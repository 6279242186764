import { useTranslation } from "@toolkit/i18n";
import React, { FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { ToastProvider, ToastProviderProps } from "react-toast-notifications";
import { SmallAlert } from "../components";
import { CustomToastContainer } from "./ToastContainer";
import { useAddToast } from "../hooks";
import { set } from "lodash";

type ToastHackRef = ReturnType<typeof useAddToast>;
const ToastHack = forwardRef((_, ref: ForwardedRef<ToastHackRef>) => {
  const addToastResp = useAddToast();
  useImperativeHandle(ref, () => ({ ...addToastResp }), [addToastResp]);
  return <></>;
});
export const AppToastProvider = ({ children, ...props }: ToastProviderProps): ReturnType<FC<ToastProviderProps>> => {
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const toastHackRef = useRef<ToastHackRef>(null);
  useEffect(() => {
    set(AppToastProvider, "addSuccessToast", toastHackRef.current?.succeeded);
    set(AppToastProvider, "addFailedToast", toastHackRef.current?.failed);
  }, []);
  return (
    <ToastProvider
      placement={direction === "rtl" ? "top-left" : "top-right"}
      components={{ Toast: SmallAlert, ToastContainer: CustomToastContainer }}
      {...props}
    >
      <ToastHack ref={toastHackRef} />
      {children}
    </ToastProvider>
  );
};

AppToastProvider.addSuccessToast = (_msg: string) => void _msg;
AppToastProvider.addFailedToast = (_msg: string, field?: any) => void (0 + _msg + field);
