"use strict";
exports.__esModule = true;
exports.Currency = exports.CoverageByType = exports.Coverage = exports.ConsumerViewPreferenceErrorCode = exports.ConsentType = exports.ConsentStatus = exports.ConsentLevel = exports.ConfigurationTypeFieldEnum = exports.ConditionOperation = exports.Community = exports.CodeSystemCode = exports.CityOrderField = exports.ChatSortingField = exports.ChatErrorCode = exports.ChartType = exports.CareType = exports.CallbackRequestStatus = exports.CallSummaryAuditRecordType = exports.CallRecordStatus = exports.CalculatedPaymentStatusForSubscription = exports.BulkTemplateCode = exports.BulkProcessingStatus = exports.BulkFilesOrderDirection = exports.BulkFileSortField = exports.BulkErrorCodes = exports.BranchType = exports.BranchOrderField = exports.BlockOrderField = exports.BlockErrorCode = exports.AuditLogOperationType = exports.AssigneeType = exports.AppointmentTypeEnum = exports.AppointmentType = exports.AppointmentStatus = exports.AppointmentSortingField = exports.AppointmentPaymentType = exports.AppointmentNotificationType = exports.AppointmentFrequencyType = exports.AppointmentCreationSource = exports.AppointmentCategory = exports.AppTypes = exports.AppRoleTypes = exports.AnxietyFrequency = exports.AmountSourceType = exports.AffectedType = exports.AddressTypeEnum = exports.Actor = exports.ActivityLevel = exports.ActionFieldType = exports.AccountErrorCode = void 0;
exports.H_EntityErrorCode = exports.H_DayOfWeek = exports.GuidedCareType = exports.GuidedCareTaskSortingField = exports.GuidedCareJourneyStatus = exports.GuidedCareJourneySortField = exports.GuidedCareJourneyItemStatus = exports.GuidedCareJourneyItemSource = exports.GuidedCareJourneyItemPriority = exports.GuidedCareHealthProgramSortingField = exports.GuidedCareCareJourneyItemStatus = exports.GuidedCareActivityType = exports.Gender = exports.FlowrseIntegrationSortDirection = exports.FileType = exports.FieldStandard = exports.ExerciseType = exports.ExerciseDurationUnit = exports.ErrorType = exports.ErrorDetail = exports.EntityType = exports.EducationLevel = exports.EarlyRefillRequestStatus = exports.EarlyRefillReasonType = exports.EarlyRefillReasonErrorCode = exports.DoctorSeniorityEnum = exports.DoctorOrderField = exports.DoctorErrorCode = exports.DoctorAvailabilityStatusEnum = exports.DoctorAvailabilityStatus = exports.DietType = exports.DietQuality = exports.DiagnosisType = exports.DiagnosisPrescriptionErrorCode = exports.DiabetesRisk = exports.DestinationType = exports.DepressionFrequency = exports.DeliveryTypesEnum = exports.DeliveryTypes = exports.DeliveryType = exports.DeliveryTimeSlotSortField = exports.DeliveryTimeSlotErrorCode = exports.DeliveryTimeSlotDeliveryType = exports.DecisionSortField = exports.DecisionPlanCategory = exports.DecisionMakerOrderDirection = exports.DecisionMakerErrorCodes = exports.DayOfWeekEnum = exports.CustomerSupportOrderStatusEnum = exports.CustomerEventsEnum = void 0;
exports.MedicalMessageVariantSortField = exports.MedicalMessageType = exports.MedicalMessageSortField = exports.MedicalMessageCategory = exports.MedicalFormType = exports.MedicalFormSortingField = exports.MedicalFormCategory = exports.MarketplaceSortDirection = exports.MarketplacePromotionSortingField = exports.MarketplaceProductSortingField = exports.MarketplacePaymentMethod = exports.MarketplaceOrderType = exports.MarketplaceOrderStatus = exports.MarketplaceOrderSortingField = exports.MarketplaceHealthPackageSortingField = exports.MarketplaceGender = exports.MarketplaceDiscountType = exports.MarketplaceDiscountSortingField = exports.MarketplaceDefaultSortingField = exports.MarketplaceContentType = exports.MarketplaceApprovalStatus = exports.MaritalStatusesEnum = exports.LanguageCodeEnum = exports.LabTemplateSortingField = exports.KeywordSearch = exports.JourneyItemClassification = exports.InterventionActivationType = exports.InsuranceSortField = exports.HealthSymptomOrderField = exports.HealthSymptomErrorCode = exports.HealthProgramType = exports.HealthProgramTemplateSortingField = exports.HealthProgramSubscriptionPeriod = exports.HealthProgramSortingField = exports.HealthProgramOptionType = exports.HealthProgramNetworkSortingField = exports.HealthProgramMembershipRequestSortingField = exports.HealthProgramMemberSortingField = exports.HealthProgramMemberListSortingField = exports.HealthProgramBenefitOptionCode = exports.HealthParameterSortingField = exports.HealthHistoryType = exports.HealthConditionType = exports.HealthConditionSortingField = exports.HealthConditionDiagnosisSortingField = exports.HealthChannelSubscriptionType = exports.H_ReportingPeriod = exports.H_RelationTypes = exports.H_OrderDirection = exports.H_Gender = void 0;
exports.PlanPeriod = exports.PlanOrderField = exports.PhysicalActivity = exports.PersonGenderEnum = exports.PermissionGroupSortField = exports.PermissionGroupErrorCode = exports.PermissionEnum = exports.PaymentType = exports.PaymentStatus = exports.PaymentSource = exports.PaymentOperationLogSortField = exports.PaymentOperation = exports.PaymentMethodEnum = exports.PaymentMethod = exports.PaymentErrorCodes = exports.PaymentBrand = exports.PayerTypeEnum = exports.PayerType = exports.PayerTpo = exports.PayerOrderField = exports.PayerErrorCode = exports.PatientMaritalStatus = exports.PatientIdentifierType = exports.PatientIdTypeEnum = exports.PatientIdType = exports.PatientHealthParameterSortField = exports.PatientErrorCode = exports.PasswordResetMethodEnum = exports.OutPatientJourneyStatus = exports.OrderTypesEnum = exports.OrderStatusEnum = exports.OrderStatus = exports.OrderSourcesEnum = exports.OrderSource = exports.OrderSortField = exports.OrderRejectionReasonType = exports.OrderRejectionReasonErrorCode = exports.OrderPaymentMethod = exports.OrderLineAuthStatus = exports.OrderEventsEnum = exports.OrderEventsEmailsEnum = exports.OrderErrorCode = exports.OrderDirection = exports.OrderDeliveryStatusEnum = exports.Operator = exports.OperationType = exports.OperationStatus = exports.NotificationType = exports.NotificationChannel = exports.MedicationDownloadAction = void 0;
exports.TaskDefinitionStatus = exports.TaskDefinitionSortingField = exports.TaskDefinitionPriority = exports.TaskDefinitionAction = exports.Tpo = exports.SurveyStatus = exports.SurveySortingField = exports.SurveyGender = exports.SurveyErrorCodes = exports.SubscriptionErrorCodes = exports.SubscriptionErrorCode = exports.SubListSortField = exports.SubListItemSortField = exports.StressLevel = exports.SpecializationErrorCode = exports.Source = exports.SortDirection = exports.SmokingHabit = exports.SiteSettingsErrorCode = exports.SiteErrorCode = exports.ServiceDescription = exports.RiskLevel = exports.RiskFactorTemplateSortingField = exports.ReviewQuestionValueType = exports.ResidencyTypesEnum = exports.RequestedBy = exports.RequestStatus = exports.ReportingPeriod = exports.RelationTypes = exports.RefundTarget = exports.RefundStatus = exports.RefillErrorCode = exports.ReferralOrderDirection = exports.ReferralErrorCodes = exports.RatingActionEnum = exports.QuestionType = exports.ProviderGuidedCareTeamStatus = exports.ProviderGuidedCareHealthProgramTeamSortingField = exports.ProfileType = exports.ProcessingStatus = exports.Priority = exports.PriceRangeEnum = exports.PrescriptionSortField = exports.PrescriptionPatientIdentifierType = exports.PrescriptionErrorCode = exports.PrescriptionDispenseStatusEnum = exports.PrescriptionDispenseStatus = exports.PrescriptionDispenseMethodEnum = exports.PluginSortField = exports.PluginErrorCode = void 0;
exports.Period = exports.VisitType = exports.VisitTranscriptionProcessingStatus = exports.VisitStatus = exports.VisitSortField = exports.VisitReferralSortField = exports.VisitPrescriptionActivityType = exports.VisitPrescriptionActivityFrequencyType = exports.VisitDiagnosisType = exports.VisitCareType = exports.VisitAttachmentType = exports.VendorUserTypes = exports.VendorTypeEnum = exports.VendorType = exports.VendorOrderField = exports.VendorErrorCode = exports.VendorDepartmentOrderField = exports.VendorBranchTypeEnum = exports.UserVendorUserType = exports.UserStatus = exports.UserSortField = exports.UserNotificationField = exports.UserGender = exports.UnverifiedNationalsStatusesEnum = exports.UnverifiedNationalsStatus = exports.Type = exports.TranscribeErrorCode = exports.TextSummaryErrorCode = exports.TerminologyErrorCodes = exports.TemplateFieldType = exports.TemplateActivityEventItemSortingField = exports.TeamMemberPosition = exports.TaskWorkflowType = void 0;
var AccountErrorCode;
(function (AccountErrorCode) {
    AccountErrorCode["ActivateOwnAccount"] = "ACTIVATE_OWN_ACCOUNT";
    AccountErrorCode["ActivateSuperuserAccount"] = "ACTIVATE_SUPERUSER_ACCOUNT";
    AccountErrorCode["DeactivateOwnAccount"] = "DEACTIVATE_OWN_ACCOUNT";
    AccountErrorCode["DeactivateSuperuserAccount"] = "DEACTIVATE_SUPERUSER_ACCOUNT";
    AccountErrorCode["DeleteConsumerAccount"] = "DELETE_CONSUMER_ACCOUNT";
    AccountErrorCode["DeleteNonStaffUser"] = "DELETE_NON_STAFF_USER";
    AccountErrorCode["DeleteOwnAccount"] = "DELETE_OWN_ACCOUNT";
    AccountErrorCode["DeleteStaffAccount"] = "DELETE_STAFF_ACCOUNT";
    AccountErrorCode["DeleteSuperuserAccount"] = "DELETE_SUPERUSER_ACCOUNT";
    AccountErrorCode["DeleteSupervendorAccount"] = "DELETE_SUPERVENDOR_ACCOUNT";
    AccountErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    AccountErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    AccountErrorCode["IntegrationTokenError"] = "INTEGRATION_TOKEN_ERROR";
    AccountErrorCode["Invalid"] = "INVALID";
    AccountErrorCode["InvalidCredentials"] = "INVALID_CREDENTIALS";
    AccountErrorCode["InvalidPassword"] = "INVALID_PASSWORD";
    AccountErrorCode["LeftNotManageablePermission"] = "LEFT_NOT_MANAGEABLE_PERMISSION";
    AccountErrorCode["NotFound"] = "NOT_FOUND";
    AccountErrorCode["OutOfScopeGroup"] = "OUT_OF_SCOPE_GROUP";
    AccountErrorCode["OutOfScopePermission"] = "OUT_OF_SCOPE_PERMISSION";
    AccountErrorCode["OutOfScopeServiceAccount"] = "OUT_OF_SCOPE_SERVICE_ACCOUNT";
    AccountErrorCode["OutOfScopeUser"] = "OUT_OF_SCOPE_USER";
    AccountErrorCode["PasswordEntirelyNumeric"] = "PASSWORD_ENTIRELY_NUMERIC";
    AccountErrorCode["PasswordTooCommon"] = "PASSWORD_TOO_COMMON";
    AccountErrorCode["PasswordTooShort"] = "PASSWORD_TOO_SHORT";
    AccountErrorCode["PasswordTooSimilar"] = "PASSWORD_TOO_SIMILAR";
    AccountErrorCode["Required"] = "REQUIRED";
    AccountErrorCode["Unique"] = "UNIQUE";
})(AccountErrorCode = exports.AccountErrorCode || (exports.AccountErrorCode = {}));
var ActionFieldType;
(function (ActionFieldType) {
    ActionFieldType["Boolean"] = "BOOLEAN";
    ActionFieldType["Date"] = "DATE";
    ActionFieldType["List"] = "LIST";
    ActionFieldType["Number"] = "NUMBER";
    ActionFieldType["String"] = "STRING";
})(ActionFieldType = exports.ActionFieldType || (exports.ActionFieldType = {}));
var ActivityLevel;
(function (ActivityLevel) {
    ActivityLevel["LightlyActive"] = "LIGHTLY_ACTIVE";
    ActivityLevel["ModeratelyActive"] = "MODERATELY_ACTIVE";
    ActivityLevel["Sedentary"] = "SEDENTARY";
    ActivityLevel["VeryActive"] = "VERY_ACTIVE";
})(ActivityLevel = exports.ActivityLevel || (exports.ActivityLevel = {}));
var Actor;
(function (Actor) {
    Actor["Doctor"] = "DOCTOR";
    Actor["Patient"] = "PATIENT";
})(Actor = exports.Actor || (exports.Actor = {}));
var AddressTypeEnum;
(function (AddressTypeEnum) {
    AddressTypeEnum["Billing"] = "BILLING";
    AddressTypeEnum["Shipping"] = "SHIPPING";
})(AddressTypeEnum = exports.AddressTypeEnum || (exports.AddressTypeEnum = {}));
var AffectedType;
(function (AffectedType) {
    AffectedType["Appointment"] = "APPOINTMENT";
    AffectedType["Visit"] = "VISIT";
    AffectedType["VisitCall"] = "VISIT_CALL";
})(AffectedType = exports.AffectedType || (exports.AffectedType = {}));
var AmountSourceType;
(function (AmountSourceType) {
    AmountSourceType["Appointment"] = "APPOINTMENT";
    AmountSourceType["Doctor"] = "DOCTOR";
    AmountSourceType["HealthProgram"] = "HEALTH_PROGRAM";
    AmountSourceType["MarketplaceOrder"] = "MARKETPLACE_ORDER";
    AmountSourceType["Order"] = "ORDER";
    AmountSourceType["OrderCheckoutToken"] = "ORDER_CHECKOUT_TOKEN";
    AmountSourceType["OutPatientJourney"] = "OUT_PATIENT_JOURNEY";
    AmountSourceType["PrescriptionOrder"] = "PRESCRIPTION_ORDER";
    AmountSourceType["Visit"] = "VISIT";
})(AmountSourceType = exports.AmountSourceType || (exports.AmountSourceType = {}));
var AnxietyFrequency;
(function (AnxietyFrequency) {
    AnxietyFrequency["MoreThanHalfTheDays"] = "MORE_THAN_HALF_THE_DAYS";
    AnxietyFrequency["NearlyEveryDay"] = "NEARLY_EVERY_DAY";
    AnxietyFrequency["NotAtAll"] = "NOT_AT_ALL";
    AnxietyFrequency["SeveralDays"] = "SEVERAL_DAYS";
})(AnxietyFrequency = exports.AnxietyFrequency || (exports.AnxietyFrequency = {}));
var AppRoleTypes;
(function (AppRoleTypes) {
    AppRoleTypes["Admin"] = "ADMIN";
    AppRoleTypes["User"] = "USER";
})(AppRoleTypes = exports.AppRoleTypes || (exports.AppRoleTypes = {}));
var AppTypes;
(function (AppTypes) {
    AppTypes["Admin"] = "ADMIN";
    AppTypes["Aggregator"] = "AGGREGATOR";
    AppTypes["Customer"] = "CUSTOMER";
    AppTypes["Payer"] = "PAYER";
    AppTypes["Vendor"] = "VENDOR";
})(AppTypes = exports.AppTypes || (exports.AppTypes = {}));
var AppointmentCategory;
(function (AppointmentCategory) {
    AppointmentCategory["Consultation"] = "CONSULTATION";
    AppointmentCategory["Lab"] = "LAB";
    AppointmentCategory["Rad"] = "RAD";
})(AppointmentCategory = exports.AppointmentCategory || (exports.AppointmentCategory = {}));
var AppointmentCreationSource;
(function (AppointmentCreationSource) {
    AppointmentCreationSource["Automatic"] = "AUTOMATIC";
    AppointmentCreationSource["Other"] = "OTHER";
    AppointmentCreationSource["Personalized"] = "PERSONALIZED";
})(AppointmentCreationSource = exports.AppointmentCreationSource || (exports.AppointmentCreationSource = {}));
var AppointmentFrequencyType;
(function (AppointmentFrequencyType) {
    AppointmentFrequencyType["Day"] = "Day";
    AppointmentFrequencyType["Month"] = "Month";
    AppointmentFrequencyType["Week"] = "Week";
    AppointmentFrequencyType["Year"] = "Year";
})(AppointmentFrequencyType = exports.AppointmentFrequencyType || (exports.AppointmentFrequencyType = {}));
var AppointmentNotificationType;
(function (AppointmentNotificationType) {
    AppointmentNotificationType["Confirmation"] = "CONFIRMATION";
    AppointmentNotificationType["Reminder"] = "REMINDER";
})(AppointmentNotificationType = exports.AppointmentNotificationType || (exports.AppointmentNotificationType = {}));
var AppointmentPaymentType;
(function (AppointmentPaymentType) {
    AppointmentPaymentType["CashOnly"] = "CASH_ONLY";
    AppointmentPaymentType["InsuranceCoverage"] = "INSURANCE_COVERAGE";
    AppointmentPaymentType["PrePaid"] = "PRE_PAID";
})(AppointmentPaymentType = exports.AppointmentPaymentType || (exports.AppointmentPaymentType = {}));
var AppointmentSortingField;
(function (AppointmentSortingField) {
    /** Sort appointment by confirmed start time. */
    AppointmentSortingField["ConfirmedStartDate"] = "CONFIRMED_START_DATE";
    /** Sort appointments by creation date. */
    AppointmentSortingField["Created"] = "CREATED";
    /** Sort appointments by last modification date. */
    AppointmentSortingField["LastModifiedDate"] = "LAST_MODIFIED_DATE";
})(AppointmentSortingField = exports.AppointmentSortingField || (exports.AppointmentSortingField = {}));
var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["Approved"] = "APPROVED";
    AppointmentStatus["CallInProgress"] = "CALL_IN_PROGRESS";
    AppointmentStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    AppointmentStatus["CanceledByDoctor"] = "CANCELED_BY_DOCTOR";
    AppointmentStatus["Cancelled"] = "CANCELLED";
    AppointmentStatus["ChatInProgress"] = "CHAT_IN_PROGRESS";
    AppointmentStatus["ComingSoon"] = "COMING_SOON";
    AppointmentStatus["Confirmed"] = "CONFIRMED";
    AppointmentStatus["FinishedSuccessfully"] = "FINISHED_SUCCESSFULLY";
    AppointmentStatus["OutPatientJourneyAssessmentDone"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_DONE";
    AppointmentStatus["OutPatientJourneyAssessmentPending"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING";
    AppointmentStatus["OutPatientJourneyCheckedIn"] = "OUT_PATIENT_JOURNEY_CHECKED_IN";
    AppointmentStatus["OutPatientJourneyConsultationDone"] = "OUT_PATIENT_JOURNEY_CONSULTATION_DONE";
    AppointmentStatus["OutPatientJourneyConsultationPending"] = "OUT_PATIENT_JOURNEY_CONSULTATION_PENDING";
    AppointmentStatus["OutPatientJourneyPending"] = "OUT_PATIENT_JOURNEY_PENDING";
    AppointmentStatus["OutPatientJourneyTreatmentDone"] = "OUT_PATIENT_JOURNEY_TREATMENT_DONE";
    AppointmentStatus["PaymentFailed"] = "PAYMENT_FAILED";
    AppointmentStatus["PaymentPending"] = "PAYMENT_PENDING";
    AppointmentStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    AppointmentStatus["PendingApproval"] = "PENDING_APPROVAL";
    AppointmentStatus["Rejected"] = "REJECTED";
    AppointmentStatus["Request"] = "REQUEST";
    AppointmentStatus["RescheduledByConsumer"] = "RESCHEDULED_BY_CONSUMER";
    AppointmentStatus["RescheduledByDoctor"] = "RESCHEDULED_BY_DOCTOR";
    AppointmentStatus["Tentative"] = "TENTATIVE";
})(AppointmentStatus = exports.AppointmentStatus || (exports.AppointmentStatus = {}));
var AppointmentType;
(function (AppointmentType) {
    AppointmentType["AtHome"] = "AT_HOME";
    AppointmentType["Diagnostic"] = "DIAGNOSTIC";
    AppointmentType["Online"] = "ONLINE";
    AppointmentType["Onsite"] = "ONSITE";
})(AppointmentType = exports.AppointmentType || (exports.AppointmentType = {}));
var AppointmentTypeEnum;
(function (AppointmentTypeEnum) {
    AppointmentTypeEnum["AtHome"] = "AT_HOME";
    AppointmentTypeEnum["Diagnostic"] = "DIAGNOSTIC";
    AppointmentTypeEnum["Online"] = "ONLINE";
    AppointmentTypeEnum["Onsite"] = "ONSITE";
})(AppointmentTypeEnum = exports.AppointmentTypeEnum || (exports.AppointmentTypeEnum = {}));
var AssigneeType;
(function (AssigneeType) {
    AssigneeType["Patient"] = "PATIENT";
    AssigneeType["TeamMember"] = "TEAM_MEMBER";
})(AssigneeType = exports.AssigneeType || (exports.AssigneeType = {}));
var AuditLogOperationType;
(function (AuditLogOperationType) {
    AuditLogOperationType["Create"] = "CREATE";
    AuditLogOperationType["Delete"] = "DELETE";
    AuditLogOperationType["Update"] = "UPDATE";
})(AuditLogOperationType = exports.AuditLogOperationType || (exports.AuditLogOperationType = {}));
var BlockErrorCode;
(function (BlockErrorCode) {
    BlockErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    BlockErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    BlockErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    BlockErrorCode["Invalid"] = "INVALID";
    BlockErrorCode["NotFound"] = "NOT_FOUND";
    BlockErrorCode["Required"] = "REQUIRED";
    BlockErrorCode["Unique"] = "UNIQUE";
})(BlockErrorCode = exports.BlockErrorCode || (exports.BlockErrorCode = {}));
var BlockOrderField;
(function (BlockOrderField) {
    BlockOrderField["Created"] = "CREATED";
    BlockOrderField["Name"] = "NAME";
})(BlockOrderField = exports.BlockOrderField || (exports.BlockOrderField = {}));
var BranchOrderField;
(function (BranchOrderField) {
    BranchOrderField["Created"] = "CREATED";
    BranchOrderField["LastMessageSent"] = "LAST_MESSAGE_SENT";
    BranchOrderField["Name"] = "NAME";
    BranchOrderField["Nearest"] = "NEAREST";
})(BranchOrderField = exports.BranchOrderField || (exports.BranchOrderField = {}));
var BranchType;
(function (BranchType) {
    BranchType["Clinic"] = "CLINIC";
    BranchType["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    BranchType["Hospital"] = "HOSPITAL";
    BranchType["Pharmacy"] = "PHARMACY";
})(BranchType = exports.BranchType || (exports.BranchType = {}));
var BulkErrorCodes;
(function (BulkErrorCodes) {
    BulkErrorCodes["BulkCancelBulkFile"] = "BULK_CANCEL_BULK_FILE";
    BulkErrorCodes["BulkDuplicateRowData"] = "BULK_DUPLICATE_ROW_DATA";
    BulkErrorCodes["BulkFieldRequired"] = "BULK_FIELD_REQUIRED";
    BulkErrorCodes["BulkFileNotFound"] = "BULK_FILE_NOT_FOUND";
    BulkErrorCodes["BulkFileServiceNotReachable"] = "BULK_FILE_SERVICE_NOT_REACHABLE";
    BulkErrorCodes["BulkInvalidDateFormat"] = "BULK_INVALID_DATE_FORMAT";
    BulkErrorCodes["BulkInvalidFileExtension"] = "BULK_INVALID_FILE_EXTENSION";
    BulkErrorCodes["BulkNoneNegativePageSize"] = "BULK_NONE_NEGATIVE_PAGE_SIZE";
    BulkErrorCodes["BulkPermissionDenied"] = "BULK_PERMISSION_DENIED";
    BulkErrorCodes["BulkTemplateNotEnabled"] = "BULK_TEMPLATE_NOT_ENABLED";
    BulkErrorCodes["BulkUnknown"] = "BULK_UNKNOWN";
    BulkErrorCodes["BulkUnknownType"] = "BULK_UNKNOWN_TYPE";
    BulkErrorCodes["FileAlreadyExist"] = "FILE_ALREADY_EXIST";
})(BulkErrorCodes = exports.BulkErrorCodes || (exports.BulkErrorCodes = {}));
var BulkFileSortField;
(function (BulkFileSortField) {
    BulkFileSortField["Code"] = "CODE";
    BulkFileSortField["CreatedDate"] = "CREATED_DATE";
    BulkFileSortField["FailedRecordCount"] = "FAILED_RECORD_COUNT";
    BulkFileSortField["FileName"] = "FILE_NAME";
    BulkFileSortField["ProcessedRecordCount"] = "PROCESSED_RECORD_COUNT";
    BulkFileSortField["RecordsCount"] = "RECORDS_COUNT";
    BulkFileSortField["UpdatedDate"] = "UPDATED_DATE";
})(BulkFileSortField = exports.BulkFileSortField || (exports.BulkFileSortField = {}));
var BulkFilesOrderDirection;
(function (BulkFilesOrderDirection) {
    BulkFilesOrderDirection["Asc"] = "ASC";
    BulkFilesOrderDirection["Desc"] = "DESC";
})(BulkFilesOrderDirection = exports.BulkFilesOrderDirection || (exports.BulkFilesOrderDirection = {}));
var BulkProcessingStatus;
(function (BulkProcessingStatus) {
    BulkProcessingStatus["Cancelled"] = "CANCELLED";
    BulkProcessingStatus["Pending"] = "PENDING";
    BulkProcessingStatus["Processed"] = "PROCESSED";
    BulkProcessingStatus["UnderProcess"] = "UNDER_PROCESS";
})(BulkProcessingStatus = exports.BulkProcessingStatus || (exports.BulkProcessingStatus = {}));
var BulkTemplateCode;
(function (BulkTemplateCode) {
    BulkTemplateCode["Doctor"] = "DOCTOR";
    BulkTemplateCode["HealthParameter"] = "HEALTH_PARAMETER";
    BulkTemplateCode["HealthProgramMember"] = "HEALTH_PROGRAM_MEMBER";
    BulkTemplateCode["HealthProgramNetwork"] = "HEALTH_PROGRAM_NETWORK";
    BulkTemplateCode["HealthProgramNetworkProvider"] = "HEALTH_PROGRAM_NETWORK_PROVIDER";
    BulkTemplateCode["MedicalForm"] = "MEDICAL_FORM";
})(BulkTemplateCode = exports.BulkTemplateCode || (exports.BulkTemplateCode = {}));
var CalculatedPaymentStatusForSubscription;
(function (CalculatedPaymentStatusForSubscription) {
    CalculatedPaymentStatusForSubscription["PaymentChargedBack"] = "PAYMENT_CHARGED_BACK";
    CalculatedPaymentStatusForSubscription["PaymentPending"] = "PAYMENT_PENDING";
    CalculatedPaymentStatusForSubscription["PaymentRejected"] = "PAYMENT_REJECTED";
    CalculatedPaymentStatusForSubscription["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    CalculatedPaymentStatusForSubscription["RefundedToWallet"] = "REFUNDED_TO_WALLET";
    CalculatedPaymentStatusForSubscription["RefundChargedBack"] = "REFUND_CHARGED_BACK";
    CalculatedPaymentStatusForSubscription["RefundPending"] = "REFUND_PENDING";
    CalculatedPaymentStatusForSubscription["RefundRejected"] = "REFUND_REJECTED";
    CalculatedPaymentStatusForSubscription["RefundSucceeded"] = "REFUND_SUCCEEDED";
})(CalculatedPaymentStatusForSubscription = exports.CalculatedPaymentStatusForSubscription || (exports.CalculatedPaymentStatusForSubscription = {}));
var CallRecordStatus;
(function (CallRecordStatus) {
    CallRecordStatus["Available"] = "AVAILABLE";
    CallRecordStatus["Ready"] = "READY";
    CallRecordStatus["TranscriptedFailed"] = "TRANSCRIPTED_FAILED";
    CallRecordStatus["TranscriptedSucceeded"] = "TRANSCRIPTED_SUCCEEDED";
    CallRecordStatus["Unavailable"] = "UNAVAILABLE";
})(CallRecordStatus = exports.CallRecordStatus || (exports.CallRecordStatus = {}));
var CallSummaryAuditRecordType;
(function (CallSummaryAuditRecordType) {
    CallSummaryAuditRecordType["Snapshot"] = "SNAPSHOT";
})(CallSummaryAuditRecordType = exports.CallSummaryAuditRecordType || (exports.CallSummaryAuditRecordType = {}));
var CallbackRequestStatus;
(function (CallbackRequestStatus) {
    CallbackRequestStatus["InProgress"] = "IN_PROGRESS";
    CallbackRequestStatus["New"] = "NEW";
    CallbackRequestStatus["Resolved"] = "RESOLVED";
})(CallbackRequestStatus = exports.CallbackRequestStatus || (exports.CallbackRequestStatus = {}));
var CareType;
(function (CareType) {
    CareType["ErxCoverage"] = "ERX_COVERAGE";
    CareType["ImmediateCare"] = "IMMEDIATE_CARE";
    CareType["Proactive"] = "PROACTIVE";
    CareType["Reactive"] = "REACTIVE";
})(CareType = exports.CareType || (exports.CareType = {}));
var ChartType;
(function (ChartType) {
    ChartType["Bar"] = "BAR";
    ChartType["HorizontalBar"] = "HORIZONTAL_BAR";
    ChartType["Line"] = "LINE";
    ChartType["Table"] = "TABLE";
})(ChartType = exports.ChartType || (exports.ChartType = {}));
var ChatErrorCode;
(function (ChatErrorCode) {
    ChatErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    ChatErrorCode["Invalid"] = "INVALID";
    ChatErrorCode["NotFound"] = "NOT_FOUND";
    ChatErrorCode["Required"] = "REQUIRED";
})(ChatErrorCode = exports.ChatErrorCode || (exports.ChatErrorCode = {}));
var ChatSortingField;
(function (ChatSortingField) {
    ChatSortingField["Code"] = "CODE";
    ChatSortingField["Created"] = "CREATED";
    ChatSortingField["Description"] = "DESCRIPTION";
    ChatSortingField["Name"] = "NAME";
    ChatSortingField["Updated"] = "UPDATED";
})(ChatSortingField = exports.ChatSortingField || (exports.ChatSortingField = {}));
var CityOrderField;
(function (CityOrderField) {
    CityOrderField["Created"] = "CREATED";
    CityOrderField["Name"] = "NAME";
})(CityOrderField = exports.CityOrderField || (exports.CityOrderField = {}));
var CodeSystemCode;
(function (CodeSystemCode) {
    CodeSystemCode["Allergy"] = "ALLERGY";
    CodeSystemCode["CancellationReason"] = "CANCELLATION_REASON";
    CodeSystemCode["ChronicDisease"] = "CHRONIC_DISEASE";
    CodeSystemCode["ChronicMedication"] = "CHRONIC_MEDICATION";
    CodeSystemCode["City"] = "CITY";
    CodeSystemCode["Clinician"] = "CLINICIAN";
    CodeSystemCode["Cpt"] = "CPT";
    CodeSystemCode["CurrentProceduralTerminology"] = "CURRENT_PROCEDURAL_TERMINOLOGY";
    CodeSystemCode["DenialCode"] = "DENIAL_CODE";
    CodeSystemCode["Diagnosis"] = "DIAGNOSIS";
    CodeSystemCode["DiagnosisRelatedGroup"] = "DIAGNOSIS_RELATED_GROUP";
    CodeSystemCode["Disability"] = "DISABILITY";
    CodeSystemCode["Division"] = "DIVISION";
    CodeSystemCode["Family"] = "FAMILY";
    CodeSystemCode["GenericDrug"] = "GENERIC_DRUG";
    CodeSystemCode["GenericDrugExtraData"] = "GENERIC_DRUG_EXTRA_DATA";
    CodeSystemCode["HealthPackageCategory"] = "HEALTH_PACKAGE_CATEGORY";
    CodeSystemCode["HealthProfessionalSpeciality"] = "HEALTH_PROFESSIONAL_SPECIALITY";
    CodeSystemCode["HealthProvider"] = "HEALTH_PROVIDER";
    CodeSystemCode["LabTest"] = "LAB_TEST";
    CodeSystemCode["Loinc"] = "LOINC";
    CodeSystemCode["MedicalService"] = "MEDICAL_SERVICE";
    CodeSystemCode["MobileTranslation"] = "MOBILE_TRANSLATION";
    CodeSystemCode["Nationality"] = "NATIONALITY";
    CodeSystemCode["NonChronicDisease"] = "NON_CHRONIC_DISEASE";
    CodeSystemCode["RejectionReason"] = "REJECTION_REASON";
    CodeSystemCode["RouteOfAdmin"] = "ROUTE_OF_ADMIN";
    CodeSystemCode["Service"] = "SERVICE";
    CodeSystemCode["Speciality"] = "SPECIALITY";
    CodeSystemCode["Surgery"] = "SURGERY";
    CodeSystemCode["Temperature"] = "TEMPERATURE";
    CodeSystemCode["TradeDrug"] = "TRADE_DRUG";
    CodeSystemCode["TradeDrugExtraData"] = "TRADE_DRUG_EXTRA_DATA";
    CodeSystemCode["UnitId"] = "UNIT_ID";
})(CodeSystemCode = exports.CodeSystemCode || (exports.CodeSystemCode = {}));
var Community;
(function (Community) {
    Community["Rural"] = "RURAL";
    Community["Urban"] = "URBAN";
})(Community = exports.Community || (exports.Community = {}));
var ConditionOperation;
(function (ConditionOperation) {
    ConditionOperation["And"] = "AND";
    ConditionOperation["Or"] = "OR";
})(ConditionOperation = exports.ConditionOperation || (exports.ConditionOperation = {}));
var ConfigurationTypeFieldEnum;
(function (ConfigurationTypeFieldEnum) {
    ConfigurationTypeFieldEnum["Boolean"] = "BOOLEAN";
    ConfigurationTypeFieldEnum["Integer"] = "INTEGER";
    ConfigurationTypeFieldEnum["Password"] = "PASSWORD";
    ConfigurationTypeFieldEnum["Secret"] = "SECRET";
    ConfigurationTypeFieldEnum["String"] = "STRING";
})(ConfigurationTypeFieldEnum = exports.ConfigurationTypeFieldEnum || (exports.ConfigurationTypeFieldEnum = {}));
var ConsentLevel;
(function (ConsentLevel) {
    ConsentLevel["All"] = "ALL";
    ConsentLevel["Basic"] = "BASIC";
    ConsentLevel["Medical"] = "MEDICAL";
})(ConsentLevel = exports.ConsentLevel || (exports.ConsentLevel = {}));
var ConsentStatus;
(function (ConsentStatus) {
    ConsentStatus["Approved"] = "APPROVED";
    ConsentStatus["Closed"] = "CLOSED";
    ConsentStatus["Pending"] = "PENDING";
    ConsentStatus["Rejected"] = "REJECTED";
})(ConsentStatus = exports.ConsentStatus || (exports.ConsentStatus = {}));
var ConsentType;
(function (ConsentType) {
    ConsentType["All"] = "ALL";
    ConsentType["View"] = "VIEW";
})(ConsentType = exports.ConsentType || (exports.ConsentType = {}));
var ConsumerViewPreferenceErrorCode;
(function (ConsumerViewPreferenceErrorCode) {
    ConsumerViewPreferenceErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    ConsumerViewPreferenceErrorCode["Invalid"] = "INVALID";
    ConsumerViewPreferenceErrorCode["NotFound"] = "NOT_FOUND";
    ConsumerViewPreferenceErrorCode["Required"] = "REQUIRED";
    ConsumerViewPreferenceErrorCode["Unique"] = "UNIQUE";
})(ConsumerViewPreferenceErrorCode = exports.ConsumerViewPreferenceErrorCode || (exports.ConsumerViewPreferenceErrorCode = {}));
var Coverage;
(function (Coverage) {
    Coverage["FullyCovered"] = "FULLY_COVERED";
    Coverage["NotCovered"] = "NOT_COVERED";
    Coverage["PartiallyCovered"] = "PARTIALLY_COVERED";
})(Coverage = exports.Coverage || (exports.Coverage = {}));
var CoverageByType;
(function (CoverageByType) {
    CoverageByType["Cash"] = "CASH";
    CoverageByType["Insurance"] = "INSURANCE";
    CoverageByType["Provider"] = "PROVIDER";
    CoverageByType["Tpa"] = "TPA";
})(CoverageByType = exports.CoverageByType || (exports.CoverageByType = {}));
var Currency;
(function (Currency) {
    Currency["Aed"] = "AED";
    Currency["Eur"] = "EUR";
    Currency["Sar"] = "SAR";
    Currency["Usd"] = "USD";
})(Currency = exports.Currency || (exports.Currency = {}));
var CustomerEventsEnum;
(function (CustomerEventsEnum) {
    CustomerEventsEnum["AccountCreated"] = "ACCOUNT_CREATED";
    CustomerEventsEnum["AccountCreatedFromSso"] = "ACCOUNT_CREATED_FROM_SSO";
    CustomerEventsEnum["CustomerDeleted"] = "CUSTOMER_DELETED";
    CustomerEventsEnum["DigitalLinkDownloaded"] = "DIGITAL_LINK_DOWNLOADED";
    CustomerEventsEnum["EmailAssigned"] = "EMAIL_ASSIGNED";
    CustomerEventsEnum["EmailChanged"] = "EMAIL_CHANGED";
    CustomerEventsEnum["EmailChangedRequest"] = "EMAIL_CHANGED_REQUEST";
    CustomerEventsEnum["NameAssigned"] = "NAME_ASSIGNED";
    CustomerEventsEnum["NoteAdded"] = "NOTE_ADDED";
    CustomerEventsEnum["NoteAddedToOrder"] = "NOTE_ADDED_TO_ORDER";
    CustomerEventsEnum["PasswordChanged"] = "PASSWORD_CHANGED";
    CustomerEventsEnum["PasswordReset"] = "PASSWORD_RESET";
    CustomerEventsEnum["PasswordResetLinkSent"] = "PASSWORD_RESET_LINK_SENT";
    CustomerEventsEnum["PlacedOrder"] = "PLACED_ORDER";
})(CustomerEventsEnum = exports.CustomerEventsEnum || (exports.CustomerEventsEnum = {}));
var CustomerSupportOrderStatusEnum;
(function (CustomerSupportOrderStatusEnum) {
    CustomerSupportOrderStatusEnum["Delivered"] = "DELIVERED";
    CustomerSupportOrderStatusEnum["Failed"] = "FAILED";
    CustomerSupportOrderStatusEnum["Returned"] = "RETURNED";
})(CustomerSupportOrderStatusEnum = exports.CustomerSupportOrderStatusEnum || (exports.CustomerSupportOrderStatusEnum = {}));
var DayOfWeekEnum;
(function (DayOfWeekEnum) {
    DayOfWeekEnum["Fri"] = "FRI";
    DayOfWeekEnum["Mon"] = "MON";
    DayOfWeekEnum["Sat"] = "SAT";
    DayOfWeekEnum["Sun"] = "SUN";
    DayOfWeekEnum["Thu"] = "THU";
    DayOfWeekEnum["Tue"] = "TUE";
    DayOfWeekEnum["Wed"] = "WED";
})(DayOfWeekEnum = exports.DayOfWeekEnum || (exports.DayOfWeekEnum = {}));
var DecisionMakerErrorCodes;
(function (DecisionMakerErrorCodes) {
    DecisionMakerErrorCodes["DecisionFileNotFound"] = "DECISION_FILE_NOT_FOUND";
    DecisionMakerErrorCodes["DecisionFileServiceNotReachable"] = "DECISION_FILE_SERVICE_NOT_REACHABLE";
    DecisionMakerErrorCodes["DecisionInvalidFileExtension"] = "DECISION_INVALID_FILE_EXTENSION";
    DecisionMakerErrorCodes["DecisionInvalidId"] = "DECISION_INVALID_ID";
    DecisionMakerErrorCodes["DecisionMakerDuplicateListItem"] = "DECISION_MAKER_DUPLICATE_LIST_ITEM";
    DecisionMakerErrorCodes["DecisionMakerDuplicateListName"] = "DECISION_MAKER_DUPLICATE_LIST_NAME";
    DecisionMakerErrorCodes["DecisionMakerDuplicateRuleName"] = "DECISION_MAKER_DUPLICATE_RULE_NAME";
    DecisionMakerErrorCodes["DecisionMakerEntityNotFound"] = "DECISION_MAKER_ENTITY_NOT_FOUND";
    DecisionMakerErrorCodes["DecisionMakerFieldMandatory"] = "DECISION_MAKER_FIELD_MANDATORY";
    DecisionMakerErrorCodes["DecisionMakerFieldSize"] = "DECISION_MAKER_FIELD_SIZE";
    DecisionMakerErrorCodes["DecisionMakerFieldSizeException"] = "DECISION_MAKER_FIELD_SIZE_EXCEPTION";
    DecisionMakerErrorCodes["DecisionMakerInvalidAction"] = "DECISION_MAKER_INVALID_ACTION";
    DecisionMakerErrorCodes["DecisionMakerInvalidOperator"] = "DECISION_MAKER_INVALID_OPERATOR";
    DecisionMakerErrorCodes["DecisionMakerInvalidRule"] = "DECISION_MAKER_INVALID_RULE";
    DecisionMakerErrorCodes["DecisionMakerInvalidValue"] = "DECISION_MAKER_INVALID_VALUE";
    DecisionMakerErrorCodes["DecisionMakerNoneNegativePageSize"] = "DECISION_MAKER_NONE_NEGATIVE_PAGE_SIZE";
    DecisionMakerErrorCodes["DecisionMakerSubListNotFound"] = "DECISION_MAKER_SUB_LIST_NOT_FOUND";
    DecisionMakerErrorCodes["DecisionMakerUnknown"] = "DECISION_MAKER_UNKNOWN";
    DecisionMakerErrorCodes["DecisionMakerUnknownType"] = "DECISION_MAKER_UNKNOWN_TYPE";
    DecisionMakerErrorCodes["DecisionMakerValidationError"] = "DECISION_MAKER_VALIDATION_ERROR";
    DecisionMakerErrorCodes["DecisionPermissionDenied"] = "DECISION_PERMISSION_DENIED";
    DecisionMakerErrorCodes["InvalidOrExpiredToken"] = "INVALID_OR_EXPIRED_TOKEN";
})(DecisionMakerErrorCodes = exports.DecisionMakerErrorCodes || (exports.DecisionMakerErrorCodes = {}));
var DecisionMakerOrderDirection;
(function (DecisionMakerOrderDirection) {
    DecisionMakerOrderDirection["Asc"] = "ASC";
    DecisionMakerOrderDirection["Desc"] = "DESC";
})(DecisionMakerOrderDirection = exports.DecisionMakerOrderDirection || (exports.DecisionMakerOrderDirection = {}));
var DecisionPlanCategory;
(function (DecisionPlanCategory) {
    DecisionPlanCategory["HealthCondition"] = "HEALTH_CONDITION";
    DecisionPlanCategory["Intervention"] = "INTERVENTION";
    DecisionPlanCategory["PatientEligibility"] = "PATIENT_ELIGIBILITY";
    DecisionPlanCategory["RiskStratification"] = "RISK_STRATIFICATION";
    DecisionPlanCategory["TaskFlow"] = "TASK_FLOW";
    DecisionPlanCategory["UserRequiredAction"] = "USER_REQUIRED_ACTION";
})(DecisionPlanCategory = exports.DecisionPlanCategory || (exports.DecisionPlanCategory = {}));
var DecisionSortField;
(function (DecisionSortField) {
    DecisionSortField["DecisionPlan"] = "DECISION_PLAN";
    DecisionSortField["IsActive"] = "IS_ACTIVE";
    DecisionSortField["Name"] = "NAME";
    DecisionSortField["Type"] = "TYPE";
})(DecisionSortField = exports.DecisionSortField || (exports.DecisionSortField = {}));
var DeliveryTimeSlotDeliveryType;
(function (DeliveryTimeSlotDeliveryType) {
    DeliveryTimeSlotDeliveryType["Delivery"] = "DELIVERY";
    DeliveryTimeSlotDeliveryType["Pickup"] = "PICKUP";
})(DeliveryTimeSlotDeliveryType = exports.DeliveryTimeSlotDeliveryType || (exports.DeliveryTimeSlotDeliveryType = {}));
var DeliveryTimeSlotErrorCode;
(function (DeliveryTimeSlotErrorCode) {
    DeliveryTimeSlotErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    DeliveryTimeSlotErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    DeliveryTimeSlotErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    DeliveryTimeSlotErrorCode["Invalid"] = "INVALID";
    DeliveryTimeSlotErrorCode["NotFound"] = "NOT_FOUND";
    DeliveryTimeSlotErrorCode["Required"] = "REQUIRED";
    DeliveryTimeSlotErrorCode["Unique"] = "UNIQUE";
})(DeliveryTimeSlotErrorCode = exports.DeliveryTimeSlotErrorCode || (exports.DeliveryTimeSlotErrorCode = {}));
var DeliveryTimeSlotSortField;
(function (DeliveryTimeSlotSortField) {
    DeliveryTimeSlotSortField["EndTime"] = "END_TIME";
    DeliveryTimeSlotSortField["IsActive"] = "IS_ACTIVE";
    DeliveryTimeSlotSortField["StartTime"] = "START_TIME";
})(DeliveryTimeSlotSortField = exports.DeliveryTimeSlotSortField || (exports.DeliveryTimeSlotSortField = {}));
var DeliveryType;
(function (DeliveryType) {
    DeliveryType["Delivery"] = "DELIVERY";
    DeliveryType["Pickup"] = "PICKUP";
})(DeliveryType = exports.DeliveryType || (exports.DeliveryType = {}));
var DeliveryTypes;
(function (DeliveryTypes) {
    DeliveryTypes["Delivery"] = "DELIVERY";
    DeliveryTypes["Pickup"] = "PICKUP";
})(DeliveryTypes = exports.DeliveryTypes || (exports.DeliveryTypes = {}));
var DeliveryTypesEnum;
(function (DeliveryTypesEnum) {
    DeliveryTypesEnum["Delivery"] = "DELIVERY";
    DeliveryTypesEnum["Pickup"] = "PICKUP";
})(DeliveryTypesEnum = exports.DeliveryTypesEnum || (exports.DeliveryTypesEnum = {}));
var DepressionFrequency;
(function (DepressionFrequency) {
    DepressionFrequency["MoreThanHalfTheDays"] = "MORE_THAN_HALF_THE_DAYS";
    DepressionFrequency["NearlyEveryDay"] = "NEARLY_EVERY_DAY";
    DepressionFrequency["NotAtAll"] = "NOT_AT_ALL";
    DepressionFrequency["SeveralDays"] = "SEVERAL_DAYS";
})(DepressionFrequency = exports.DepressionFrequency || (exports.DepressionFrequency = {}));
var DestinationType;
(function (DestinationType) {
    DestinationType["Eclaim"] = "ECLAIM";
    DestinationType["Io"] = "IO";
})(DestinationType = exports.DestinationType || (exports.DestinationType = {}));
var DiabetesRisk;
(function (DiabetesRisk) {
    DiabetesRisk["High"] = "HIGH";
    DiabetesRisk["Low"] = "LOW";
})(DiabetesRisk = exports.DiabetesRisk || (exports.DiabetesRisk = {}));
var DiagnosisPrescriptionErrorCode;
(function (DiagnosisPrescriptionErrorCode) {
    DiagnosisPrescriptionErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    DiagnosisPrescriptionErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    DiagnosisPrescriptionErrorCode["Ingested"] = "INGESTED";
    DiagnosisPrescriptionErrorCode["Invalid"] = "INVALID";
    DiagnosisPrescriptionErrorCode["NotFound"] = "NOT_FOUND";
    DiagnosisPrescriptionErrorCode["Required"] = "REQUIRED";
    DiagnosisPrescriptionErrorCode["Unique"] = "UNIQUE";
})(DiagnosisPrescriptionErrorCode = exports.DiagnosisPrescriptionErrorCode || (exports.DiagnosisPrescriptionErrorCode = {}));
var DiagnosisType;
(function (DiagnosisType) {
    DiagnosisType["Principal"] = "Principal";
    DiagnosisType["Secondary"] = "Secondary";
})(DiagnosisType = exports.DiagnosisType || (exports.DiagnosisType = {}));
var DietQuality;
(function (DietQuality) {
    DietQuality["Adequate"] = "ADEQUATE";
    DietQuality["Fair"] = "FAIR";
    DietQuality["Poor"] = "POOR";
    DietQuality["VeryPoor"] = "VERY_POOR";
})(DietQuality = exports.DietQuality || (exports.DietQuality = {}));
var DietType;
(function (DietType) {
    DietType["Balanced"] = "BALANCED";
    DietType["HighProtein"] = "HIGH_PROTEIN";
    DietType["Keto"] = "KETO";
    DietType["LowCarb"] = "LOW_CARB";
    DietType["LowFat"] = "LOW_FAT";
    DietType["MealReplacement"] = "MEAL_REPLACEMENT";
    DietType["VeryLowCalorie"] = "VERY_LOW_CALORIE";
})(DietType = exports.DietType || (exports.DietType = {}));
var DoctorAvailabilityStatus;
(function (DoctorAvailabilityStatus) {
    DoctorAvailabilityStatus["AvailableLevel_1"] = "AVAILABLE_LEVEL_1";
    DoctorAvailabilityStatus["AvailableLevel_2"] = "AVAILABLE_LEVEL_2";
    DoctorAvailabilityStatus["Unavailable"] = "UNAVAILABLE";
})(DoctorAvailabilityStatus = exports.DoctorAvailabilityStatus || (exports.DoctorAvailabilityStatus = {}));
var DoctorAvailabilityStatusEnum;
(function (DoctorAvailabilityStatusEnum) {
    DoctorAvailabilityStatusEnum["AvailableLevel_1"] = "AVAILABLE_LEVEL_1";
    DoctorAvailabilityStatusEnum["AvailableLevel_2"] = "AVAILABLE_LEVEL_2";
    DoctorAvailabilityStatusEnum["Unavailable"] = "UNAVAILABLE";
})(DoctorAvailabilityStatusEnum = exports.DoctorAvailabilityStatusEnum || (exports.DoctorAvailabilityStatusEnum = {}));
var DoctorErrorCode;
(function (DoctorErrorCode) {
    DoctorErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    DoctorErrorCode["Invalid"] = "INVALID";
    DoctorErrorCode["NotFound"] = "NOT_FOUND";
    DoctorErrorCode["Required"] = "REQUIRED";
    DoctorErrorCode["Unique"] = "UNIQUE";
})(DoctorErrorCode = exports.DoctorErrorCode || (exports.DoctorErrorCode = {}));
var DoctorOrderField;
(function (DoctorOrderField) {
    DoctorOrderField["Created"] = "CREATED";
    DoctorOrderField["Distance"] = "DISTANCE";
    DoctorOrderField["Rating"] = "RATING";
})(DoctorOrderField = exports.DoctorOrderField || (exports.DoctorOrderField = {}));
var DoctorSeniorityEnum;
(function (DoctorSeniorityEnum) {
    DoctorSeniorityEnum["Cp"] = "CP";
    DoctorSeniorityEnum["Gp"] = "GP";
    DoctorSeniorityEnum["Sp"] = "SP";
})(DoctorSeniorityEnum = exports.DoctorSeniorityEnum || (exports.DoctorSeniorityEnum = {}));
var EarlyRefillReasonErrorCode;
(function (EarlyRefillReasonErrorCode) {
    EarlyRefillReasonErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    EarlyRefillReasonErrorCode["Invalid"] = "INVALID";
    EarlyRefillReasonErrorCode["NotFound"] = "NOT_FOUND";
    EarlyRefillReasonErrorCode["Required"] = "REQUIRED";
    EarlyRefillReasonErrorCode["Unique"] = "UNIQUE";
})(EarlyRefillReasonErrorCode = exports.EarlyRefillReasonErrorCode || (exports.EarlyRefillReasonErrorCode = {}));
var EarlyRefillReasonType;
(function (EarlyRefillReasonType) {
    EarlyRefillReasonType["Custom"] = "CUSTOM";
    EarlyRefillReasonType["System"] = "SYSTEM";
})(EarlyRefillReasonType = exports.EarlyRefillReasonType || (exports.EarlyRefillReasonType = {}));
var EarlyRefillRequestStatus;
(function (EarlyRefillRequestStatus) {
    EarlyRefillRequestStatus["Approved"] = "APPROVED";
    EarlyRefillRequestStatus["Pending"] = "PENDING";
    EarlyRefillRequestStatus["Rejected"] = "REJECTED";
})(EarlyRefillRequestStatus = exports.EarlyRefillRequestStatus || (exports.EarlyRefillRequestStatus = {}));
var EducationLevel;
(function (EducationLevel) {
    EducationLevel["HighSchoolGraduate"] = "HIGH_SCHOOL_GRADUATE";
    EducationLevel["LessThanHighSchool"] = "LESS_THAN_HIGH_SCHOOL";
    EducationLevel["UniversityGraduate"] = "UNIVERSITY_GRADUATE";
})(EducationLevel = exports.EducationLevel || (exports.EducationLevel = {}));
var EntityType;
(function (EntityType) {
    EntityType["Boolean"] = "BOOLEAN";
    EntityType["Date"] = "DATE";
    EntityType["Id"] = "ID";
    EntityType["List"] = "LIST";
    EntityType["Number"] = "NUMBER";
    EntityType["String"] = "STRING";
})(EntityType = exports.EntityType || (exports.EntityType = {}));
var ErrorDetail;
(function (ErrorDetail) {
    /**
     * The deadline expired before the operation could complete.
     *
     * For operations that change the state of the system, this error
     * may be returned even if the operation has completed successfully.
     * For example, a successful response from a server could have been
     * delayed long enough for the deadline to expire.
     *
     * HTTP Mapping: 504 Gateway Timeout
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["DeadlineExceeded"] = "DEADLINE_EXCEEDED";
    /**
     * The server detected that the client is exhibiting a behavior that
     * might be generating excessive load.
     *
     * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["EnhanceYourCalm"] = "ENHANCE_YOUR_CALM";
    /**
     * The requested field is not found in the schema.
     *
     * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
     * query is valid, but is unable to return a result (if, for example, a
     * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
     * returned by the server to signify that the requested field is not known to exist.
     * This may be returned in lieu of failing the entire query.
     * See also `PERMISSION_DENIED` for cases where the
     * requested field is invalid only for the given user or class of users.
     *
     * HTTP Mapping: 404 Not Found
     * Error Type: BAD_REQUEST
     */
    ErrorDetail["FieldNotFound"] = "FIELD_NOT_FOUND";
    /**
     * The client specified an invalid argument.
     *
     * Note that this differs from `FAILED_PRECONDITION`.
     * `INVALID_ARGUMENT` indicates arguments that are problematic
     * regardless of the state of the system (e.g., a malformed file name).
     *
     * HTTP Mapping: 400 Bad Request
     * Error Type: BAD_REQUEST
     */
    ErrorDetail["InvalidArgument"] = "INVALID_ARGUMENT";
    /**
     * The provided cursor is not valid.
     *
     * The most common usage for this error is when a client is paginating
     * through a list that uses stateful cursors. In that case, the provided
     * cursor may be expired.
     *
     * HTTP Mapping: 404 Not Found
     * Error Type: NOT_FOUND
     */
    ErrorDetail["InvalidCursor"] = "INVALID_CURSOR";
    /**
     * Unable to perform operation because a required resource is missing.
     *
     * Example: Client is attempting to refresh a list, but the specified
     * list is expired. This requires an action by the client to get a new list.
     *
     * If the user is simply trying GET a resource that is not found,
     * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
     * is to be used particularly when the user is performing an operation
     * that requires a particular resource to exist.
     *
     * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
     * Error Type: FAILED_PRECONDITION
     */
    ErrorDetail["MissingResource"] = "MISSING_RESOURCE";
    /**
     * Service Error.
     *
     * There is a problem with an upstream service.
     *
     * This may be returned if a gateway receives an unknown error from a service
     * or if a service is unreachable.
     * If a request times out which waiting on a response from a service,
     * `DEADLINE_EXCEEDED` may be returned instead.
     * If a service returns a more specific error Type, the specific error Type may
     * be returned instead.
     *
     * HTTP Mapping: 502 Bad Gateway
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["ServiceError"] = "SERVICE_ERROR";
    /**
     * Request failed due to network errors.
     *
     * HTTP Mapping: 503 Unavailable
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["TcpFailure"] = "TCP_FAILURE";
    /**
     * Request throttled based on server concurrency limits.
     *
     * HTTP Mapping: 503 Unavailable
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["ThrottledConcurrency"] = "THROTTLED_CONCURRENCY";
    /**
     * Request throttled based on server CPU limits
     *
     * HTTP Mapping: 503 Unavailable.
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["ThrottledCpu"] = "THROTTLED_CPU";
    /**
     * The operation is not implemented or is not currently supported/enabled.
     *
     * HTTP Mapping: 501 Not Implemented
     * Error Type: BAD_REQUEST
     */
    ErrorDetail["Unimplemented"] = "UNIMPLEMENTED";
    /**
     * Unknown error.
     *
     * This error should only be returned when no other error detail applies.
     * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
     *
     * HTTP Mapping: 500 Internal Server Error
     */
    ErrorDetail["Unknown"] = "UNKNOWN";
})(ErrorDetail = exports.ErrorDetail || (exports.ErrorDetail = {}));
var ErrorType;
(function (ErrorType) {
    /**
     * Bad Request.
     *
     * There is a problem with the request.
     * Retrying the same request is not likely to succeed.
     * An example would be a query or argument that cannot be deserialized.
     *
     * HTTP Mapping: 400 Bad Request
     */
    ErrorType["BadRequest"] = "BAD_REQUEST";
    /**
     * The operation was rejected because the system is not in a state
     * required for the operation's execution.  For example, the directory
     * to be deleted is non-empty, an rmdir operation is applied to
     * a non-directory, etc.
     *
     * Service implementers can use the following guidelines to decide
     * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
     *
     * - Use `UNAVAILABLE` if the client can retry just the failing call.
     * - Use `FAILED_PRECONDITION` if the client should not retry until
     * the system state has been explicitly fixed.  E.g., if an "rmdir"
     *      fails because the directory is non-empty, `FAILED_PRECONDITION`
     * should be returned since the client should not retry unless
     * the files are deleted from the directory.
     *
     * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
     */
    ErrorType["FailedPrecondition"] = "FAILED_PRECONDITION";
    /**
     * Internal error.
     *
     * An unexpected internal error was encountered. This means that some
     * invariants expected by the underlying system have been broken.
     * This error code is reserved for serious errors.
     *
     * HTTP Mapping: 500 Internal Server Error
     */
    ErrorType["Internal"] = "INTERNAL";
    /**
     * The requested entity was not found.
     *
     * This could apply to a resource that has never existed (e.g. bad resource id),
     * or a resource that no longer exists (e.g. cache expired.)
     *
     * Note to server developers: if a request is denied for an entire class
     * of users, such as gradual feature rollout or undocumented allowlist,
     * `NOT_FOUND` may be used. If a request is denied for some users within
     * a class of users, such as user-based access control, `PERMISSION_DENIED`
     * must be used.
     *
     * HTTP Mapping: 404 Not Found
     */
    ErrorType["NotFound"] = "NOT_FOUND";
    /**
     * The caller does not have permission to execute the specified
     * operation.
     *
     * `PERMISSION_DENIED` must not be used for rejections
     * caused by exhausting some resource or quota.
     * `PERMISSION_DENIED` must not be used if the caller
     * cannot be identified (use `UNAUTHENTICATED`
     * instead for those errors).
     *
     * This error Type does not imply the
     * request is valid or the requested entity exists or satisfies
     * other pre-conditions.
     *
     * HTTP Mapping: 403 Forbidden
     */
    ErrorType["PermissionDenied"] = "PERMISSION_DENIED";
    /**
     * The request does not have valid authentication credentials.
     *
     * This is intended to be returned only for routes that require
     * authentication.
     *
     * HTTP Mapping: 401 Unauthorized
     */
    ErrorType["Unauthenticated"] = "UNAUTHENTICATED";
    /**
     * Currently Unavailable.
     *
     * The service is currently unavailable.  This is most likely a
     * transient condition, which can be corrected by retrying with
     * a backoff.
     *
     * HTTP Mapping: 503 Unavailable
     */
    ErrorType["Unavailable"] = "UNAVAILABLE";
    /**
     * Unknown error.
     *
     * For example, this error may be returned when
     * an error code received from another address space belongs to
     * an error space that is not known in this address space.  Also
     * errors raised by APIs that do not return enough error information
     * may be converted to this error.
     *
     * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
     * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
     * by an implementation as being equivalent to INTERNAL.
     *
     * When possible, a more specific error should be provided.
     *
     * HTTP Mapping: 520 Unknown Error
     */
    ErrorType["Unknown"] = "UNKNOWN";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));
var ExerciseDurationUnit;
(function (ExerciseDurationUnit) {
    ExerciseDurationUnit["Hours"] = "HOURS";
    ExerciseDurationUnit["Minutes"] = "MINUTES";
})(ExerciseDurationUnit = exports.ExerciseDurationUnit || (exports.ExerciseDurationUnit = {}));
var ExerciseType;
(function (ExerciseType) {
    ExerciseType["BodyweightExercises"] = "BODYWEIGHT_EXERCISES";
    ExerciseType["Cycling"] = "CYCLING";
    ExerciseType["Dancing"] = "DANCING";
    ExerciseType["Pilates"] = "PILATES";
    ExerciseType["ResistanceBandExercises"] = "RESISTANCE_BAND_EXERCISES";
    ExerciseType["StretchingRoutines"] = "STRETCHING_ROUTINES";
    ExerciseType["Swimming"] = "SWIMMING";
    ExerciseType["Walking"] = "WALKING";
    ExerciseType["WaterAerobics"] = "WATER_AEROBICS";
    ExerciseType["Weightlifting"] = "WEIGHTLIFTING";
    ExerciseType["Yoga"] = "YOGA";
})(ExerciseType = exports.ExerciseType || (exports.ExerciseType = {}));
var FieldStandard;
(function (FieldStandard) {
    FieldStandard["Cpt"] = "CPT";
    FieldStandard["Loinc"] = "LOINC";
})(FieldStandard = exports.FieldStandard || (exports.FieldStandard = {}));
var FileType;
(function (FileType) {
    FileType["Csv"] = "CSV";
    FileType["Pdf"] = "PDF";
    FileType["Txt"] = "TXT";
})(FileType = exports.FileType || (exports.FileType = {}));
var FlowrseIntegrationSortDirection;
(function (FlowrseIntegrationSortDirection) {
    FlowrseIntegrationSortDirection["Asc"] = "ASC";
    FlowrseIntegrationSortDirection["Desc"] = "DESC";
})(FlowrseIntegrationSortDirection = exports.FlowrseIntegrationSortDirection || (exports.FlowrseIntegrationSortDirection = {}));
var Gender;
(function (Gender) {
    Gender["Female"] = "FEMALE";
    Gender["Male"] = "MALE";
})(Gender = exports.Gender || (exports.Gender = {}));
var GuidedCareActivityType;
(function (GuidedCareActivityType) {
    GuidedCareActivityType["FacilityVisit"] = "FACILITY_VISIT";
    GuidedCareActivityType["HomeVisit"] = "HOME_VISIT";
    GuidedCareActivityType["Lab"] = "LAB";
    GuidedCareActivityType["MedicalForm"] = "MEDICAL_FORM";
    GuidedCareActivityType["MedicalMessageActivityInstruction"] = "MEDICAL_MESSAGE_ACTIVITY_INSTRUCTION";
    GuidedCareActivityType["MedicalMessageGeneral"] = "MEDICAL_MESSAGE_GENERAL";
    GuidedCareActivityType["MedicalMessageLifestyleReminder"] = "MEDICAL_MESSAGE_LIFESTYLE_REMINDER";
    GuidedCareActivityType["MedicalMessageMedical"] = "MEDICAL_MESSAGE_MEDICAL";
    GuidedCareActivityType["MedicalMessageMonitoring"] = "MEDICAL_MESSAGE_MONITORING";
    GuidedCareActivityType["OnlineVisit"] = "ONLINE_VISIT";
    GuidedCareActivityType["Rad"] = "RAD";
})(GuidedCareActivityType = exports.GuidedCareActivityType || (exports.GuidedCareActivityType = {}));
var GuidedCareCareJourneyItemStatus;
(function (GuidedCareCareJourneyItemStatus) {
    GuidedCareCareJourneyItemStatus["Accepted"] = "ACCEPTED";
    GuidedCareCareJourneyItemStatus["Cancelled"] = "CANCELLED";
    GuidedCareCareJourneyItemStatus["Completed"] = "COMPLETED";
    GuidedCareCareJourneyItemStatus["Failed"] = "FAILED";
    GuidedCareCareJourneyItemStatus["InProgress"] = "IN_PROGRESS";
    GuidedCareCareJourneyItemStatus["Missed"] = "MISSED";
    GuidedCareCareJourneyItemStatus["OnHold"] = "ON_HOLD";
    GuidedCareCareJourneyItemStatus["Pending"] = "PENDING";
    GuidedCareCareJourneyItemStatus["Received"] = "RECEIVED";
    GuidedCareCareJourneyItemStatus["Rejected"] = "REJECTED";
    GuidedCareCareJourneyItemStatus["Rescheduled"] = "RESCHEDULED";
})(GuidedCareCareJourneyItemStatus = exports.GuidedCareCareJourneyItemStatus || (exports.GuidedCareCareJourneyItemStatus = {}));
var GuidedCareHealthProgramSortingField;
(function (GuidedCareHealthProgramSortingField) {
    GuidedCareHealthProgramSortingField["Created"] = "CREATED";
    GuidedCareHealthProgramSortingField["Id"] = "ID";
    GuidedCareHealthProgramSortingField["IsActive"] = "IS_ACTIVE";
    GuidedCareHealthProgramSortingField["Modified"] = "MODIFIED";
    GuidedCareHealthProgramSortingField["Price"] = "PRICE";
    GuidedCareHealthProgramSortingField["ProgramName"] = "PROGRAM_NAME";
    GuidedCareHealthProgramSortingField["TemplateName"] = "TEMPLATE_NAME";
})(GuidedCareHealthProgramSortingField = exports.GuidedCareHealthProgramSortingField || (exports.GuidedCareHealthProgramSortingField = {}));
var GuidedCareJourneyItemPriority;
(function (GuidedCareJourneyItemPriority) {
    /** The request should be actioned as soon as possible - higher priority than urgent */
    GuidedCareJourneyItemPriority["Asap"] = "ASAP";
    /** The request has normal priority */
    GuidedCareJourneyItemPriority["Routine"] = "ROUTINE";
    /** The request should be actioned immediately - highest possible priority. E.g. an emergency */
    GuidedCareJourneyItemPriority["Stat"] = "STAT";
    /** The request should be actioned promptly - higher priority than routine */
    GuidedCareJourneyItemPriority["Urgent"] = "URGENT";
})(GuidedCareJourneyItemPriority = exports.GuidedCareJourneyItemPriority || (exports.GuidedCareJourneyItemPriority = {}));
var GuidedCareJourneyItemSource;
(function (GuidedCareJourneyItemSource) {
    GuidedCareJourneyItemSource["Personalized"] = "PERSONALIZED";
    GuidedCareJourneyItemSource["Program"] = "PROGRAM";
})(GuidedCareJourneyItemSource = exports.GuidedCareJourneyItemSource || (exports.GuidedCareJourneyItemSource = {}));
var GuidedCareJourneyItemStatus;
(function (GuidedCareJourneyItemStatus) {
    GuidedCareJourneyItemStatus["Accepted"] = "ACCEPTED";
    GuidedCareJourneyItemStatus["Cancelled"] = "CANCELLED";
    GuidedCareJourneyItemStatus["Completed"] = "COMPLETED";
    GuidedCareJourneyItemStatus["Failed"] = "FAILED";
    GuidedCareJourneyItemStatus["InProgress"] = "IN_PROGRESS";
    GuidedCareJourneyItemStatus["Missed"] = "MISSED";
    GuidedCareJourneyItemStatus["OnHold"] = "ON_HOLD";
    GuidedCareJourneyItemStatus["Pending"] = "PENDING";
    GuidedCareJourneyItemStatus["Received"] = "RECEIVED";
    GuidedCareJourneyItemStatus["Rejected"] = "REJECTED";
    GuidedCareJourneyItemStatus["Rescheduled"] = "RESCHEDULED";
})(GuidedCareJourneyItemStatus = exports.GuidedCareJourneyItemStatus || (exports.GuidedCareJourneyItemStatus = {}));
var GuidedCareJourneySortField;
(function (GuidedCareJourneySortField) {
    /** Sort by adherence. */
    GuidedCareJourneySortField["Adherence"] = "ADHERENCE";
    /** Sort by creation date. */
    GuidedCareJourneySortField["CreationDate"] = "CREATION_DATE";
    /** Sort by gaps. */
    GuidedCareJourneySortField["Gaps"] = "GAPS";
})(GuidedCareJourneySortField = exports.GuidedCareJourneySortField || (exports.GuidedCareJourneySortField = {}));
var GuidedCareJourneyStatus;
(function (GuidedCareJourneyStatus) {
    GuidedCareJourneyStatus["Cancelled"] = "CANCELLED";
    GuidedCareJourneyStatus["Completed"] = "COMPLETED";
    GuidedCareJourneyStatus["InProgress"] = "IN_PROGRESS";
})(GuidedCareJourneyStatus = exports.GuidedCareJourneyStatus || (exports.GuidedCareJourneyStatus = {}));
var GuidedCareTaskSortingField;
(function (GuidedCareTaskSortingField) {
    GuidedCareTaskSortingField["DueDate"] = "DUE_DATE";
    GuidedCareTaskSortingField["NextReminderDate"] = "NEXT_REMINDER_DATE";
})(GuidedCareTaskSortingField = exports.GuidedCareTaskSortingField || (exports.GuidedCareTaskSortingField = {}));
var GuidedCareType;
(function (GuidedCareType) {
    GuidedCareType["Acute"] = "ACUTE";
    GuidedCareType["Chronic"] = "CHRONIC";
    GuidedCareType["PostOperative"] = "POST_OPERATIVE";
    GuidedCareType["PreOperative"] = "PRE_OPERATIVE";
    GuidedCareType["Primary"] = "PRIMARY";
    GuidedCareType["SubAcute"] = "SUB_ACUTE";
    GuidedCareType["Supportive"] = "SUPPORTIVE";
})(GuidedCareType = exports.GuidedCareType || (exports.GuidedCareType = {}));
var H_DayOfWeek;
(function (H_DayOfWeek) {
    H_DayOfWeek["Friday"] = "Friday";
    H_DayOfWeek["Monday"] = "Monday";
    H_DayOfWeek["Saturday"] = "Saturday";
    H_DayOfWeek["Sunday"] = "Sunday";
    H_DayOfWeek["Thursday"] = "Thursday";
    H_DayOfWeek["Tuesday"] = "Tuesday";
    H_DayOfWeek["Wednesday"] = "Wednesday";
})(H_DayOfWeek = exports.H_DayOfWeek || (exports.H_DayOfWeek = {}));
var H_EntityErrorCode;
(function (H_EntityErrorCode) {
    H_EntityErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    H_EntityErrorCode["AppointmentOverlap"] = "APPOINTMENT_OVERLAP";
    H_EntityErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    H_EntityErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    H_EntityErrorCode["Invalid"] = "INVALID";
    H_EntityErrorCode["Mismatch"] = "MISMATCH";
    H_EntityErrorCode["NotFound"] = "NOT_FOUND";
    H_EntityErrorCode["Required"] = "REQUIRED";
    H_EntityErrorCode["Unique"] = "UNIQUE";
})(H_EntityErrorCode = exports.H_EntityErrorCode || (exports.H_EntityErrorCode = {}));
var H_Gender;
(function (H_Gender) {
    H_Gender["Female"] = "FEMALE";
    H_Gender["Male"] = "MALE";
})(H_Gender = exports.H_Gender || (exports.H_Gender = {}));
var H_OrderDirection;
(function (H_OrderDirection) {
    /** Specifies an ascending sort order. */
    H_OrderDirection["Asc"] = "ASC";
    /** Specifies a descending sort order. */
    H_OrderDirection["Desc"] = "DESC";
})(H_OrderDirection = exports.H_OrderDirection || (exports.H_OrderDirection = {}));
var H_RelationTypes;
(function (H_RelationTypes) {
    H_RelationTypes["Aunt"] = "AUNT";
    H_RelationTypes["Brother"] = "BROTHER";
    H_RelationTypes["Cousin"] = "COUSIN";
    H_RelationTypes["Daughter"] = "DAUGHTER";
    H_RelationTypes["Father"] = "FATHER";
    H_RelationTypes["Friend"] = "FRIEND";
    H_RelationTypes["Granddaughter"] = "GRANDDAUGHTER";
    H_RelationTypes["Grandfather"] = "GRANDFATHER";
    H_RelationTypes["Grandmother"] = "GRANDMOTHER";
    H_RelationTypes["Grandson"] = "GRANDSON";
    H_RelationTypes["Husband"] = "HUSBAND";
    H_RelationTypes["Mother"] = "MOTHER";
    H_RelationTypes["Nephew"] = "NEPHEW";
    H_RelationTypes["Niece"] = "NIECE";
    H_RelationTypes["Other"] = "OTHER";
    H_RelationTypes["Sister"] = "SISTER";
    H_RelationTypes["Son"] = "SON";
    H_RelationTypes["Uncle"] = "UNCLE";
    H_RelationTypes["Wife"] = "WIFE";
})(H_RelationTypes = exports.H_RelationTypes || (exports.H_RelationTypes = {}));
var H_ReportingPeriod;
(function (H_ReportingPeriod) {
    H_ReportingPeriod["Last_7Days"] = "LAST_7_DAYS";
    H_ReportingPeriod["ThisMonth"] = "THIS_MONTH";
    H_ReportingPeriod["Today"] = "TODAY";
})(H_ReportingPeriod = exports.H_ReportingPeriod || (exports.H_ReportingPeriod = {}));
var HealthChannelSubscriptionType;
(function (HealthChannelSubscriptionType) {
    HealthChannelSubscriptionType["Free"] = "FREE";
    HealthChannelSubscriptionType["Paid"] = "PAID";
})(HealthChannelSubscriptionType = exports.HealthChannelSubscriptionType || (exports.HealthChannelSubscriptionType = {}));
var HealthConditionDiagnosisSortingField;
(function (HealthConditionDiagnosisSortingField) {
    HealthConditionDiagnosisSortingField["Code"] = "CODE";
    HealthConditionDiagnosisSortingField["CreatedDate"] = "CREATED_DATE";
})(HealthConditionDiagnosisSortingField = exports.HealthConditionDiagnosisSortingField || (exports.HealthConditionDiagnosisSortingField = {}));
var HealthConditionSortingField;
(function (HealthConditionSortingField) {
    HealthConditionSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    HealthConditionSortingField["Code"] = "CODE";
    HealthConditionSortingField["CreatedDate"] = "CREATED_DATE";
    HealthConditionSortingField["Disease"] = "DISEASE";
    HealthConditionSortingField["Display"] = "DISPLAY";
    HealthConditionSortingField["IsActive"] = "IS_ACTIVE";
    HealthConditionSortingField["Type"] = "TYPE";
})(HealthConditionSortingField = exports.HealthConditionSortingField || (exports.HealthConditionSortingField = {}));
var HealthConditionType;
(function (HealthConditionType) {
    HealthConditionType["Acute"] = "ACUTE";
    HealthConditionType["Chronic"] = "CHRONIC";
    HealthConditionType["SubAcute"] = "SUB_ACUTE";
})(HealthConditionType = exports.HealthConditionType || (exports.HealthConditionType = {}));
var HealthHistoryType;
(function (HealthHistoryType) {
    HealthHistoryType["Allergy"] = "ALLERGY";
    HealthHistoryType["Diagnose"] = "DIAGNOSE";
    HealthHistoryType["Family"] = "FAMILY";
    HealthHistoryType["Medical"] = "MEDICAL";
    HealthHistoryType["Surgery"] = "SURGERY";
})(HealthHistoryType = exports.HealthHistoryType || (exports.HealthHistoryType = {}));
var HealthParameterSortingField;
(function (HealthParameterSortingField) {
    HealthParameterSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    HealthParameterSortingField["Code"] = "CODE";
    HealthParameterSortingField["CreatedDate"] = "CREATED_DATE";
    HealthParameterSortingField["Display"] = "DISPLAY";
    HealthParameterSortingField["StandardCode"] = "STANDARD_CODE";
})(HealthParameterSortingField = exports.HealthParameterSortingField || (exports.HealthParameterSortingField = {}));
var HealthProgramBenefitOptionCode;
(function (HealthProgramBenefitOptionCode) {
    HealthProgramBenefitOptionCode["MaxOfFreeAudioVideoCalls"] = "MAX_OF_FREE_AUDIO_VIDEO_CALLS";
    HealthProgramBenefitOptionCode["NumberOfProactiveAudioVideoCalls"] = "NUMBER_OF_PROACTIVE_AUDIO_VIDEO_CALLS";
    HealthProgramBenefitOptionCode["NumberOfReactiveAudioVideoCalls"] = "NUMBER_OF_REACTIVE_AUDIO_VIDEO_CALLS";
    HealthProgramBenefitOptionCode["PatientSharePercentage"] = "PATIENT_SHARE_PERCENTAGE";
    HealthProgramBenefitOptionCode["ProactiveAtHomePlannedHealthServices"] = "PROACTIVE_AT_HOME_PLANNED_HEALTH_SERVICES";
    HealthProgramBenefitOptionCode["ProactiveInPersonPlannedHealthServices"] = "PROACTIVE_IN_PERSON_PLANNED_HEALTH_SERVICES";
    HealthProgramBenefitOptionCode["ReactiveAtHomeHealthServices"] = "REACTIVE_AT_HOME_HEALTH_SERVICES";
    HealthProgramBenefitOptionCode["ReactiveInPersonHealthServices"] = "REACTIVE_IN_PERSON_HEALTH_SERVICES";
})(HealthProgramBenefitOptionCode = exports.HealthProgramBenefitOptionCode || (exports.HealthProgramBenefitOptionCode = {}));
var HealthProgramMemberListSortingField;
(function (HealthProgramMemberListSortingField) {
    HealthProgramMemberListSortingField["Created"] = "CREATED";
    HealthProgramMemberListSortingField["IsActive"] = "IS_ACTIVE";
    HealthProgramMemberListSortingField["Name"] = "NAME";
    HealthProgramMemberListSortingField["Updated"] = "UPDATED";
})(HealthProgramMemberListSortingField = exports.HealthProgramMemberListSortingField || (exports.HealthProgramMemberListSortingField = {}));
var HealthProgramMemberSortingField;
(function (HealthProgramMemberSortingField) {
    HealthProgramMemberSortingField["Created"] = "CREATED";
    HealthProgramMemberSortingField["InsuranceId"] = "INSURANCE_ID";
    HealthProgramMemberSortingField["Name"] = "NAME";
    HealthProgramMemberSortingField["NationalId"] = "NATIONAL_ID";
    HealthProgramMemberSortingField["Updated"] = "UPDATED";
})(HealthProgramMemberSortingField = exports.HealthProgramMemberSortingField || (exports.HealthProgramMemberSortingField = {}));
var HealthProgramMembershipRequestSortingField;
(function (HealthProgramMembershipRequestSortingField) {
    HealthProgramMembershipRequestSortingField["Created"] = "CREATED";
    HealthProgramMembershipRequestSortingField["InsuranceId"] = "INSURANCE_ID";
    HealthProgramMembershipRequestSortingField["Name"] = "NAME";
    HealthProgramMembershipRequestSortingField["NationalId"] = "NATIONAL_ID";
    HealthProgramMembershipRequestSortingField["Updated"] = "UPDATED";
})(HealthProgramMembershipRequestSortingField = exports.HealthProgramMembershipRequestSortingField || (exports.HealthProgramMembershipRequestSortingField = {}));
var HealthProgramNetworkSortingField;
(function (HealthProgramNetworkSortingField) {
    HealthProgramNetworkSortingField["Code"] = "CODE";
    HealthProgramNetworkSortingField["Created"] = "CREATED";
    HealthProgramNetworkSortingField["IsActive"] = "IS_ACTIVE";
    HealthProgramNetworkSortingField["Name"] = "NAME";
    HealthProgramNetworkSortingField["Updated"] = "UPDATED";
})(HealthProgramNetworkSortingField = exports.HealthProgramNetworkSortingField || (exports.HealthProgramNetworkSortingField = {}));
var HealthProgramOptionType;
(function (HealthProgramOptionType) {
    HealthProgramOptionType["Include"] = "INCLUDE";
    HealthProgramOptionType["Limitation"] = "LIMITATION";
})(HealthProgramOptionType = exports.HealthProgramOptionType || (exports.HealthProgramOptionType = {}));
var HealthProgramSortingField;
(function (HealthProgramSortingField) {
    HealthProgramSortingField["Code"] = "CODE";
    HealthProgramSortingField["Created"] = "CREATED";
    HealthProgramSortingField["IsActive"] = "IS_ACTIVE";
    HealthProgramSortingField["Name"] = "NAME";
    HealthProgramSortingField["Type"] = "TYPE";
    HealthProgramSortingField["Updated"] = "UPDATED";
})(HealthProgramSortingField = exports.HealthProgramSortingField || (exports.HealthProgramSortingField = {}));
var HealthProgramSubscriptionPeriod;
(function (HealthProgramSubscriptionPeriod) {
    HealthProgramSubscriptionPeriod["Monthly"] = "MONTHLY";
    HealthProgramSubscriptionPeriod["Yearly"] = "YEARLY";
})(HealthProgramSubscriptionPeriod = exports.HealthProgramSubscriptionPeriod || (exports.HealthProgramSubscriptionPeriod = {}));
var HealthProgramTemplateSortingField;
(function (HealthProgramTemplateSortingField) {
    HealthProgramTemplateSortingField["Created"] = "CREATED";
    HealthProgramTemplateSortingField["GuidedCareType"] = "GUIDED_CARE_TYPE";
    HealthProgramTemplateSortingField["Id"] = "ID";
    HealthProgramTemplateSortingField["IsDraft"] = "IS_DRAFT";
    HealthProgramTemplateSortingField["Modified"] = "MODIFIED";
    HealthProgramTemplateSortingField["Name"] = "NAME";
})(HealthProgramTemplateSortingField = exports.HealthProgramTemplateSortingField || (exports.HealthProgramTemplateSortingField = {}));
var HealthProgramType;
(function (HealthProgramType) {
    HealthProgramType["ImmediateCare"] = "IMMEDIATE_CARE";
    HealthProgramType["ScheduledCare"] = "SCHEDULED_CARE";
})(HealthProgramType = exports.HealthProgramType || (exports.HealthProgramType = {}));
var HealthSymptomErrorCode;
(function (HealthSymptomErrorCode) {
    HealthSymptomErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    HealthSymptomErrorCode["Invalid"] = "INVALID";
    HealthSymptomErrorCode["NotFound"] = "NOT_FOUND";
    HealthSymptomErrorCode["Required"] = "REQUIRED";
    HealthSymptomErrorCode["Unique"] = "UNIQUE";
})(HealthSymptomErrorCode = exports.HealthSymptomErrorCode || (exports.HealthSymptomErrorCode = {}));
var HealthSymptomOrderField;
(function (HealthSymptomOrderField) {
    HealthSymptomOrderField["Created"] = "CREATED";
    HealthSymptomOrderField["Name"] = "NAME";
})(HealthSymptomOrderField = exports.HealthSymptomOrderField || (exports.HealthSymptomOrderField = {}));
var InsuranceSortField;
(function (InsuranceSortField) {
    /** Sort by creation date. */
    InsuranceSortField["CreationDate"] = "CREATION_DATE";
    /** Sort by name. */
    InsuranceSortField["Name"] = "NAME";
})(InsuranceSortField = exports.InsuranceSortField || (exports.InsuranceSortField = {}));
var InterventionActivationType;
(function (InterventionActivationType) {
    InterventionActivationType["Merge"] = "MERGE";
    InterventionActivationType["Replace"] = "REPLACE";
})(InterventionActivationType = exports.InterventionActivationType || (exports.InterventionActivationType = {}));
var JourneyItemClassification;
(function (JourneyItemClassification) {
    JourneyItemClassification["Essential"] = "ESSENTIAL";
    JourneyItemClassification["Supportive"] = "SUPPORTIVE";
})(JourneyItemClassification = exports.JourneyItemClassification || (exports.JourneyItemClassification = {}));
var KeywordSearch;
(function (KeywordSearch) {
    KeywordSearch["All"] = "ALL";
    KeywordSearch["Code"] = "CODE";
    KeywordSearch["Display"] = "DISPLAY";
})(KeywordSearch = exports.KeywordSearch || (exports.KeywordSearch = {}));
var LabTemplateSortingField;
(function (LabTemplateSortingField) {
    LabTemplateSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    LabTemplateSortingField["Code"] = "CODE";
    LabTemplateSortingField["CreatedDate"] = "CREATED_DATE";
    LabTemplateSortingField["Display"] = "DISPLAY";
    LabTemplateSortingField["IsActive"] = "IS_ACTIVE";
})(LabTemplateSortingField = exports.LabTemplateSortingField || (exports.LabTemplateSortingField = {}));
var LanguageCodeEnum;
(function (LanguageCodeEnum) {
    LanguageCodeEnum["Ar"] = "AR";
    LanguageCodeEnum["Az"] = "AZ";
    LanguageCodeEnum["Bg"] = "BG";
    LanguageCodeEnum["Bn"] = "BN";
    LanguageCodeEnum["Ca"] = "CA";
    LanguageCodeEnum["Cs"] = "CS";
    LanguageCodeEnum["Da"] = "DA";
    LanguageCodeEnum["De"] = "DE";
    LanguageCodeEnum["El"] = "EL";
    LanguageCodeEnum["En"] = "EN";
    LanguageCodeEnum["Es"] = "ES";
    LanguageCodeEnum["EsCo"] = "ES_CO";
    LanguageCodeEnum["Et"] = "ET";
    LanguageCodeEnum["Fa"] = "FA";
    LanguageCodeEnum["Fi"] = "FI";
    LanguageCodeEnum["Fr"] = "FR";
    LanguageCodeEnum["Hi"] = "HI";
    LanguageCodeEnum["Hu"] = "HU";
    LanguageCodeEnum["Hy"] = "HY";
    LanguageCodeEnum["Id"] = "ID";
    LanguageCodeEnum["Is"] = "IS";
    LanguageCodeEnum["It"] = "IT";
    LanguageCodeEnum["Ja"] = "JA";
    LanguageCodeEnum["Ko"] = "KO";
    LanguageCodeEnum["Lt"] = "LT";
    LanguageCodeEnum["Mn"] = "MN";
    LanguageCodeEnum["Nb"] = "NB";
    LanguageCodeEnum["Nl"] = "NL";
    LanguageCodeEnum["Pl"] = "PL";
    LanguageCodeEnum["Pt"] = "PT";
    LanguageCodeEnum["PtBr"] = "PT_BR";
    LanguageCodeEnum["Ro"] = "RO";
    LanguageCodeEnum["Ru"] = "RU";
    LanguageCodeEnum["Sk"] = "SK";
    LanguageCodeEnum["Sl"] = "SL";
    LanguageCodeEnum["Sq"] = "SQ";
    LanguageCodeEnum["Sr"] = "SR";
    LanguageCodeEnum["Sv"] = "SV";
    LanguageCodeEnum["Sw"] = "SW";
    LanguageCodeEnum["Ta"] = "TA";
    LanguageCodeEnum["Th"] = "TH";
    LanguageCodeEnum["Tr"] = "TR";
    LanguageCodeEnum["Uk"] = "UK";
    LanguageCodeEnum["Vi"] = "VI";
    LanguageCodeEnum["ZhHans"] = "ZH_HANS";
    LanguageCodeEnum["ZhHant"] = "ZH_HANT";
})(LanguageCodeEnum = exports.LanguageCodeEnum || (exports.LanguageCodeEnum = {}));
var MaritalStatusesEnum;
(function (MaritalStatusesEnum) {
    MaritalStatusesEnum["Divorced"] = "DIVORCED";
    MaritalStatusesEnum["Married"] = "MARRIED";
    MaritalStatusesEnum["Single"] = "SINGLE";
    MaritalStatusesEnum["Widowed"] = "WIDOWED";
})(MaritalStatusesEnum = exports.MaritalStatusesEnum || (exports.MaritalStatusesEnum = {}));
var MarketplaceApprovalStatus;
(function (MarketplaceApprovalStatus) {
    MarketplaceApprovalStatus["Approved"] = "APPROVED";
    MarketplaceApprovalStatus["Draft"] = "DRAFT";
    MarketplaceApprovalStatus["PendingApproval"] = "PENDING_APPROVAL";
    MarketplaceApprovalStatus["Rejected"] = "REJECTED";
})(MarketplaceApprovalStatus = exports.MarketplaceApprovalStatus || (exports.MarketplaceApprovalStatus = {}));
var MarketplaceContentType;
(function (MarketplaceContentType) {
    MarketplaceContentType["Html"] = "HTML";
    MarketplaceContentType["Md"] = "MD";
    MarketplaceContentType["Text"] = "TEXT";
})(MarketplaceContentType = exports.MarketplaceContentType || (exports.MarketplaceContentType = {}));
var MarketplaceDefaultSortingField;
(function (MarketplaceDefaultSortingField) {
    MarketplaceDefaultSortingField["Created"] = "CREATED";
    MarketplaceDefaultSortingField["Name"] = "NAME";
    MarketplaceDefaultSortingField["NameAr"] = "NAME_AR";
})(MarketplaceDefaultSortingField = exports.MarketplaceDefaultSortingField || (exports.MarketplaceDefaultSortingField = {}));
var MarketplaceDiscountSortingField;
(function (MarketplaceDiscountSortingField) {
    MarketplaceDiscountSortingField["Amount"] = "AMOUNT";
    MarketplaceDiscountSortingField["Created"] = "CREATED";
    MarketplaceDiscountSortingField["EndDate"] = "END_DATE";
    MarketplaceDiscountSortingField["Name"] = "NAME";
    MarketplaceDiscountSortingField["Percentage"] = "PERCENTAGE";
    MarketplaceDiscountSortingField["Published"] = "PUBLISHED";
    MarketplaceDiscountSortingField["StartDate"] = "START_DATE";
    MarketplaceDiscountSortingField["Type"] = "TYPE";
})(MarketplaceDiscountSortingField = exports.MarketplaceDiscountSortingField || (exports.MarketplaceDiscountSortingField = {}));
var MarketplaceDiscountType;
(function (MarketplaceDiscountType) {
    MarketplaceDiscountType["Fixed"] = "FIXED";
    MarketplaceDiscountType["Percentage"] = "PERCENTAGE";
})(MarketplaceDiscountType = exports.MarketplaceDiscountType || (exports.MarketplaceDiscountType = {}));
var MarketplaceGender;
(function (MarketplaceGender) {
    MarketplaceGender["All"] = "ALL";
    MarketplaceGender["Female"] = "FEMALE";
    MarketplaceGender["Male"] = "MALE";
})(MarketplaceGender = exports.MarketplaceGender || (exports.MarketplaceGender = {}));
var MarketplaceHealthPackageSortingField;
(function (MarketplaceHealthPackageSortingField) {
    MarketplaceHealthPackageSortingField["Created"] = "CREATED";
    MarketplaceHealthPackageSortingField["Name"] = "NAME";
    MarketplaceHealthPackageSortingField["NameAr"] = "NAME_AR";
    MarketplaceHealthPackageSortingField["Price"] = "PRICE";
})(MarketplaceHealthPackageSortingField = exports.MarketplaceHealthPackageSortingField || (exports.MarketplaceHealthPackageSortingField = {}));
var MarketplaceOrderSortingField;
(function (MarketplaceOrderSortingField) {
    MarketplaceOrderSortingField["Created"] = "CREATED";
    MarketplaceOrderSortingField["PaymentMethod"] = "PAYMENT_METHOD";
    MarketplaceOrderSortingField["Status"] = "STATUS";
    MarketplaceOrderSortingField["Type"] = "TYPE";
})(MarketplaceOrderSortingField = exports.MarketplaceOrderSortingField || (exports.MarketplaceOrderSortingField = {}));
var MarketplaceOrderStatus;
(function (MarketplaceOrderStatus) {
    MarketplaceOrderStatus["Accepted"] = "ACCEPTED";
    MarketplaceOrderStatus["Cancelled"] = "CANCELLED";
    MarketplaceOrderStatus["Consumed"] = "CONSUMED";
    MarketplaceOrderStatus["Delivered"] = "DELIVERED";
    MarketplaceOrderStatus["New"] = "NEW";
    MarketplaceOrderStatus["OutForDelivery"] = "OUT_FOR_DELIVERY";
    MarketplaceOrderStatus["Rejected"] = "REJECTED";
})(MarketplaceOrderStatus = exports.MarketplaceOrderStatus || (exports.MarketplaceOrderStatus = {}));
var MarketplaceOrderType;
(function (MarketplaceOrderType) {
    MarketplaceOrderType["HealthPackage"] = "HEALTH_PACKAGE";
    MarketplaceOrderType["Product"] = "PRODUCT";
})(MarketplaceOrderType = exports.MarketplaceOrderType || (exports.MarketplaceOrderType = {}));
var MarketplacePaymentMethod;
(function (MarketplacePaymentMethod) {
    MarketplacePaymentMethod["CreditWhenArrive"] = "CREDIT_WHEN_ARRIVE";
    MarketplacePaymentMethod["PayNow"] = "PAY_NOW";
})(MarketplacePaymentMethod = exports.MarketplacePaymentMethod || (exports.MarketplacePaymentMethod = {}));
var MarketplaceProductSortingField;
(function (MarketplaceProductSortingField) {
    MarketplaceProductSortingField["Created"] = "CREATED";
    MarketplaceProductSortingField["Name"] = "NAME";
    MarketplaceProductSortingField["NameAr"] = "NAME_AR";
    MarketplaceProductSortingField["Price"] = "PRICE";
    MarketplaceProductSortingField["Published"] = "PUBLISHED";
})(MarketplaceProductSortingField = exports.MarketplaceProductSortingField || (exports.MarketplaceProductSortingField = {}));
var MarketplacePromotionSortingField;
(function (MarketplacePromotionSortingField) {
    MarketplacePromotionSortingField["Created"] = "CREATED";
    MarketplacePromotionSortingField["EndDate"] = "END_DATE";
    MarketplacePromotionSortingField["Name"] = "NAME";
    MarketplacePromotionSortingField["Published"] = "PUBLISHED";
    MarketplacePromotionSortingField["StartDate"] = "START_DATE";
})(MarketplacePromotionSortingField = exports.MarketplacePromotionSortingField || (exports.MarketplacePromotionSortingField = {}));
var MarketplaceSortDirection;
(function (MarketplaceSortDirection) {
    MarketplaceSortDirection["Asc"] = "ASC";
    MarketplaceSortDirection["Desc"] = "DESC";
})(MarketplaceSortDirection = exports.MarketplaceSortDirection || (exports.MarketplaceSortDirection = {}));
var MedicalFormCategory;
(function (MedicalFormCategory) {
    MedicalFormCategory["Condition"] = "CONDITION";
    MedicalFormCategory["Disease"] = "DISEASE";
    MedicalFormCategory["General"] = "GENERAL";
    MedicalFormCategory["Procedure"] = "PROCEDURE";
})(MedicalFormCategory = exports.MedicalFormCategory || (exports.MedicalFormCategory = {}));
var MedicalFormSortingField;
(function (MedicalFormSortingField) {
    MedicalFormSortingField["Active"] = "ACTIVE";
    MedicalFormSortingField["Category"] = "CATEGORY";
    MedicalFormSortingField["CreatedDate"] = "CREATED_DATE";
    MedicalFormSortingField["Name"] = "NAME";
    MedicalFormSortingField["NameAr"] = "NAME_AR";
    MedicalFormSortingField["Type"] = "TYPE";
    MedicalFormSortingField["UpdatedDate"] = "UPDATED_DATE";
})(MedicalFormSortingField = exports.MedicalFormSortingField || (exports.MedicalFormSortingField = {}));
var MedicalFormType;
(function (MedicalFormType) {
    MedicalFormType["Administrative"] = "ADMINISTRATIVE";
    MedicalFormType["Assessment"] = "ASSESSMENT";
    MedicalFormType["History"] = "HISTORY";
    MedicalFormType["Prem"] = "PREM";
    MedicalFormType["Prom"] = "PROM";
    MedicalFormType["Quizzes"] = "QUIZZES";
})(MedicalFormType = exports.MedicalFormType || (exports.MedicalFormType = {}));
var MedicalMessageCategory;
(function (MedicalMessageCategory) {
    MedicalMessageCategory["ActivityInstruction"] = "ACTIVITY_INSTRUCTION";
    MedicalMessageCategory["General"] = "GENERAL";
    MedicalMessageCategory["LifestyleReminder"] = "LIFESTYLE_REMINDER";
    MedicalMessageCategory["Medical"] = "MEDICAL";
    MedicalMessageCategory["MonitoringReminder"] = "MONITORING_REMINDER";
})(MedicalMessageCategory = exports.MedicalMessageCategory || (exports.MedicalMessageCategory = {}));
var MedicalMessageSortField;
(function (MedicalMessageSortField) {
    /** Sort by creation date. */
    MedicalMessageSortField["CreatedDate"] = "CREATED_DATE";
    /** Sort by update date. */
    MedicalMessageSortField["LastModifiedDate"] = "LAST_MODIFIED_DATE";
    /** Sort by name. */
    MedicalMessageSortField["Name"] = "NAME";
})(MedicalMessageSortField = exports.MedicalMessageSortField || (exports.MedicalMessageSortField = {}));
var MedicalMessageType;
(function (MedicalMessageType) {
    MedicalMessageType["Article"] = "ARTICLE";
    MedicalMessageType["Text"] = "TEXT";
    MedicalMessageType["Video"] = "VIDEO";
})(MedicalMessageType = exports.MedicalMessageType || (exports.MedicalMessageType = {}));
var MedicalMessageVariantSortField;
(function (MedicalMessageVariantSortField) {
    /** Sort by creation date. */
    MedicalMessageVariantSortField["CreatedDate"] = "CREATED_DATE";
    MedicalMessageVariantSortField["Id"] = "ID";
    MedicalMessageVariantSortField["IsActive"] = "IS_ACTIVE";
    /** Sort by update date. */
    MedicalMessageVariantSortField["LastModifiedDate"] = "LAST_MODIFIED_DATE";
})(MedicalMessageVariantSortField = exports.MedicalMessageVariantSortField || (exports.MedicalMessageVariantSortField = {}));
var MedicationDownloadAction;
(function (MedicationDownloadAction) {
    MedicationDownloadAction["Added"] = "ADDED";
    MedicationDownloadAction["Original"] = "ORIGINAL";
    MedicationDownloadAction["Removed"] = "REMOVED";
})(MedicationDownloadAction = exports.MedicationDownloadAction || (exports.MedicationDownloadAction = {}));
var NotificationChannel;
(function (NotificationChannel) {
    NotificationChannel["App"] = "APP";
    NotificationChannel["Email"] = "EMAIL";
    NotificationChannel["Sms"] = "SMS";
})(NotificationChannel = exports.NotificationChannel || (exports.NotificationChannel = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["BulkFile"] = "BULK_FILE";
    NotificationType["Chat"] = "CHAT";
    NotificationType["ChronicProgramEnrollment"] = "CHRONIC_PROGRAM_ENROLLMENT";
    NotificationType["DoctorAvailabilityStatusChanged"] = "DOCTOR_AVAILABILITY_STATUS_CHANGED";
    NotificationType["ErxCreate"] = "ERX_CREATE";
    NotificationType["ErxDeliveryStatusUpdate"] = "ERX_DELIVERY_STATUS_UPDATE";
    NotificationType["ErxStatusUpdate"] = "ERX_STATUS_UPDATE";
    NotificationType["GuidedCareAppointmentBookingReminder"] = "GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER";
    NotificationType["GuidedCareInterventionNotification"] = "GUIDED_CARE_INTERVENTION_NOTIFICATION";
    NotificationType["GuidedCareTaskNotification"] = "GUIDED_CARE_TASK_NOTIFICATION";
    NotificationType["HealthParameters"] = "HEALTH_PARAMETERS";
    NotificationType["HealthProgramAppointmentReminder"] = "HEALTH_PROGRAM_APPOINTMENT_REMINDER";
    NotificationType["HealthProgramAppointmentStatusChanged"] = "HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED";
    NotificationType["HealthProgramNotification"] = "HEALTH_PROGRAM_NOTIFICATION";
    NotificationType["HealthProgramVisitStatusChanged"] = "HEALTH_PROGRAM_VISIT_STATUS_CHANGED";
    NotificationType["MarketplaceOrderStatusUpdate"] = "MARKETPLACE_ORDER_STATUS_UPDATE";
    NotificationType["MarkAllAsRead"] = "MARK_ALL_AS_READ";
    NotificationType["MarkAsRead"] = "MARK_AS_READ";
    NotificationType["MedicalForm"] = "MEDICAL_FORM";
    NotificationType["MedicalMessage"] = "MEDICAL_MESSAGE";
    NotificationType["NeedActionCodeSystem"] = "NEED_ACTION_CODE_SYSTEM";
    NotificationType["NewNotification"] = "NEW_NOTIFICATION";
    NotificationType["OrderDeleted"] = "ORDER_DELETED";
    NotificationType["OrderStatusUpdate"] = "ORDER_STATUS_UPDATE";
    NotificationType["OrderTimeoutAlert"] = "ORDER_TIMEOUT_ALERT";
    NotificationType["PatientProfileAccessClose"] = "PATIENT_PROFILE_ACCESS_CLOSE";
    NotificationType["PatientProfileAccessRequest"] = "PATIENT_PROFILE_ACCESS_REQUEST";
    NotificationType["PatientProfileAccessResponse"] = "PATIENT_PROFILE_ACCESS_RESPONSE";
    NotificationType["PaymentNotification"] = "PAYMENT_NOTIFICATION";
    NotificationType["PingPong"] = "PING_PONG";
    NotificationType["PrescriptionAuthStatusChanged"] = "PRESCRIPTION_AUTH_STATUS_CHANGED";
    NotificationType["SurveyNotification"] = "SURVEY_NOTIFICATION";
    NotificationType["VisitVitalSigns"] = "VISIT_VITAL_SIGNS";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
var OperationStatus;
(function (OperationStatus) {
    OperationStatus["Failed"] = "FAILED";
    OperationStatus["Pending"] = "PENDING";
    OperationStatus["Successful"] = "SUCCESSFUL";
})(OperationStatus = exports.OperationStatus || (exports.OperationStatus = {}));
var OperationType;
(function (OperationType) {
    OperationType["AddToWallet"] = "ADD_TO_WALLET";
    OperationType["DeductFromWallet"] = "DEDUCT_FROM_WALLET";
    OperationType["Refund"] = "REFUND";
    OperationType["RefundFromWallet"] = "REFUND_FROM_WALLET";
})(OperationType = exports.OperationType || (exports.OperationType = {}));
var Operator;
(function (Operator) {
    Operator["Contains"] = "CONTAINS";
    Operator["Count"] = "COUNT";
    Operator["EndWith"] = "END_WITH";
    Operator["Equal"] = "EQUAL";
    Operator["GreaterThan"] = "GREATER_THAN";
    Operator["GreaterThanOrEqual"] = "GREATER_THAN_OR_EQUAL";
    Operator["In"] = "IN";
    Operator["IsEmpty"] = "IS_EMPTY";
    Operator["IsNotEmpty"] = "IS_NOT_EMPTY";
    Operator["LessThan"] = "LESS_THAN";
    Operator["LessThanOrEqual"] = "LESS_THAN_OR_EQUAL";
    Operator["No"] = "NO";
    Operator["NotContains"] = "NOT_CONTAINS";
    Operator["NotEqual"] = "NOT_EQUAL";
    Operator["NotIn"] = "NOT_IN";
    Operator["StartWith"] = "START_WITH";
    Operator["Yes"] = "YES";
})(Operator = exports.Operator || (exports.Operator = {}));
var OrderDeliveryStatusEnum;
(function (OrderDeliveryStatusEnum) {
    OrderDeliveryStatusEnum["CarrierAllocated"] = "CARRIER_ALLOCATED";
    OrderDeliveryStatusEnum["DeliveryTimedOut"] = "DELIVERY_TIMED_OUT";
    OrderDeliveryStatusEnum["DriverAcceptedOrder"] = "DRIVER_ACCEPTED_ORDER";
    OrderDeliveryStatusEnum["DriverIsAboutToArrive"] = "DRIVER_IS_ABOUT_TO_ARRIVE";
    OrderDeliveryStatusEnum["DriverPickedUpOrder"] = "DRIVER_PICKED_UP_ORDER";
    OrderDeliveryStatusEnum["Failed"] = "FAILED";
    OrderDeliveryStatusEnum["OrderIsCancelled"] = "ORDER_IS_CANCELLED";
    OrderDeliveryStatusEnum["OrderIsDelivered"] = "ORDER_IS_DELIVERED";
    OrderDeliveryStatusEnum["OrderIsResumed"] = "ORDER_IS_RESUMED";
    OrderDeliveryStatusEnum["OrderIsSuspended"] = "ORDER_IS_SUSPENDED";
    OrderDeliveryStatusEnum["Pending"] = "PENDING";
    OrderDeliveryStatusEnum["PickedByConsumer"] = "PICKED_BY_CONSUMER";
    OrderDeliveryStatusEnum["Returned"] = "RETURNED";
})(OrderDeliveryStatusEnum = exports.OrderDeliveryStatusEnum || (exports.OrderDeliveryStatusEnum = {}));
var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "ASC";
    OrderDirection["Desc"] = "DESC";
})(OrderDirection = exports.OrderDirection || (exports.OrderDirection = {}));
var OrderErrorCode;
(function (OrderErrorCode) {
    OrderErrorCode["BillingAddressNotSet"] = "BILLING_ADDRESS_NOT_SET";
    OrderErrorCode["CannotAcceptOrder"] = "CANNOT_ACCEPT_ORDER";
    OrderErrorCode["CannotCancelFulfillment"] = "CANNOT_CANCEL_FULFILLMENT";
    OrderErrorCode["CannotCancelOrder"] = "CANNOT_CANCEL_ORDER";
    OrderErrorCode["CannotDelete"] = "CANNOT_DELETE";
    OrderErrorCode["CannotRefund"] = "CANNOT_REFUND";
    OrderErrorCode["CannotRejectOrder"] = "CANNOT_REJECT_ORDER";
    OrderErrorCode["CaptureInactivePayment"] = "CAPTURE_INACTIVE_PAYMENT";
    OrderErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    OrderErrorCode["FulfillOrderLine"] = "FULFILL_ORDER_LINE";
    OrderErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    OrderErrorCode["InsufficientStock"] = "INSUFFICIENT_STOCK";
    OrderErrorCode["Invalid"] = "INVALID";
    OrderErrorCode["NotEditable"] = "NOT_EDITABLE";
    OrderErrorCode["NotFound"] = "NOT_FOUND";
    OrderErrorCode["OrderNoShippingAddress"] = "ORDER_NO_SHIPPING_ADDRESS";
    OrderErrorCode["PaymentError"] = "PAYMENT_ERROR";
    OrderErrorCode["PaymentMissing"] = "PAYMENT_MISSING";
    OrderErrorCode["ProductNotPublished"] = "PRODUCT_NOT_PUBLISHED";
    OrderErrorCode["Required"] = "REQUIRED";
    OrderErrorCode["ShippingMethodNotApplicable"] = "SHIPPING_METHOD_NOT_APPLICABLE";
    OrderErrorCode["ShippingMethodRequired"] = "SHIPPING_METHOD_REQUIRED";
    OrderErrorCode["Unique"] = "UNIQUE";
    OrderErrorCode["VoidInactivePayment"] = "VOID_INACTIVE_PAYMENT";
    OrderErrorCode["ZeroQuantity"] = "ZERO_QUANTITY";
})(OrderErrorCode = exports.OrderErrorCode || (exports.OrderErrorCode = {}));
var OrderEventsEmailsEnum;
(function (OrderEventsEmailsEnum) {
    OrderEventsEmailsEnum["FulfillmentConfirmation"] = "FULFILLMENT_CONFIRMATION";
    OrderEventsEmailsEnum["OrderConfirmation"] = "ORDER_CONFIRMATION";
    OrderEventsEmailsEnum["PaymentConfirmation"] = "PAYMENT_CONFIRMATION";
    OrderEventsEmailsEnum["ShippingConfirmation"] = "SHIPPING_CONFIRMATION";
    OrderEventsEmailsEnum["TrackingUpdated"] = "TRACKING_UPDATED";
})(OrderEventsEmailsEnum = exports.OrderEventsEmailsEnum || (exports.OrderEventsEmailsEnum = {}));
var OrderEventsEnum;
(function (OrderEventsEnum) {
    OrderEventsEnum["Allocated"] = "ALLOCATED";
    OrderEventsEnum["Canceled"] = "CANCELED";
    OrderEventsEnum["CardPaid"] = "CARD_PAID";
    OrderEventsEnum["Consumed"] = "CONSUMED";
    OrderEventsEnum["ConsumerCanceled"] = "CONSUMER_CANCELED";
    OrderEventsEnum["ConsumerPicked"] = "CONSUMER_PICKED";
    OrderEventsEnum["Delivered"] = "DELIVERED";
    OrderEventsEnum["DeliveredTimedOut"] = "DELIVERED_TIMED_OUT";
    OrderEventsEnum["Dispensed"] = "DISPENSED";
    OrderEventsEnum["DraftAddedProducts"] = "DRAFT_ADDED_PRODUCTS";
    OrderEventsEnum["DraftCreated"] = "DRAFT_CREATED";
    OrderEventsEnum["DraftCustomerAccepted"] = "DRAFT_CUSTOMER_ACCEPTED";
    OrderEventsEnum["DraftRemovedProducts"] = "DRAFT_REMOVED_PRODUCTS";
    OrderEventsEnum["DriverAcceptedOrder"] = "DRIVER_ACCEPTED_ORDER";
    OrderEventsEnum["DriverDelivered"] = "DRIVER_DELIVERED";
    OrderEventsEnum["DriverIsAboutToArrive"] = "DRIVER_IS_ABOUT_TO_ARRIVE";
    OrderEventsEnum["DriverPicked"] = "DRIVER_PICKED";
    OrderEventsEnum["DriverReturned"] = "DRIVER_RETURNED";
    OrderEventsEnum["EmailSent"] = "EMAIL_SENT";
    OrderEventsEnum["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    OrderEventsEnum["ErxHubAuthSubmitTimedOut"] = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT";
    OrderEventsEnum["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    OrderEventsEnum["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    OrderEventsEnum["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    OrderEventsEnum["ErxHubClaimSubmitTimedOut"] = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT";
    OrderEventsEnum["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    OrderEventsEnum["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    OrderEventsEnum["ErxHubRejected"] = "ERX_HUB_REJECTED";
    OrderEventsEnum["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    OrderEventsEnum["Failed"] = "FAILED";
    OrderEventsEnum["FulfillmentCanceled"] = "FULFILLMENT_CANCELED";
    OrderEventsEnum["FulfillmentFulfilledItems"] = "FULFILLMENT_FULFILLED_ITEMS";
    OrderEventsEnum["FulfillmentRestockedItems"] = "FULFILLMENT_RESTOCKED_ITEMS";
    OrderEventsEnum["NoteAdded"] = "NOTE_ADDED";
    OrderEventsEnum["OrderCarrierAllocated"] = "ORDER_CARRIER_ALLOCATED";
    OrderEventsEnum["OrderCreated"] = "ORDER_CREATED";
    OrderEventsEnum["OrderDeliveryFailed"] = "ORDER_DELIVERY_FAILED";
    OrderEventsEnum["OrderDeliveryIsCancelled"] = "ORDER_DELIVERY_IS_CANCELLED";
    OrderEventsEnum["OrderDeliveryIsResumed"] = "ORDER_DELIVERY_IS_RESUMED";
    OrderEventsEnum["OrderDeliveryIsSuspended"] = "ORDER_DELIVERY_IS_SUSPENDED";
    OrderEventsEnum["OrderDeliveryTimedOut"] = "ORDER_DELIVERY_TIMED_OUT";
    OrderEventsEnum["OrderFullyPaid"] = "ORDER_FULLY_PAID";
    OrderEventsEnum["OrderMarkedAsPaid"] = "ORDER_MARKED_AS_PAID";
    OrderEventsEnum["OrderPickedByCustomer"] = "ORDER_PICKED_BY_CUSTOMER";
    OrderEventsEnum["Other"] = "OTHER";
    OrderEventsEnum["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderEventsEnum["OutForDeliveryTimedOut"] = "OUT_FOR_DELIVERY_TIMED_OUT";
    OrderEventsEnum["OversoldItems"] = "OVERSOLD_ITEMS";
    OrderEventsEnum["PaymentCaptured"] = "PAYMENT_CAPTURED";
    OrderEventsEnum["PaymentFailed"] = "PAYMENT_FAILED";
    OrderEventsEnum["PaymentOnArrive"] = "PAYMENT_ON_ARRIVE";
    OrderEventsEnum["PaymentPending"] = "PAYMENT_PENDING";
    OrderEventsEnum["PaymentRefunded"] = "PAYMENT_REFUNDED";
    OrderEventsEnum["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OrderEventsEnum["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    OrderEventsEnum["PaymentVoided"] = "PAYMENT_VOIDED";
    OrderEventsEnum["PendingDelivery"] = "PENDING_DELIVERY";
    OrderEventsEnum["Placed"] = "PLACED";
    OrderEventsEnum["PlacedFromDraft"] = "PLACED_FROM_DRAFT";
    OrderEventsEnum["PrescriptionOrderCreated"] = "PRESCRIPTION_ORDER_CREATED";
    OrderEventsEnum["PrescriptionOrderVendorAssigned"] = "PRESCRIPTION_ORDER_VENDOR_ASSIGNED";
    OrderEventsEnum["PrescriptionRefillOrderCreated"] = "PRESCRIPTION_REFILL_ORDER_CREATED";
    OrderEventsEnum["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    OrderEventsEnum["Returned"] = "RETURNED";
    OrderEventsEnum["ReturnedTimedOut"] = "RETURNED_TIMED_OUT";
    OrderEventsEnum["ReturnInProgress"] = "RETURN_IN_PROGRESS";
    OrderEventsEnum["TrackingUpdated"] = "TRACKING_UPDATED";
    OrderEventsEnum["UpdatedAddress"] = "UPDATED_ADDRESS";
    OrderEventsEnum["VendorAccepted"] = "VENDOR_ACCEPTED";
    OrderEventsEnum["VendorCanceled"] = "VENDOR_CANCELED";
    OrderEventsEnum["VendorDelivered"] = "VENDOR_DELIVERED";
    OrderEventsEnum["VendorFinishedProcessing"] = "VENDOR_FINISHED_PROCESSING";
    OrderEventsEnum["VendorRejected"] = "VENDOR_REJECTED";
    OrderEventsEnum["VendorResponseTimedOut"] = "VENDOR_RESPONSE_TIMED_OUT";
    OrderEventsEnum["VendorReturned"] = "VENDOR_RETURNED";
    OrderEventsEnum["WaitingErxHubAuthApproval"] = "WAITING_ERX_HUB_AUTH_APPROVAL";
    OrderEventsEnum["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
})(OrderEventsEnum = exports.OrderEventsEnum || (exports.OrderEventsEnum = {}));
var OrderLineAuthStatus;
(function (OrderLineAuthStatus) {
    OrderLineAuthStatus["Approved"] = "APPROVED";
    OrderLineAuthStatus["New"] = "NEW";
    OrderLineAuthStatus["PartialApproved"] = "PARTIAL_APPROVED";
    OrderLineAuthStatus["Rejected"] = "REJECTED";
})(OrderLineAuthStatus = exports.OrderLineAuthStatus || (exports.OrderLineAuthStatus = {}));
var OrderPaymentMethod;
(function (OrderPaymentMethod) {
    OrderPaymentMethod["CreditWhenArrive"] = "CREDIT_WHEN_ARRIVE";
    OrderPaymentMethod["PayNow"] = "PAY_NOW";
})(OrderPaymentMethod = exports.OrderPaymentMethod || (exports.OrderPaymentMethod = {}));
var OrderRejectionReasonErrorCode;
(function (OrderRejectionReasonErrorCode) {
    OrderRejectionReasonErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    OrderRejectionReasonErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    OrderRejectionReasonErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    OrderRejectionReasonErrorCode["Invalid"] = "INVALID";
    OrderRejectionReasonErrorCode["NotFound"] = "NOT_FOUND";
    OrderRejectionReasonErrorCode["Required"] = "REQUIRED";
    OrderRejectionReasonErrorCode["Unique"] = "UNIQUE";
})(OrderRejectionReasonErrorCode = exports.OrderRejectionReasonErrorCode || (exports.OrderRejectionReasonErrorCode = {}));
var OrderRejectionReasonType;
(function (OrderRejectionReasonType) {
    OrderRejectionReasonType["Custom"] = "CUSTOM";
    OrderRejectionReasonType["System"] = "SYSTEM";
})(OrderRejectionReasonType = exports.OrderRejectionReasonType || (exports.OrderRejectionReasonType = {}));
var OrderSortField;
(function (OrderSortField) {
    OrderSortField["CreationDate"] = "CREATION_DATE";
    OrderSortField["Customer"] = "CUSTOMER";
    OrderSortField["FulfillmentStatus"] = "FULFILLMENT_STATUS";
    OrderSortField["ModifiedDate"] = "MODIFIED_DATE";
    OrderSortField["Number"] = "NUMBER";
    OrderSortField["StatusAndCreated"] = "STATUS_AND_CREATED";
    OrderSortField["Total"] = "TOTAL";
})(OrderSortField = exports.OrderSortField || (exports.OrderSortField = {}));
var OrderSource;
(function (OrderSource) {
    OrderSource["PrescriptionDispense"] = "PRESCRIPTION_DISPENSE";
    OrderSource["PrescriptionRefill"] = "PRESCRIPTION_REFILL";
})(OrderSource = exports.OrderSource || (exports.OrderSource = {}));
var OrderSourcesEnum;
(function (OrderSourcesEnum) {
    OrderSourcesEnum["PrescriptionDispense"] = "PRESCRIPTION_DISPENSE";
    OrderSourcesEnum["PrescriptionRefill"] = "PRESCRIPTION_REFILL";
})(OrderSourcesEnum = exports.OrderSourcesEnum || (exports.OrderSourcesEnum = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["AcceptedByProvider"] = "ACCEPTED_BY_PROVIDER";
    OrderStatus["Allocated"] = "ALLOCATED";
    OrderStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    OrderStatus["CanceledByProvider"] = "CANCELED_BY_PROVIDER";
    OrderStatus["Consumed"] = "CONSUMED";
    OrderStatus["ConsumedTimedOut"] = "CONSUMED_TIMED_OUT";
    OrderStatus["Delivered"] = "DELIVERED";
    OrderStatus["DeliveredTimedOut"] = "DELIVERED_TIMED_OUT";
    OrderStatus["Dispensed"] = "DISPENSED";
    OrderStatus["Draft"] = "DRAFT";
    OrderStatus["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    OrderStatus["ErxHubAuthSubmitTimedOut"] = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT";
    OrderStatus["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    OrderStatus["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    OrderStatus["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    OrderStatus["ErxHubClaimSubmitTimedOut"] = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT";
    OrderStatus["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    OrderStatus["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    OrderStatus["ErxHubRejected"] = "ERX_HUB_REJECTED";
    OrderStatus["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    OrderStatus["Failed"] = "FAILED";
    OrderStatus["Fulfilled"] = "FULFILLED";
    OrderStatus["InProgress"] = "IN_PROGRESS";
    OrderStatus["NewRequest"] = "NEW_REQUEST";
    OrderStatus["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderStatus["OutForDeliveryTimedOut"] = "OUT_FOR_DELIVERY_TIMED_OUT";
    OrderStatus["PartiallyFulfilled"] = "PARTIALLY_FULFILLED";
    OrderStatus["PaymentFailed"] = "PAYMENT_FAILED";
    OrderStatus["PaymentOnArrive"] = "PAYMENT_ON_ARRIVE";
    OrderStatus["PaymentPending"] = "PAYMENT_PENDING";
    OrderStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OrderStatus["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    OrderStatus["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    OrderStatus["RejectedByProvider"] = "REJECTED_BY_PROVIDER";
    OrderStatus["Returned"] = "RETURNED";
    OrderStatus["ReturnedTimedOut"] = "RETURNED_TIMED_OUT";
    OrderStatus["ReturnInProgress"] = "RETURN_IN_PROGRESS";
    OrderStatus["Unfulfilled"] = "UNFULFILLED";
    OrderStatus["VendorResponseTimedOut"] = "VENDOR_RESPONSE_TIMED_OUT";
    OrderStatus["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    OrderStatus["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    OrderStatus["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var OrderStatusEnum;
(function (OrderStatusEnum) {
    OrderStatusEnum["AcceptedByProvider"] = "ACCEPTED_BY_PROVIDER";
    OrderStatusEnum["Allocated"] = "ALLOCATED";
    OrderStatusEnum["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    OrderStatusEnum["CanceledByProvider"] = "CANCELED_BY_PROVIDER";
    OrderStatusEnum["Consumed"] = "CONSUMED";
    OrderStatusEnum["ConsumedTimedOut"] = "CONSUMED_TIMED_OUT";
    OrderStatusEnum["Delivered"] = "DELIVERED";
    OrderStatusEnum["DeliveredTimedOut"] = "DELIVERED_TIMED_OUT";
    OrderStatusEnum["Dispensed"] = "DISPENSED";
    OrderStatusEnum["Draft"] = "DRAFT";
    OrderStatusEnum["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    OrderStatusEnum["ErxHubAuthSubmitTimedOut"] = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT";
    OrderStatusEnum["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    OrderStatusEnum["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    OrderStatusEnum["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    OrderStatusEnum["ErxHubClaimSubmitTimedOut"] = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT";
    OrderStatusEnum["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    OrderStatusEnum["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    OrderStatusEnum["ErxHubRejected"] = "ERX_HUB_REJECTED";
    OrderStatusEnum["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    OrderStatusEnum["Failed"] = "FAILED";
    OrderStatusEnum["Fulfilled"] = "FULFILLED";
    OrderStatusEnum["InProgress"] = "IN_PROGRESS";
    OrderStatusEnum["NewRequest"] = "NEW_REQUEST";
    OrderStatusEnum["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderStatusEnum["OutForDeliveryTimedOut"] = "OUT_FOR_DELIVERY_TIMED_OUT";
    OrderStatusEnum["PartiallyFulfilled"] = "PARTIALLY_FULFILLED";
    OrderStatusEnum["PaymentFailed"] = "PAYMENT_FAILED";
    OrderStatusEnum["PaymentOnArrive"] = "PAYMENT_ON_ARRIVE";
    OrderStatusEnum["PaymentPending"] = "PAYMENT_PENDING";
    OrderStatusEnum["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OrderStatusEnum["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    OrderStatusEnum["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    OrderStatusEnum["RejectedByProvider"] = "REJECTED_BY_PROVIDER";
    OrderStatusEnum["Returned"] = "RETURNED";
    OrderStatusEnum["ReturnedTimedOut"] = "RETURNED_TIMED_OUT";
    OrderStatusEnum["ReturnInProgress"] = "RETURN_IN_PROGRESS";
    OrderStatusEnum["Unfulfilled"] = "UNFULFILLED";
    OrderStatusEnum["VendorResponseTimedOut"] = "VENDOR_RESPONSE_TIMED_OUT";
    OrderStatusEnum["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    OrderStatusEnum["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    OrderStatusEnum["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(OrderStatusEnum = exports.OrderStatusEnum || (exports.OrderStatusEnum = {}));
var OrderTypesEnum;
(function (OrderTypesEnum) {
    OrderTypesEnum["HomeDelivery"] = "HOME_DELIVERY";
    OrderTypesEnum["PickupFromPharmacy"] = "PICKUP_FROM_PHARMACY";
})(OrderTypesEnum = exports.OrderTypesEnum || (exports.OrderTypesEnum = {}));
var OutPatientJourneyStatus;
(function (OutPatientJourneyStatus) {
    OutPatientJourneyStatus["AssessmentDone"] = "ASSESSMENT_DONE";
    OutPatientJourneyStatus["AssessmentPending"] = "ASSESSMENT_PENDING";
    OutPatientJourneyStatus["Canceled"] = "CANCELED";
    OutPatientJourneyStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    OutPatientJourneyStatus["CheckedIn"] = "CHECKED_IN";
    OutPatientJourneyStatus["Completed"] = "COMPLETED";
    OutPatientJourneyStatus["ConsultationDone"] = "CONSULTATION_DONE";
    OutPatientJourneyStatus["ConsultationPending"] = "CONSULTATION_PENDING";
    OutPatientJourneyStatus["Incompleted"] = "INCOMPLETED";
    OutPatientJourneyStatus["PaymentFailed"] = "PAYMENT_FAILED";
    OutPatientJourneyStatus["PaymentPending"] = "PAYMENT_PENDING";
    OutPatientJourneyStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OutPatientJourneyStatus["Pending"] = "PENDING";
    OutPatientJourneyStatus["TreatmentDone"] = "TREATMENT_DONE";
})(OutPatientJourneyStatus = exports.OutPatientJourneyStatus || (exports.OutPatientJourneyStatus = {}));
var PasswordResetMethodEnum;
(function (PasswordResetMethodEnum) {
    PasswordResetMethodEnum["Email"] = "EMAIL";
    PasswordResetMethodEnum["Mobile"] = "MOBILE";
})(PasswordResetMethodEnum = exports.PasswordResetMethodEnum || (exports.PasswordResetMethodEnum = {}));
var PatientErrorCode;
(function (PatientErrorCode) {
    PatientErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    PatientErrorCode["Invalid"] = "INVALID";
    PatientErrorCode["NotFound"] = "NOT_FOUND";
    PatientErrorCode["Required"] = "REQUIRED";
    PatientErrorCode["Unique"] = "UNIQUE";
})(PatientErrorCode = exports.PatientErrorCode || (exports.PatientErrorCode = {}));
var PatientHealthParameterSortField;
(function (PatientHealthParameterSortField) {
    /** Sort by health parameter code. */
    PatientHealthParameterSortField["Code"] = "CODE";
    /** Sort by creation date. */
    PatientHealthParameterSortField["CreatedDate"] = "CREATED_DATE";
    /** Sort by source. */
    PatientHealthParameterSortField["Source"] = "SOURCE";
})(PatientHealthParameterSortField = exports.PatientHealthParameterSortField || (exports.PatientHealthParameterSortField = {}));
var PatientIdType;
(function (PatientIdType) {
    PatientIdType["D001"] = "D001";
    PatientIdType["D002"] = "D002";
    PatientIdType["D003"] = "D003";
    PatientIdType["D004"] = "D004";
    PatientIdType["D005"] = "D005";
    PatientIdType["Other"] = "OTHER";
})(PatientIdType = exports.PatientIdType || (exports.PatientIdType = {}));
var PatientIdTypeEnum;
(function (PatientIdTypeEnum) {
    PatientIdTypeEnum["D001"] = "D001";
    PatientIdTypeEnum["D002"] = "D002";
    PatientIdTypeEnum["D003"] = "D003";
    PatientIdTypeEnum["D004"] = "D004";
    PatientIdTypeEnum["D005"] = "D005";
    PatientIdTypeEnum["Other"] = "OTHER";
})(PatientIdTypeEnum = exports.PatientIdTypeEnum || (exports.PatientIdTypeEnum = {}));
var PatientIdentifierType;
(function (PatientIdentifierType) {
    PatientIdentifierType["MemberId"] = "MEMBER_ID";
    PatientIdentifierType["NationalId"] = "NATIONAL_ID";
})(PatientIdentifierType = exports.PatientIdentifierType || (exports.PatientIdentifierType = {}));
var PatientMaritalStatus;
(function (PatientMaritalStatus) {
    PatientMaritalStatus["Divorced"] = "DIVORCED";
    PatientMaritalStatus["Married"] = "MARRIED";
    PatientMaritalStatus["Single"] = "SINGLE";
    PatientMaritalStatus["Widowed"] = "WIDOWED";
})(PatientMaritalStatus = exports.PatientMaritalStatus || (exports.PatientMaritalStatus = {}));
var PayerErrorCode;
(function (PayerErrorCode) {
    PayerErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    PayerErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    PayerErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    PayerErrorCode["Invalid"] = "INVALID";
    PayerErrorCode["NotFound"] = "NOT_FOUND";
    PayerErrorCode["NotPayersImage"] = "NOT_PAYERS_IMAGE";
    PayerErrorCode["Required"] = "REQUIRED";
    PayerErrorCode["Unique"] = "UNIQUE";
})(PayerErrorCode = exports.PayerErrorCode || (exports.PayerErrorCode = {}));
var PayerOrderField;
(function (PayerOrderField) {
    PayerOrderField["Created"] = "CREATED";
    PayerOrderField["Name"] = "NAME";
    PayerOrderField["NameAr"] = "NAME_AR";
    PayerOrderField["Tpo"] = "TPO";
})(PayerOrderField = exports.PayerOrderField || (exports.PayerOrderField = {}));
var PayerTpo;
(function (PayerTpo) {
    PayerTpo["Eclaim"] = "ECLAIM";
    PayerTpo["Io"] = "IO";
})(PayerTpo = exports.PayerTpo || (exports.PayerTpo = {}));
var PayerType;
(function (PayerType) {
    PayerType["Insurance"] = "INSURANCE";
    PayerType["Tpa"] = "TPA";
})(PayerType = exports.PayerType || (exports.PayerType = {}));
var PayerTypeEnum;
(function (PayerTypeEnum) {
    PayerTypeEnum["Insurance"] = "INSURANCE";
    PayerTypeEnum["Tpa"] = "TPA";
})(PayerTypeEnum = exports.PayerTypeEnum || (exports.PayerTypeEnum = {}));
var PaymentBrand;
(function (PaymentBrand) {
    PaymentBrand["Mada"] = "MADA";
    PaymentBrand["Master"] = "MASTER";
    PaymentBrand["Visa"] = "VISA";
})(PaymentBrand = exports.PaymentBrand || (exports.PaymentBrand = {}));
var PaymentErrorCodes;
(function (PaymentErrorCodes) {
    PaymentErrorCodes["InvalidOrExpiredToken"] = "INVALID_OR_EXPIRED_TOKEN";
    PaymentErrorCodes["PaymentAddCardError"] = "PAYMENT_ADD_CARD_ERROR";
    PaymentErrorCodes["PaymentCardNotFound"] = "PAYMENT_CARD_NOT_FOUND";
    PaymentErrorCodes["PaymentDeleteCardError"] = "PAYMENT_DELETE_CARD_ERROR";
    PaymentErrorCodes["PaymentInvalidSecurityCodeError"] = "PAYMENT_INVALID_SECURITY_CODE_ERROR";
    PaymentErrorCodes["PaymentOwnershipError"] = "PAYMENT_OWNERSHIP_ERROR";
    PaymentErrorCodes["PaymentPermissionDenied"] = "PAYMENT_PERMISSION_DENIED";
    PaymentErrorCodes["PaymentProcessError"] = "PAYMENT_PROCESS_ERROR";
    PaymentErrorCodes["PaymentUnknown"] = "PAYMENT_UNKNOWN";
    PaymentErrorCodes["PaymentUnknownType"] = "PAYMENT_UNKNOWN_TYPE";
})(PaymentErrorCodes = exports.PaymentErrorCodes || (exports.PaymentErrorCodes = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["Cash"] = "CASH";
    PaymentMethod["Cc"] = "CC";
    PaymentMethod["PaymentCard"] = "PAYMENT_CARD";
})(PaymentMethod = exports.PaymentMethod || (exports.PaymentMethod = {}));
var PaymentMethodEnum;
(function (PaymentMethodEnum) {
    PaymentMethodEnum["CreditWhenArrive"] = "CREDIT_WHEN_ARRIVE";
    PaymentMethodEnum["PayNow"] = "PAY_NOW";
})(PaymentMethodEnum = exports.PaymentMethodEnum || (exports.PaymentMethodEnum = {}));
var PaymentOperation;
(function (PaymentOperation) {
    PaymentOperation["Confirm"] = "CONFIRM";
    PaymentOperation["Refund"] = "REFUND";
    PaymentOperation["Reverse"] = "REVERSE";
})(PaymentOperation = exports.PaymentOperation || (exports.PaymentOperation = {}));
var PaymentOperationLogSortField;
(function (PaymentOperationLogSortField) {
    /** Sort visit by created. */
    PaymentOperationLogSortField["Created"] = "CREATED";
})(PaymentOperationLogSortField = exports.PaymentOperationLogSortField || (exports.PaymentOperationLogSortField = {}));
var PaymentSource;
(function (PaymentSource) {
    PaymentSource["Checkout"] = "CHECKOUT";
    PaymentSource["Wallet"] = "WALLET";
})(PaymentSource = exports.PaymentSource || (exports.PaymentSource = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["FullyCovered"] = "FULLY_COVERED";
    PaymentStatus["PaymentFailed"] = "PAYMENT_FAILED";
    PaymentStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    PaymentStatus["PaymentUnavailable"] = "PAYMENT_UNAVAILABLE";
    PaymentStatus["PaymentUnderProcessing"] = "PAYMENT_UNDER_PROCESSING";
    PaymentStatus["Refunded"] = "REFUNDED";
    PaymentStatus["RefundedFailed"] = "REFUNDED_FAILED";
    PaymentStatus["RefundedToWallet"] = "REFUNDED_TO_WALLET";
    PaymentStatus["RefundedUnderProcessing"] = "REFUNDED_UNDER_PROCESSING";
    PaymentStatus["UnPaid"] = "UN_PAID";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var PaymentType;
(function (PaymentType) {
    PaymentType["Cd"] = "CD";
    PaymentType["Cp"] = "CP";
    PaymentType["Db"] = "DB";
    PaymentType["Pa"] = "PA";
    PaymentType["Rf"] = "RF";
    PaymentType["Rv"] = "RV";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
var PermissionEnum;
(function (PermissionEnum) {
    PermissionEnum["ApproveHealthPackages"] = "APPROVE_HEALTH_PACKAGES";
    PermissionEnum["ApproveProducts"] = "APPROVE_PRODUCTS";
    PermissionEnum["AssignPrescriptionOrderToVendors"] = "ASSIGN_PRESCRIPTION_ORDER_TO_VENDORS";
    PermissionEnum["ConvertVirtualOrderToRealOne"] = "CONVERT_VIRTUAL_ORDER_TO_REAL_ONE";
    PermissionEnum["CustomerSupportManageOrders"] = "CUSTOMER_SUPPORT_MANAGE_ORDERS";
    PermissionEnum["ManageAppointments"] = "MANAGE_APPOINTMENTS";
    PermissionEnum["ManageArticles"] = "MANAGE_ARTICLES";
    PermissionEnum["ManageBlocks"] = "MANAGE_BLOCKS";
    PermissionEnum["ManageBranches"] = "MANAGE_BRANCHES";
    PermissionEnum["ManageBrands"] = "MANAGE_BRANDS";
    PermissionEnum["ManageCallbacksRequests"] = "MANAGE_CALLBACKS_REQUESTS";
    PermissionEnum["ManageChat"] = "MANAGE_CHAT";
    PermissionEnum["ManageChatFlow"] = "MANAGE_CHAT_FLOW";
    PermissionEnum["ManageChatFlowContributor"] = "MANAGE_CHAT_FLOW_CONTRIBUTOR";
    PermissionEnum["ManageCheckouts"] = "MANAGE_CHECKOUTS";
    PermissionEnum["ManageCities"] = "MANAGE_CITIES";
    PermissionEnum["ManageCodeSystemEditorReviewers"] = "MANAGE_CODE_SYSTEM_EDITOR_REVIEWERS";
    PermissionEnum["ManageCodeSystemLists"] = "MANAGE_CODE_SYSTEM_LISTS";
    PermissionEnum["ManageCodeSystemReviewers"] = "MANAGE_CODE_SYSTEM_REVIEWERS";
    PermissionEnum["ManageDeliveryTimeSlots"] = "MANAGE_DELIVERY_TIME_SLOTS";
    PermissionEnum["ManageDepartments"] = "MANAGE_DEPARTMENTS";
    PermissionEnum["ManageDiagnosis"] = "MANAGE_DIAGNOSIS";
    PermissionEnum["ManageDiscounts"] = "MANAGE_DISCOUNTS";
    PermissionEnum["ManageDoctors"] = "MANAGE_DOCTORS";
    PermissionEnum["ManageEarlyRefillReasons"] = "MANAGE_EARLY_REFILL_REASONS";
    PermissionEnum["ManageHealthChannels"] = "MANAGE_HEALTH_CHANNELS";
    PermissionEnum["ManageHealthChannelsCategories"] = "MANAGE_HEALTH_CHANNELS_CATEGORIES";
    PermissionEnum["ManageHealthConditions"] = "MANAGE_HEALTH_CONDITIONS";
    PermissionEnum["ManageHealthMessages"] = "MANAGE_HEALTH_MESSAGES";
    PermissionEnum["ManageHealthPackages"] = "MANAGE_HEALTH_PACKAGES";
    PermissionEnum["ManageHealthPackageCategories"] = "MANAGE_HEALTH_PACKAGE_CATEGORIES";
    PermissionEnum["ManageHealthPackageOrders"] = "MANAGE_HEALTH_PACKAGE_ORDERS";
    PermissionEnum["ManageHealthPrograms"] = "MANAGE_HEALTH_PROGRAMS";
    PermissionEnum["ManageHealthProgramsCareForFields"] = "MANAGE_HEALTH_PROGRAMS_CARE_FOR_FIELDS";
    PermissionEnum["ManageHealthSymptoms"] = "MANAGE_HEALTH_SYMPTOMS";
    PermissionEnum["ManageInsurance"] = "MANAGE_INSURANCE";
    PermissionEnum["ManageInsuranceNetworks"] = "MANAGE_INSURANCE_NETWORKS";
    PermissionEnum["ManageInvoices"] = "MANAGE_INVOICES";
    PermissionEnum["ManageLabs"] = "MANAGE_LABS";
    PermissionEnum["ManageLanguages"] = "MANAGE_LANGUAGES";
    PermissionEnum["ManageMarketplaceOrders"] = "MANAGE_MARKETPLACE_ORDERS";
    PermissionEnum["ManageMedicalDeliveryRequests"] = "MANAGE_MEDICAL_DELIVERY_REQUESTS";
    PermissionEnum["ManageMedicalForms"] = "MANAGE_MEDICAL_FORMS";
    PermissionEnum["ManageMedications"] = "MANAGE_MEDICATIONS";
    PermissionEnum["ManageMedicationScientificDetails"] = "MANAGE_MEDICATION_SCIENTIFIC_DETAILS";
    PermissionEnum["ManageOrders"] = "MANAGE_ORDERS";
    PermissionEnum["ManageParameters"] = "MANAGE_PARAMETERS";
    PermissionEnum["ManagePatients"] = "MANAGE_PATIENTS";
    PermissionEnum["ManagePatientMedicalHistory"] = "MANAGE_PATIENT_MEDICAL_HISTORY";
    PermissionEnum["ManagePayments"] = "MANAGE_PAYMENTS";
    PermissionEnum["ManagePermissionGroup"] = "MANAGE_PERMISSION_GROUP";
    PermissionEnum["ManagePharmacyCredentials"] = "MANAGE_PHARMACY_CREDENTIALS";
    PermissionEnum["ManagePlugins"] = "MANAGE_PLUGINS";
    PermissionEnum["ManagePrescriptions"] = "MANAGE_PRESCRIPTIONS";
    PermissionEnum["ManagePricingRules"] = "MANAGE_PRICING_RULES";
    PermissionEnum["ManageProducts"] = "MANAGE_PRODUCTS";
    PermissionEnum["ManageProductCategories"] = "MANAGE_PRODUCT_CATEGORIES";
    PermissionEnum["ManagePrograms"] = "MANAGE_PROGRAMS";
    PermissionEnum["ManageProgramTeams"] = "MANAGE_PROGRAM_TEAMS";
    PermissionEnum["ManageProgramTemplates"] = "MANAGE_PROGRAM_TEMPLATES";
    PermissionEnum["ManagePromotions"] = "MANAGE_PROMOTIONS";
    PermissionEnum["ManageQualifications"] = "MANAGE_QUALIFICATIONS";
    PermissionEnum["ManageRejectionReasons"] = "MANAGE_REJECTION_REASONS";
    PermissionEnum["ManageRiskStratification"] = "MANAGE_RISK_STRATIFICATION";
    PermissionEnum["ManageRuleEngineRules"] = "MANAGE_RULE_ENGINE_RULES";
    PermissionEnum["ManageSettings"] = "MANAGE_SETTINGS";
    PermissionEnum["ManageShipping"] = "MANAGE_SHIPPING";
    PermissionEnum["ManageSocialAndStreaming"] = "MANAGE_SOCIAL_AND_STREAMING";
    PermissionEnum["ManageSpecializations"] = "MANAGE_SPECIALIZATIONS";
    PermissionEnum["ManageStaff"] = "MANAGE_STAFF";
    PermissionEnum["ManageSubscriptions"] = "MANAGE_SUBSCRIPTIONS";
    PermissionEnum["ManageSurveys"] = "MANAGE_SURVEYS";
    PermissionEnum["ManageTranslations"] = "MANAGE_TRANSLATIONS";
    PermissionEnum["ManageUsers"] = "MANAGE_USERS";
    PermissionEnum["ManageVendors"] = "MANAGE_VENDORS";
    PermissionEnum["ManageVisits"] = "MANAGE_VISITS";
    PermissionEnum["ManageVisitCancelReasons"] = "MANAGE_VISIT_CANCEL_REASONS";
    PermissionEnum["ManageVisitRejectionReasons"] = "MANAGE_VISIT_REJECTION_REASONS";
    PermissionEnum["ManageVisitSummary"] = "MANAGE_VISIT_SUMMARY";
    PermissionEnum["ManageWallets"] = "MANAGE_WALLETS";
    PermissionEnum["MedicationScientificDetails"] = "MEDICATION_SCIENTIFIC_DETAILS";
    PermissionEnum["PublishCodeSystem"] = "PUBLISH_CODE_SYSTEM";
    PermissionEnum["UploadCodeSystemFile"] = "UPLOAD_CODE_SYSTEM_FILE";
    PermissionEnum["VerifyMemberIds"] = "VERIFY_MEMBER_IDS";
    PermissionEnum["VerifyNationalIds"] = "VERIFY_NATIONAL_IDS";
    PermissionEnum["ViewAppointments"] = "VIEW_APPOINTMENTS";
    PermissionEnum["ViewCodeSystemLists"] = "VIEW_CODE_SYSTEM_LISTS";
    PermissionEnum["ViewCustomers"] = "VIEW_CUSTOMERS";
    PermissionEnum["ViewHealthPrograms"] = "VIEW_HEALTH_PROGRAMS";
    PermissionEnum["ViewOrders"] = "VIEW_ORDERS";
    PermissionEnum["ViewPatients"] = "VIEW_PATIENTS";
    PermissionEnum["ViewPrograms"] = "VIEW_PROGRAMS";
    PermissionEnum["ViewProgramTeams"] = "VIEW_PROGRAM_TEAMS";
    PermissionEnum["ViewProgramTemplates"] = "VIEW_PROGRAM_TEMPLATES";
    PermissionEnum["ViewStaff"] = "VIEW_STAFF";
    PermissionEnum["ViewSubscriptions"] = "VIEW_SUBSCRIPTIONS";
    PermissionEnum["ViewSurveys"] = "VIEW_SURVEYS";
    PermissionEnum["ViewUsers"] = "VIEW_USERS";
    PermissionEnum["ViewVisits"] = "VIEW_VISITS";
    PermissionEnum["ViewWallets"] = "VIEW_WALLETS";
    PermissionEnum["WorkflowManageOrders"] = "WORKFLOW_MANAGE_ORDERS";
    PermissionEnum["ManageOutPatientJourneys"] = "manage_out_patient_journeys";
})(PermissionEnum = exports.PermissionEnum || (exports.PermissionEnum = {}));
var PermissionGroupErrorCode;
(function (PermissionGroupErrorCode) {
    PermissionGroupErrorCode["AssignNonStaffMember"] = "ASSIGN_NON_STAFF_MEMBER";
    PermissionGroupErrorCode["CannotRemoveFromLastGroup"] = "CANNOT_REMOVE_FROM_LAST_GROUP";
    PermissionGroupErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    PermissionGroupErrorCode["Invalid"] = "INVALID";
    PermissionGroupErrorCode["LeftNotManageablePermission"] = "LEFT_NOT_MANAGEABLE_PERMISSION";
    PermissionGroupErrorCode["NotEditable"] = "NOT_EDITABLE";
    PermissionGroupErrorCode["NotFound"] = "NOT_FOUND";
    PermissionGroupErrorCode["OutOfScopePermission"] = "OUT_OF_SCOPE_PERMISSION";
    PermissionGroupErrorCode["OutOfScopeUser"] = "OUT_OF_SCOPE_USER";
    PermissionGroupErrorCode["Required"] = "REQUIRED";
    PermissionGroupErrorCode["Unique"] = "UNIQUE";
})(PermissionGroupErrorCode = exports.PermissionGroupErrorCode || (exports.PermissionGroupErrorCode = {}));
var PermissionGroupSortField;
(function (PermissionGroupSortField) {
    PermissionGroupSortField["CreatedDate"] = "CREATED_DATE";
    PermissionGroupSortField["Name"] = "NAME";
})(PermissionGroupSortField = exports.PermissionGroupSortField || (exports.PermissionGroupSortField = {}));
var PersonGenderEnum;
(function (PersonGenderEnum) {
    PersonGenderEnum["Female"] = "FEMALE";
    PersonGenderEnum["Male"] = "MALE";
})(PersonGenderEnum = exports.PersonGenderEnum || (exports.PersonGenderEnum = {}));
var PhysicalActivity;
(function (PhysicalActivity) {
    PhysicalActivity["Active"] = "ACTIVE";
    PhysicalActivity["Inactive"] = "INACTIVE";
    PhysicalActivity["ModeratelyActive"] = "MODERATELY_ACTIVE";
})(PhysicalActivity = exports.PhysicalActivity || (exports.PhysicalActivity = {}));
var PlanOrderField;
(function (PlanOrderField) {
    PlanOrderField["Name"] = "NAME";
})(PlanOrderField = exports.PlanOrderField || (exports.PlanOrderField = {}));
var PlanPeriod;
(function (PlanPeriod) {
    PlanPeriod["HalfYear"] = "HALF_YEAR";
    PlanPeriod["Monthly"] = "MONTHLY";
    PlanPeriod["ThreeMonths"] = "THREE_MONTHS";
    PlanPeriod["Weekly"] = "WEEKLY";
    PlanPeriod["Yearly"] = "YEARLY";
})(PlanPeriod = exports.PlanPeriod || (exports.PlanPeriod = {}));
var PluginErrorCode;
(function (PluginErrorCode) {
    PluginErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    PluginErrorCode["Invalid"] = "INVALID";
    PluginErrorCode["NotFound"] = "NOT_FOUND";
    PluginErrorCode["PluginMisconfigured"] = "PLUGIN_MISCONFIGURED";
    PluginErrorCode["Required"] = "REQUIRED";
    PluginErrorCode["Unique"] = "UNIQUE";
})(PluginErrorCode = exports.PluginErrorCode || (exports.PluginErrorCode = {}));
var PluginSortField;
(function (PluginSortField) {
    PluginSortField["IsActive"] = "IS_ACTIVE";
    PluginSortField["Name"] = "NAME";
})(PluginSortField = exports.PluginSortField || (exports.PluginSortField = {}));
var PrescriptionDispenseMethodEnum;
(function (PrescriptionDispenseMethodEnum) {
    PrescriptionDispenseMethodEnum["Dispense"] = "DISPENSE";
    PrescriptionDispenseMethodEnum["Ingested"] = "INGESTED";
    PrescriptionDispenseMethodEnum["Refill"] = "REFILL";
})(PrescriptionDispenseMethodEnum = exports.PrescriptionDispenseMethodEnum || (exports.PrescriptionDispenseMethodEnum = {}));
var PrescriptionDispenseStatus;
(function (PrescriptionDispenseStatus) {
    PrescriptionDispenseStatus["Delivered"] = "DELIVERED";
    PrescriptionDispenseStatus["Dispensed"] = "DISPENSED";
    PrescriptionDispenseStatus["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    PrescriptionDispenseStatus["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    PrescriptionDispenseStatus["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    PrescriptionDispenseStatus["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    PrescriptionDispenseStatus["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    PrescriptionDispenseStatus["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    PrescriptionDispenseStatus["ErxHubRejected"] = "ERX_HUB_REJECTED";
    PrescriptionDispenseStatus["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    PrescriptionDispenseStatus["InProgress"] = "IN_PROGRESS";
    PrescriptionDispenseStatus["OutForDelivery"] = "OUT_FOR_DELIVERY";
    PrescriptionDispenseStatus["Pending"] = "PENDING";
    PrescriptionDispenseStatus["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    PrescriptionDispenseStatus["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    PrescriptionDispenseStatus["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    PrescriptionDispenseStatus["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(PrescriptionDispenseStatus = exports.PrescriptionDispenseStatus || (exports.PrescriptionDispenseStatus = {}));
var PrescriptionDispenseStatusEnum;
(function (PrescriptionDispenseStatusEnum) {
    PrescriptionDispenseStatusEnum["Delivered"] = "DELIVERED";
    PrescriptionDispenseStatusEnum["Dispensed"] = "DISPENSED";
    PrescriptionDispenseStatusEnum["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    PrescriptionDispenseStatusEnum["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    PrescriptionDispenseStatusEnum["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    PrescriptionDispenseStatusEnum["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    PrescriptionDispenseStatusEnum["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    PrescriptionDispenseStatusEnum["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    PrescriptionDispenseStatusEnum["ErxHubRejected"] = "ERX_HUB_REJECTED";
    PrescriptionDispenseStatusEnum["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    PrescriptionDispenseStatusEnum["InProgress"] = "IN_PROGRESS";
    PrescriptionDispenseStatusEnum["OutForDelivery"] = "OUT_FOR_DELIVERY";
    PrescriptionDispenseStatusEnum["Pending"] = "PENDING";
    PrescriptionDispenseStatusEnum["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    PrescriptionDispenseStatusEnum["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    PrescriptionDispenseStatusEnum["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    PrescriptionDispenseStatusEnum["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(PrescriptionDispenseStatusEnum = exports.PrescriptionDispenseStatusEnum || (exports.PrescriptionDispenseStatusEnum = {}));
var PrescriptionErrorCode;
(function (PrescriptionErrorCode) {
    PrescriptionErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    PrescriptionErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    PrescriptionErrorCode["Ingested"] = "INGESTED";
    PrescriptionErrorCode["Invalid"] = "INVALID";
    PrescriptionErrorCode["NotFound"] = "NOT_FOUND";
    PrescriptionErrorCode["Required"] = "REQUIRED";
    PrescriptionErrorCode["Unique"] = "UNIQUE";
})(PrescriptionErrorCode = exports.PrescriptionErrorCode || (exports.PrescriptionErrorCode = {}));
var PrescriptionPatientIdentifierType;
(function (PrescriptionPatientIdentifierType) {
    PrescriptionPatientIdentifierType["MemberId"] = "MEMBER_ID";
    PrescriptionPatientIdentifierType["NationalId"] = "NATIONAL_ID";
})(PrescriptionPatientIdentifierType = exports.PrescriptionPatientIdentifierType || (exports.PrescriptionPatientIdentifierType = {}));
var PrescriptionSortField;
(function (PrescriptionSortField) {
    PrescriptionSortField["Clinician"] = "CLINICIAN";
    PrescriptionSortField["Created"] = "CREATED";
    PrescriptionSortField["PatientIdentifier"] = "PATIENT_IDENTIFIER";
    PrescriptionSortField["ProviderLicense"] = "PROVIDER_LICENSE";
    PrescriptionSortField["ReferenceNumber"] = "REFERENCE_NUMBER";
})(PrescriptionSortField = exports.PrescriptionSortField || (exports.PrescriptionSortField = {}));
var PriceRangeEnum;
(function (PriceRangeEnum) {
    PriceRangeEnum["Average"] = "AVERAGE";
    PriceRangeEnum["Cheap"] = "CHEAP";
    PriceRangeEnum["Expensive"] = "EXPENSIVE";
    PriceRangeEnum["VeryCheap"] = "VERY_CHEAP";
    PriceRangeEnum["VeryExpensive"] = "VERY_EXPENSIVE";
})(PriceRangeEnum = exports.PriceRangeEnum || (exports.PriceRangeEnum = {}));
var Priority;
(function (Priority) {
    Priority["High"] = "HIGH";
    Priority["Low"] = "LOW";
    Priority["Medium"] = "MEDIUM";
})(Priority = exports.Priority || (exports.Priority = {}));
var ProcessingStatus;
(function (ProcessingStatus) {
    ProcessingStatus["Approved"] = "APPROVED";
    ProcessingStatus["Draft"] = "DRAFT";
    ProcessingStatus["Expired"] = "EXPIRED";
    ProcessingStatus["Published"] = "PUBLISHED";
    ProcessingStatus["Rejected"] = "REJECTED";
    ProcessingStatus["UnderReview"] = "UNDER_REVIEW";
})(ProcessingStatus = exports.ProcessingStatus || (exports.ProcessingStatus = {}));
var ProfileType;
(function (ProfileType) {
    ProfileType["Doctor"] = "DOCTOR";
    ProfileType["Patient"] = "PATIENT";
    ProfileType["Vendor"] = "VENDOR";
})(ProfileType = exports.ProfileType || (exports.ProfileType = {}));
var ProviderGuidedCareHealthProgramTeamSortingField;
(function (ProviderGuidedCareHealthProgramTeamSortingField) {
    ProviderGuidedCareHealthProgramTeamSortingField["Created"] = "CREATED";
    ProviderGuidedCareHealthProgramTeamSortingField["Modified"] = "MODIFIED";
    ProviderGuidedCareHealthProgramTeamSortingField["Name"] = "NAME";
    ProviderGuidedCareHealthProgramTeamSortingField["Status"] = "STATUS";
})(ProviderGuidedCareHealthProgramTeamSortingField = exports.ProviderGuidedCareHealthProgramTeamSortingField || (exports.ProviderGuidedCareHealthProgramTeamSortingField = {}));
var ProviderGuidedCareTeamStatus;
(function (ProviderGuidedCareTeamStatus) {
    ProviderGuidedCareTeamStatus["Approved"] = "APPROVED";
    ProviderGuidedCareTeamStatus["Pending"] = "PENDING";
    ProviderGuidedCareTeamStatus["Rejected"] = "REJECTED";
})(ProviderGuidedCareTeamStatus = exports.ProviderGuidedCareTeamStatus || (exports.ProviderGuidedCareTeamStatus = {}));
var QuestionType;
(function (QuestionType) {
    QuestionType["Boolean"] = "BOOLEAN";
    QuestionType["MultiChoice"] = "MULTI_CHOICE";
    QuestionType["SingleChoice"] = "SINGLE_CHOICE";
    QuestionType["String"] = "STRING";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
var RatingActionEnum;
(function (RatingActionEnum) {
    RatingActionEnum["Create"] = "CREATE";
    RatingActionEnum["Update"] = "UPDATE";
})(RatingActionEnum = exports.RatingActionEnum || (exports.RatingActionEnum = {}));
var ReferralErrorCodes;
(function (ReferralErrorCodes) {
    ReferralErrorCodes["ReferralDoctorNotFound"] = "REFERRAL_DOCTOR_NOT_FOUND";
    ReferralErrorCodes["ReferralEmptyDoctorLicense"] = "REFERRAL_EMPTY_DOCTOR_LICENSE";
    ReferralErrorCodes["ReferralErrorCallingIntegrator"] = "REFERRAL_ERROR_CALLING_INTEGRATOR";
    ReferralErrorCodes["ReferralInvalidId"] = "REFERRAL_INVALID_ID";
    ReferralErrorCodes["ReferralMakerNoneNegativePageSize"] = "REFERRAL_MAKER_NONE_NEGATIVE_PAGE_SIZE";
    ReferralErrorCodes["ReferralMakerUnknown"] = "REFERRAL_MAKER_UNKNOWN";
    ReferralErrorCodes["ReferralMakerUnknownType"] = "REFERRAL_MAKER_UNKNOWN_TYPE";
    ReferralErrorCodes["ReferralMemberIdIsNull"] = "REFERRAL_MEMBER_ID_IS_NULL";
    ReferralErrorCodes["ReferralMemberNotActive"] = "REFERRAL_MEMBER_NOT_ACTIVE";
    ReferralErrorCodes["ReferralNotFound"] = "REFERRAL_NOT_FOUND";
    ReferralErrorCodes["ReferralNoPrincipalDiagnosis"] = "REFERRAL_NO_PRINCIPAL_DIAGNOSIS";
    ReferralErrorCodes["ReferralOnlyOnePrincipalDiagnosisAllowed"] = "REFERRAL_ONLY_ONE_PRINCIPAL_DIAGNOSIS_ALLOWED";
    ReferralErrorCodes["ReferralOperationNotAllowed"] = "REFERRAL_OPERATION_NOT_ALLOWED";
    ReferralErrorCodes["ReferralPatientNotEligible"] = "REFERRAL_PATIENT_NOT_ELIGIBLE";
    ReferralErrorCodes["ReferralPatientNotFound"] = "REFERRAL_PATIENT_NOT_FOUND";
    ReferralErrorCodes["ReferralPayerLicenseNotDefined"] = "REFERRAL_PAYER_LICENSE_NOT_DEFINED";
    ReferralErrorCodes["ReferralPermissionDenied"] = "REFERRAL_PERMISSION_DENIED";
    ReferralErrorCodes["ReferralUserIdNotDefined"] = "REFERRAL_USER_ID_NOT_DEFINED";
})(ReferralErrorCodes = exports.ReferralErrorCodes || (exports.ReferralErrorCodes = {}));
var ReferralOrderDirection;
(function (ReferralOrderDirection) {
    ReferralOrderDirection["Asc"] = "ASC";
    ReferralOrderDirection["Desc"] = "DESC";
})(ReferralOrderDirection = exports.ReferralOrderDirection || (exports.ReferralOrderDirection = {}));
var RefillErrorCode;
(function (RefillErrorCode) {
    RefillErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    RefillErrorCode["Invalid"] = "INVALID";
    RefillErrorCode["NotFound"] = "NOT_FOUND";
    RefillErrorCode["Required"] = "REQUIRED";
    RefillErrorCode["Unique"] = "UNIQUE";
})(RefillErrorCode = exports.RefillErrorCode || (exports.RefillErrorCode = {}));
var RefundStatus;
(function (RefundStatus) {
    RefundStatus["Handled"] = "HANDLED";
    RefundStatus["Requested"] = "REQUESTED";
})(RefundStatus = exports.RefundStatus || (exports.RefundStatus = {}));
var RefundTarget;
(function (RefundTarget) {
    RefundTarget["PaymentGateway"] = "PAYMENT_GATEWAY";
    RefundTarget["Wallet"] = "WALLET";
})(RefundTarget = exports.RefundTarget || (exports.RefundTarget = {}));
var RelationTypes;
(function (RelationTypes) {
    RelationTypes["Brother"] = "BROTHER";
    RelationTypes["Daughter"] = "DAUGHTER";
    RelationTypes["Father"] = "FATHER";
    RelationTypes["Grandfather"] = "GRANDFATHER";
    RelationTypes["Grandmother"] = "GRANDMOTHER";
    RelationTypes["Husband"] = "HUSBAND";
    RelationTypes["Mother"] = "MOTHER";
    RelationTypes["Other"] = "OTHER";
    RelationTypes["Sister"] = "SISTER";
    RelationTypes["Son"] = "SON";
    RelationTypes["Wife"] = "WIFE";
})(RelationTypes = exports.RelationTypes || (exports.RelationTypes = {}));
var ReportingPeriod;
(function (ReportingPeriod) {
    ReportingPeriod["Last_7Days"] = "LAST_7_DAYS";
    ReportingPeriod["ThisMonth"] = "THIS_MONTH";
    ReportingPeriod["Today"] = "TODAY";
})(ReportingPeriod = exports.ReportingPeriod || (exports.ReportingPeriod = {}));
var RequestStatus;
(function (RequestStatus) {
    RequestStatus["Approved"] = "APPROVED";
    RequestStatus["Pending"] = "PENDING";
    RequestStatus["Rejected"] = "REJECTED";
})(RequestStatus = exports.RequestStatus || (exports.RequestStatus = {}));
var RequestedBy;
(function (RequestedBy) {
    RequestedBy["Patient"] = "PATIENT";
    RequestedBy["ProviderGuidedCareHealthProgramTeam"] = "PROVIDER_GUIDED_CARE_HEALTH_PROGRAM_TEAM";
})(RequestedBy = exports.RequestedBy || (exports.RequestedBy = {}));
var ResidencyTypesEnum;
(function (ResidencyTypesEnum) {
    ResidencyTypesEnum["Residence"] = "RESIDENCE";
    ResidencyTypesEnum["Visitor"] = "VISITOR";
})(ResidencyTypesEnum = exports.ResidencyTypesEnum || (exports.ResidencyTypesEnum = {}));
var ReviewQuestionValueType;
(function (ReviewQuestionValueType) {
    ReviewQuestionValueType["Boolean"] = "BOOLEAN";
    ReviewQuestionValueType["StarRating"] = "STAR_RATING";
})(ReviewQuestionValueType = exports.ReviewQuestionValueType || (exports.ReviewQuestionValueType = {}));
var RiskFactorTemplateSortingField;
(function (RiskFactorTemplateSortingField) {
    RiskFactorTemplateSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    RiskFactorTemplateSortingField["Code"] = "CODE";
    RiskFactorTemplateSortingField["CreatedDate"] = "CREATED_DATE";
    RiskFactorTemplateSortingField["Display"] = "DISPLAY";
    RiskFactorTemplateSortingField["IsActive"] = "IS_ACTIVE";
})(RiskFactorTemplateSortingField = exports.RiskFactorTemplateSortingField || (exports.RiskFactorTemplateSortingField = {}));
var RiskLevel;
(function (RiskLevel) {
    RiskLevel["High"] = "HIGH";
    RiskLevel["Low"] = "LOW";
})(RiskLevel = exports.RiskLevel || (exports.RiskLevel = {}));
var ServiceDescription;
(function (ServiceDescription) {
    ServiceDescription["LabTests"] = "LAB_TESTS";
    ServiceDescription["RadTests"] = "RAD_TESTS";
})(ServiceDescription = exports.ServiceDescription || (exports.ServiceDescription = {}));
var SiteErrorCode;
(function (SiteErrorCode) {
    SiteErrorCode["ForbiddenCharacter"] = "FORBIDDEN_CHARACTER";
    SiteErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
})(SiteErrorCode = exports.SiteErrorCode || (exports.SiteErrorCode = {}));
var SiteSettingsErrorCode;
(function (SiteSettingsErrorCode) {
    SiteSettingsErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    SiteSettingsErrorCode["CannotFetchTaxRates"] = "CANNOT_FETCH_TAX_RATES";
    SiteSettingsErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    SiteSettingsErrorCode["Invalid"] = "INVALID";
    SiteSettingsErrorCode["NotFound"] = "NOT_FOUND";
    SiteSettingsErrorCode["Required"] = "REQUIRED";
    SiteSettingsErrorCode["Unique"] = "UNIQUE";
})(SiteSettingsErrorCode = exports.SiteSettingsErrorCode || (exports.SiteSettingsErrorCode = {}));
var SmokingHabit;
(function (SmokingHabit) {
    SmokingHabit["FormerSmoker"] = "FORMER_SMOKER";
    SmokingHabit["HeavySmoker"] = "HEAVY_SMOKER";
    SmokingHabit["LightSmoker"] = "LIGHT_SMOKER";
    SmokingHabit["Never"] = "NEVER";
})(SmokingHabit = exports.SmokingHabit || (exports.SmokingHabit = {}));
var SortDirection;
(function (SortDirection) {
    SortDirection["Asc"] = "ASC";
    SortDirection["Desc"] = "DESC";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));
var Source;
(function (Source) {
    Source["Doctor"] = "DOCTOR";
    Source["Iot"] = "IOT";
    Source["Nurse"] = "NURSE";
    Source["Patient"] = "PATIENT";
})(Source = exports.Source || (exports.Source = {}));
var SpecializationErrorCode;
(function (SpecializationErrorCode) {
    SpecializationErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    SpecializationErrorCode["Invalid"] = "INVALID";
    SpecializationErrorCode["NotFound"] = "NOT_FOUND";
    SpecializationErrorCode["Required"] = "REQUIRED";
    SpecializationErrorCode["Unique"] = "UNIQUE";
})(SpecializationErrorCode = exports.SpecializationErrorCode || (exports.SpecializationErrorCode = {}));
var StressLevel;
(function (StressLevel) {
    StressLevel["High"] = "HIGH";
    StressLevel["Low"] = "LOW";
})(StressLevel = exports.StressLevel || (exports.StressLevel = {}));
var SubListItemSortField;
(function (SubListItemSortField) {
    SubListItemSortField["Code"] = "CODE";
    SubListItemSortField["Display"] = "DISPLAY";
})(SubListItemSortField = exports.SubListItemSortField || (exports.SubListItemSortField = {}));
var SubListSortField;
(function (SubListSortField) {
    SubListSortField["Display"] = "DISPLAY";
    SubListSortField["Field"] = "FIELD";
})(SubListSortField = exports.SubListSortField || (exports.SubListSortField = {}));
var SubscriptionErrorCode;
(function (SubscriptionErrorCode) {
    SubscriptionErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    SubscriptionErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    SubscriptionErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    SubscriptionErrorCode["Invalid"] = "INVALID";
    SubscriptionErrorCode["NotFound"] = "NOT_FOUND";
    SubscriptionErrorCode["Required"] = "REQUIRED";
    SubscriptionErrorCode["Unique"] = "UNIQUE";
})(SubscriptionErrorCode = exports.SubscriptionErrorCode || (exports.SubscriptionErrorCode = {}));
var SubscriptionErrorCodes;
(function (SubscriptionErrorCodes) {
    SubscriptionErrorCodes["SubscriptionInvalidId"] = "SUBSCRIPTION_INVALID_ID";
    SubscriptionErrorCodes["SubscriptionMakerUnknownType"] = "SUBSCRIPTION_MAKER_UNKNOWN_TYPE";
    SubscriptionErrorCodes["SubscriptionNoneNegativePageSize"] = "SUBSCRIPTION_NONE_NEGATIVE_PAGE_SIZE";
    SubscriptionErrorCodes["SubscriptionNotFound"] = "SUBSCRIPTION_NOT_FOUND";
    SubscriptionErrorCodes["SubscriptionPermissionDenied"] = "SUBSCRIPTION_PERMISSION_DENIED";
    SubscriptionErrorCodes["SubscriptionUnknown"] = "SUBSCRIPTION_UNKNOWN";
    SubscriptionErrorCodes["SubscriptionValidation"] = "SUBSCRIPTION_VALIDATION";
})(SubscriptionErrorCodes = exports.SubscriptionErrorCodes || (exports.SubscriptionErrorCodes = {}));
var SurveyErrorCodes;
(function (SurveyErrorCodes) {
    SurveyErrorCodes["SurveyDuplicate"] = "SURVEY_DUPLICATE";
    SurveyErrorCodes["SurveyInvalidDateFormat"] = "SURVEY_INVALID_DATE_FORMAT";
    SurveyErrorCodes["SurveyMakerUnknownType"] = "SURVEY_MAKER_UNKNOWN_TYPE";
    SurveyErrorCodes["SurveyNoneNegativePageSize"] = "SURVEY_NONE_NEGATIVE_PAGE_SIZE";
    SurveyErrorCodes["SurveyNotFound"] = "SURVEY_NOT_FOUND";
    SurveyErrorCodes["SurveyPermissionDenied"] = "SURVEY_PERMISSION_DENIED";
    SurveyErrorCodes["SurveyUnknown"] = "SURVEY_UNKNOWN";
    SurveyErrorCodes["SurveyValidation"] = "SURVEY_VALIDATION";
})(SurveyErrorCodes = exports.SurveyErrorCodes || (exports.SurveyErrorCodes = {}));
var SurveyGender;
(function (SurveyGender) {
    SurveyGender["Female"] = "Female";
    SurveyGender["Male"] = "Male";
})(SurveyGender = exports.SurveyGender || (exports.SurveyGender = {}));
var SurveySortingField;
(function (SurveySortingField) {
    SurveySortingField["CreationDate"] = "CREATION_DATE";
    SurveySortingField["Status"] = "STATUS";
    SurveySortingField["UpdateDate"] = "UPDATE_DATE";
})(SurveySortingField = exports.SurveySortingField || (exports.SurveySortingField = {}));
var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["Cancelled"] = "CANCELLED";
    SurveyStatus["Draft"] = "DRAFT";
    SurveyStatus["Expired"] = "EXPIRED";
    SurveyStatus["Published"] = "PUBLISHED";
    SurveyStatus["Unpublished"] = "UNPUBLISHED";
})(SurveyStatus = exports.SurveyStatus || (exports.SurveyStatus = {}));
var Tpo;
(function (Tpo) {
    Tpo["Eclaim"] = "ECLAIM";
    Tpo["Io"] = "IO";
})(Tpo = exports.Tpo || (exports.Tpo = {}));
var TaskDefinitionAction;
(function (TaskDefinitionAction) {
    TaskDefinitionAction["SendMedicalMessage"] = "SEND_MEDICAL_MESSAGE";
    TaskDefinitionAction["SendNotification"] = "SEND_NOTIFICATION";
})(TaskDefinitionAction = exports.TaskDefinitionAction || (exports.TaskDefinitionAction = {}));
var TaskDefinitionPriority;
(function (TaskDefinitionPriority) {
    TaskDefinitionPriority["Low"] = "LOW";
    TaskDefinitionPriority["Moderate"] = "MODERATE";
    TaskDefinitionPriority["Urgent"] = "URGENT";
})(TaskDefinitionPriority = exports.TaskDefinitionPriority || (exports.TaskDefinitionPriority = {}));
var TaskDefinitionSortingField;
(function (TaskDefinitionSortingField) {
    TaskDefinitionSortingField["Created"] = "CREATED";
    TaskDefinitionSortingField["Priority"] = "PRIORITY";
})(TaskDefinitionSortingField = exports.TaskDefinitionSortingField || (exports.TaskDefinitionSortingField = {}));
var TaskDefinitionStatus;
(function (TaskDefinitionStatus) {
    TaskDefinitionStatus["Cancelled"] = "CANCELLED";
    TaskDefinitionStatus["Done"] = "DONE";
    TaskDefinitionStatus["Idle"] = "IDLE";
    TaskDefinitionStatus["InProgress"] = "IN_PROGRESS";
    TaskDefinitionStatus["Missed"] = "MISSED";
    TaskDefinitionStatus["Pending"] = "PENDING";
})(TaskDefinitionStatus = exports.TaskDefinitionStatus || (exports.TaskDefinitionStatus = {}));
var TaskWorkflowType;
(function (TaskWorkflowType) {
    TaskWorkflowType["ConfirmAppointmentDate"] = "CONFIRM_APPOINTMENT_DATE";
    TaskWorkflowType["ReviewMedicalFormAnswer"] = "REVIEW_MEDICAL_FORM_ANSWER";
    TaskWorkflowType["SubmitMedicalFormAnswers"] = "SUBMIT_MEDICAL_FORM_ANSWERS";
})(TaskWorkflowType = exports.TaskWorkflowType || (exports.TaskWorkflowType = {}));
var TeamMemberPosition;
(function (TeamMemberPosition) {
    TeamMemberPosition["DentalHygienist"] = "DENTAL_HYGIENIST";
    TeamMemberPosition["DiabetesEducator"] = "DIABETES_EDUCATOR";
    TeamMemberPosition["FitnessCoach"] = "FITNESS_COACH";
    TeamMemberPosition["Nurse"] = "NURSE";
    TeamMemberPosition["Nutritionist"] = "NUTRITIONIST";
    TeamMemberPosition["Optometrist"] = "OPTOMETRIST";
    TeamMemberPosition["Physician"] = "PHYSICIAN";
    TeamMemberPosition["PodiatricMedicalAssistant"] = "PODIATRIC_MEDICAL_ASSISTANT";
    TeamMemberPosition["Psychologist"] = "PSYCHOLOGIST";
    TeamMemberPosition["SocialWorker"] = "SOCIAL_WORKER";
    TeamMemberPosition["TeamAdmin"] = "TEAM_ADMIN";
    TeamMemberPosition["TeamLeader"] = "TEAM_LEADER";
})(TeamMemberPosition = exports.TeamMemberPosition || (exports.TeamMemberPosition = {}));
var TemplateActivityEventItemSortingField;
(function (TemplateActivityEventItemSortingField) {
    TemplateActivityEventItemSortingField["Offset"] = "OFFSET";
})(TemplateActivityEventItemSortingField = exports.TemplateActivityEventItemSortingField || (exports.TemplateActivityEventItemSortingField = {}));
var TemplateFieldType;
(function (TemplateFieldType) {
    TemplateFieldType["AudioFile"] = "AUDIO_FILE";
    TemplateFieldType["Boolean"] = "BOOLEAN";
    TemplateFieldType["Date"] = "DATE";
    TemplateFieldType["List"] = "LIST";
    TemplateFieldType["Number"] = "NUMBER";
    TemplateFieldType["NumericList"] = "NUMERIC_LIST";
    TemplateFieldType["String"] = "STRING";
    TemplateFieldType["VideoFile"] = "VIDEO_FILE";
})(TemplateFieldType = exports.TemplateFieldType || (exports.TemplateFieldType = {}));
var TerminologyErrorCodes;
(function (TerminologyErrorCodes) {
    TerminologyErrorCodes["InvalidOrExpiredToken"] = "INVALID_OR_EXPIRED_TOKEN";
    TerminologyErrorCodes["TerminologyClinicianNotFound"] = "TERMINOLOGY_CLINICIAN_NOT_FOUND";
    TerminologyErrorCodes["TerminologyConceptDefinitionListNotFound"] = "TERMINOLOGY_CONCEPT_DEFINITION_LIST_NOT_FOUND";
    TerminologyErrorCodes["TerminologyDuplicateColumn"] = "TERMINOLOGY_DUPLICATE_COLUMN";
    TerminologyErrorCodes["TerminologyDuplicateProperty"] = "TERMINOLOGY_DUPLICATE_PROPERTY";
    TerminologyErrorCodes["TerminologyDuplicateRowData"] = "TERMINOLOGY_DUPLICATE_ROW_DATA";
    TerminologyErrorCodes["TerminologyDuplicateVersion"] = "TERMINOLOGY_DUPLICATE_VERSION";
    TerminologyErrorCodes["TerminologyEmptyFile"] = "TERMINOLOGY_EMPTY_FILE";
    TerminologyErrorCodes["TerminologyFileServiceNotReachable"] = "TERMINOLOGY_FILE_SERVICE_NOT_REACHABLE";
    TerminologyErrorCodes["TerminologyInvalidDateFormat"] = "TERMINOLOGY_INVALID_DATE_FORMAT";
    TerminologyErrorCodes["TerminologyInvalidFileExtension"] = "TERMINOLOGY_INVALID_FILE_EXTENSION";
    TerminologyErrorCodes["TerminologyInvalidProcessingStatus"] = "TERMINOLOGY_INVALID_PROCESSING_STATUS";
    TerminologyErrorCodes["TerminologyInvalidToken"] = "TERMINOLOGY_INVALID_TOKEN";
    TerminologyErrorCodes["TerminologyListDefinitionNotFound"] = "TERMINOLOGY_LIST_Definition_NOT_FOUND";
    TerminologyErrorCodes["TerminologyListItemNotFound"] = "TERMINOLOGY_LIST_Item_NOT_FOUND";
    TerminologyErrorCodes["TerminologyListNotFound"] = "TERMINOLOGY_LIST_NOT_FOUND";
    TerminologyErrorCodes["TerminologyMandatoryConceptPropertyException"] = "TERMINOLOGY_MANDATORY_CONCEPT_PROPERTY_EXCEPTION";
    TerminologyErrorCodes["TerminologyNoneDraftCodeSystem"] = "TERMINOLOGY_NONE_DRAFT_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyNoneEditableConceptDefinition"] = "TERMINOLOGY_NONE_EDITABLE_CONCEPT_DEFINITION";
    TerminologyErrorCodes["TerminologyNoneEditableList"] = "TERMINOLOGY_NONE_EDITABLE_LIST";
    TerminologyErrorCodes["TerminologyNoneNegativePageSize"] = "TERMINOLOGY_NONE_NEGATIVE_PAGE_SIZE";
    TerminologyErrorCodes["TerminologyNoneUnderReviewCodeSystem"] = "TERMINOLOGY_NONE_UNDER_REVIEW_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyNotApprovedCodeSystem"] = "TERMINOLOGY_NOT_APPROVED_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyNotFound"] = "TERMINOLOGY_NOT_FOUND";
    TerminologyErrorCodes["TerminologyNoActiveCodeSystem"] = "TERMINOLOGY_NO_ACTIVE_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyOldVersion"] = "TERMINOLOGY_OLD_VERSION";
    TerminologyErrorCodes["TerminologyPermissionDenied"] = "TERMINOLOGY_PERMISSION_DENIED";
    TerminologyErrorCodes["TerminologyUnknown"] = "TERMINOLOGY_UNKNOWN";
    TerminologyErrorCodes["TerminologyUnknownType"] = "TERMINOLOGY_UNKNOWN_TYPE";
    TerminologyErrorCodes["TerminologyValidation"] = "TERMINOLOGY_VALIDATION";
})(TerminologyErrorCodes = exports.TerminologyErrorCodes || (exports.TerminologyErrorCodes = {}));
var TextSummaryErrorCode;
(function (TextSummaryErrorCode) {
    TextSummaryErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    TextSummaryErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    TextSummaryErrorCode["Ingested"] = "INGESTED";
    TextSummaryErrorCode["Invalid"] = "INVALID";
    TextSummaryErrorCode["NotFound"] = "NOT_FOUND";
    TextSummaryErrorCode["Required"] = "REQUIRED";
    TextSummaryErrorCode["Unique"] = "UNIQUE";
})(TextSummaryErrorCode = exports.TextSummaryErrorCode || (exports.TextSummaryErrorCode = {}));
var TranscribeErrorCode;
(function (TranscribeErrorCode) {
    TranscribeErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    TranscribeErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    TranscribeErrorCode["Ingested"] = "INGESTED";
    TranscribeErrorCode["Invalid"] = "INVALID";
    TranscribeErrorCode["NotFound"] = "NOT_FOUND";
    TranscribeErrorCode["Required"] = "REQUIRED";
    TranscribeErrorCode["Unique"] = "UNIQUE";
})(TranscribeErrorCode = exports.TranscribeErrorCode || (exports.TranscribeErrorCode = {}));
var Type;
(function (Type) {
    Type["Boolean"] = "BOOLEAN";
    Type["Date"] = "DATE";
    Type["Float"] = "FLOAT";
    Type["Integer"] = "INTEGER";
    Type["String"] = "STRING";
})(Type = exports.Type || (exports.Type = {}));
var UnverifiedNationalsStatus;
(function (UnverifiedNationalsStatus) {
    UnverifiedNationalsStatus["Approved"] = "APPROVED";
    UnverifiedNationalsStatus["Pending"] = "PENDING";
    UnverifiedNationalsStatus["Rejected"] = "REJECTED";
})(UnverifiedNationalsStatus = exports.UnverifiedNationalsStatus || (exports.UnverifiedNationalsStatus = {}));
var UnverifiedNationalsStatusesEnum;
(function (UnverifiedNationalsStatusesEnum) {
    UnverifiedNationalsStatusesEnum["Approved"] = "APPROVED";
    UnverifiedNationalsStatusesEnum["Pending"] = "PENDING";
    UnverifiedNationalsStatusesEnum["Rejected"] = "REJECTED";
})(UnverifiedNationalsStatusesEnum = exports.UnverifiedNationalsStatusesEnum || (exports.UnverifiedNationalsStatusesEnum = {}));
var UserGender;
(function (UserGender) {
    UserGender["Female"] = "FEMALE";
    UserGender["Male"] = "MALE";
})(UserGender = exports.UserGender || (exports.UserGender = {}));
var UserNotificationField;
(function (UserNotificationField) {
    UserNotificationField["CreationDate"] = "CREATION_DATE";
    UserNotificationField["Seen"] = "SEEN";
    UserNotificationField["Type"] = "TYPE";
    UserNotificationField["UpdateDate"] = "UPDATE_DATE";
})(UserNotificationField = exports.UserNotificationField || (exports.UserNotificationField = {}));
var UserSortField;
(function (UserSortField) {
    UserSortField["DateJoined"] = "DATE_JOINED";
    UserSortField["Email"] = "EMAIL";
    UserSortField["FirstName"] = "FIRST_NAME";
    UserSortField["LastMessageSent"] = "LAST_MESSAGE_SENT";
    UserSortField["LastName"] = "LAST_NAME";
    UserSortField["OrderCount"] = "ORDER_COUNT";
})(UserSortField = exports.UserSortField || (exports.UserSortField = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "ACTIVE";
    UserStatus["Deactivated"] = "DEACTIVATED";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var UserVendorUserType;
(function (UserVendorUserType) {
    UserVendorUserType["DentalHygienist"] = "DENTAL_HYGIENIST";
    UserVendorUserType["DiabetesEducator"] = "DIABETES_EDUCATOR";
    UserVendorUserType["Doctor"] = "DOCTOR";
    UserVendorUserType["FitnessCoach"] = "FITNESS_COACH";
    UserVendorUserType["Manager"] = "MANAGER";
    UserVendorUserType["Nurse"] = "NURSE";
    UserVendorUserType["Nutritionist"] = "NUTRITIONIST";
    UserVendorUserType["Optometrist"] = "OPTOMETRIST";
    UserVendorUserType["Pharmacist"] = "PHARMACIST";
    UserVendorUserType["PodiatricMedicalAssistant"] = "PODIATRIC_MEDICAL_ASSISTANT";
    UserVendorUserType["Psychologist"] = "PSYCHOLOGIST";
    UserVendorUserType["Receptionist"] = "RECEPTIONIST";
    UserVendorUserType["SocialWorker"] = "SOCIAL_WORKER";
})(UserVendorUserType = exports.UserVendorUserType || (exports.UserVendorUserType = {}));
var VendorBranchTypeEnum;
(function (VendorBranchTypeEnum) {
    VendorBranchTypeEnum["Clinic"] = "CLINIC";
    VendorBranchTypeEnum["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    VendorBranchTypeEnum["Hospital"] = "HOSPITAL";
    VendorBranchTypeEnum["Pharmacy"] = "PHARMACY";
})(VendorBranchTypeEnum = exports.VendorBranchTypeEnum || (exports.VendorBranchTypeEnum = {}));
var VendorDepartmentOrderField;
(function (VendorDepartmentOrderField) {
    VendorDepartmentOrderField["Branch"] = "BRANCH";
    VendorDepartmentOrderField["Code"] = "CODE";
    VendorDepartmentOrderField["Name"] = "NAME";
})(VendorDepartmentOrderField = exports.VendorDepartmentOrderField || (exports.VendorDepartmentOrderField = {}));
var VendorErrorCode;
(function (VendorErrorCode) {
    VendorErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    VendorErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    VendorErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    VendorErrorCode["Invalid"] = "INVALID";
    VendorErrorCode["NotFound"] = "NOT_FOUND";
    VendorErrorCode["NotVendorsImage"] = "NOT_VENDORS_IMAGE";
    VendorErrorCode["Required"] = "REQUIRED";
    VendorErrorCode["Unique"] = "UNIQUE";
})(VendorErrorCode = exports.VendorErrorCode || (exports.VendorErrorCode = {}));
var VendorOrderField;
(function (VendorOrderField) {
    VendorOrderField["Created"] = "CREATED";
    VendorOrderField["Name"] = "NAME";
    VendorOrderField["Nearest"] = "NEAREST";
    VendorOrderField["OrderCount"] = "ORDER_COUNT";
    VendorOrderField["Rating"] = "RATING";
})(VendorOrderField = exports.VendorOrderField || (exports.VendorOrderField = {}));
var VendorType;
(function (VendorType) {
    VendorType["Clinic"] = "CLINIC";
    VendorType["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    VendorType["Hospital"] = "HOSPITAL";
    VendorType["Pharmacy"] = "PHARMACY";
})(VendorType = exports.VendorType || (exports.VendorType = {}));
var VendorTypeEnum;
(function (VendorTypeEnum) {
    VendorTypeEnum["Clinic"] = "CLINIC";
    VendorTypeEnum["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    VendorTypeEnum["Hospital"] = "HOSPITAL";
    VendorTypeEnum["Pharmacy"] = "PHARMACY";
})(VendorTypeEnum = exports.VendorTypeEnum || (exports.VendorTypeEnum = {}));
var VendorUserTypes;
(function (VendorUserTypes) {
    VendorUserTypes["DentalHygienist"] = "DENTAL_HYGIENIST";
    VendorUserTypes["DiabetesEducator"] = "DIABETES_EDUCATOR";
    VendorUserTypes["Doctor"] = "DOCTOR";
    VendorUserTypes["FitnessCoach"] = "FITNESS_COACH";
    VendorUserTypes["Manager"] = "MANAGER";
    VendorUserTypes["Nurse"] = "NURSE";
    VendorUserTypes["Nutritionist"] = "NUTRITIONIST";
    VendorUserTypes["Optometrist"] = "OPTOMETRIST";
    VendorUserTypes["Pharmacist"] = "PHARMACIST";
    VendorUserTypes["PodiatricMedicalAssistant"] = "PODIATRIC_MEDICAL_ASSISTANT";
    VendorUserTypes["Psychologist"] = "PSYCHOLOGIST";
    VendorUserTypes["Receptionist"] = "RECEPTIONIST";
    VendorUserTypes["SocialWorker"] = "SOCIAL_WORKER";
})(VendorUserTypes = exports.VendorUserTypes || (exports.VendorUserTypes = {}));
var VisitAttachmentType;
(function (VisitAttachmentType) {
    VisitAttachmentType["File"] = "FILE";
    VisitAttachmentType["MedicalReport"] = "MEDICAL_REPORT";
    VisitAttachmentType["TestResult"] = "TEST_RESULT";
})(VisitAttachmentType = exports.VisitAttachmentType || (exports.VisitAttachmentType = {}));
var VisitCareType;
(function (VisitCareType) {
    /** Home Visit */
    VisitCareType["HomeVisit"] = "HOME_VISIT";
    /** In Person */
    VisitCareType["InPerson"] = "IN_PERSON";
    /** Virtual */
    VisitCareType["Virtual"] = "VIRTUAL";
})(VisitCareType = exports.VisitCareType || (exports.VisitCareType = {}));
var VisitDiagnosisType;
(function (VisitDiagnosisType) {
    VisitDiagnosisType["Principal"] = "PRINCIPAL";
    VisitDiagnosisType["Secondary"] = "SECONDARY";
})(VisitDiagnosisType = exports.VisitDiagnosisType || (exports.VisitDiagnosisType = {}));
var VisitPrescriptionActivityFrequencyType;
(function (VisitPrescriptionActivityFrequencyType) {
    VisitPrescriptionActivityFrequencyType["Day"] = "Day";
    VisitPrescriptionActivityFrequencyType["Hour"] = "Hour";
    VisitPrescriptionActivityFrequencyType["Month"] = "Month";
    VisitPrescriptionActivityFrequencyType["Week"] = "Week";
})(VisitPrescriptionActivityFrequencyType = exports.VisitPrescriptionActivityFrequencyType || (exports.VisitPrescriptionActivityFrequencyType = {}));
var VisitPrescriptionActivityType;
(function (VisitPrescriptionActivityType) {
    VisitPrescriptionActivityType["GenericDrug"] = "GenericDrug";
    VisitPrescriptionActivityType["TradeDrug"] = "TradeDrug";
})(VisitPrescriptionActivityType = exports.VisitPrescriptionActivityType || (exports.VisitPrescriptionActivityType = {}));
var VisitReferralSortField;
(function (VisitReferralSortField) {
    VisitReferralSortField["CreatedDate"] = "CREATED_DATE";
})(VisitReferralSortField = exports.VisitReferralSortField || (exports.VisitReferralSortField = {}));
var VisitSortField;
(function (VisitSortField) {
    /** Sort visit by created. */
    VisitSortField["Created"] = "CREATED";
    /** Sort visit by date. */
    VisitSortField["Date"] = "DATE";
    /** Sort visit by number. */
    VisitSortField["Number"] = "NUMBER";
})(VisitSortField = exports.VisitSortField || (exports.VisitSortField = {}));
var VisitStatus;
(function (VisitStatus) {
    VisitStatus["Accepted"] = "ACCEPTED";
    VisitStatus["Assigned"] = "ASSIGNED";
    VisitStatus["CallInProgress"] = "CALL_IN_PROGRESS";
    VisitStatus["CallRejoinable"] = "CALL_REJOINABLE";
    VisitStatus["CallTimedOut"] = "CALL_TIMED_OUT";
    VisitStatus["Canceled"] = "CANCELED";
    VisitStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    VisitStatus["ChatInProgress"] = "CHAT_IN_PROGRESS";
    VisitStatus["Completed"] = "COMPLETED";
    VisitStatus["DoctorAnswerTimedOut"] = "DOCTOR_ANSWER_TIMED_OUT";
    VisitStatus["EndedByOneOfTheParticipants"] = "ENDED_BY_ONE_OF_THE_PARTICIPANTS";
    VisitStatus["Incompleted"] = "INCOMPLETED";
    VisitStatus["OutPatientJourneyAssessmentDone"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_DONE";
    VisitStatus["OutPatientJourneyAssessmentPending"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING";
    VisitStatus["OutPatientJourneyCheckedIn"] = "OUT_PATIENT_JOURNEY_CHECKED_IN";
    VisitStatus["OutPatientJourneyConsultationDone"] = "OUT_PATIENT_JOURNEY_CONSULTATION_DONE";
    VisitStatus["OutPatientJourneyConsultationPending"] = "OUT_PATIENT_JOURNEY_CONSULTATION_PENDING";
    VisitStatus["OutPatientJourneyPending"] = "OUT_PATIENT_JOURNEY_PENDING";
    VisitStatus["OutPatientJourneyTreatmentDone"] = "OUT_PATIENT_JOURNEY_TREATMENT_DONE";
    VisitStatus["PaymentFailed"] = "PAYMENT_FAILED";
    VisitStatus["PaymentPending"] = "PAYMENT_PENDING";
    VisitStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    VisitStatus["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    VisitStatus["Pending"] = "PENDING";
    VisitStatus["Rejected"] = "REJECTED";
})(VisitStatus = exports.VisitStatus || (exports.VisitStatus = {}));
var VisitTranscriptionProcessingStatus;
(function (VisitTranscriptionProcessingStatus) {
    VisitTranscriptionProcessingStatus["Failed"] = "FAILED";
    VisitTranscriptionProcessingStatus["Pending"] = "PENDING";
    VisitTranscriptionProcessingStatus["Processed"] = "PROCESSED";
    VisitTranscriptionProcessingStatus["UnderProcess"] = "UNDER_PROCESS";
})(VisitTranscriptionProcessingStatus = exports.VisitTranscriptionProcessingStatus || (exports.VisitTranscriptionProcessingStatus = {}));
var VisitType;
(function (VisitType) {
    VisitType["DirectAppointment"] = "DIRECT_APPOINTMENT";
    VisitType["DirectAtHome"] = "DIRECT_AT_HOME";
    VisitType["DirectCall"] = "DIRECT_CALL";
    VisitType["DirectInPerson"] = "DIRECT_IN_PERSON";
    VisitType["HealthProgram"] = "HEALTH_PROGRAM";
})(VisitType = exports.VisitType || (exports.VisitType = {}));
var Period;
(function (Period) {
    Period["HalfYear"] = "HALF_YEAR";
    Period["Monthly"] = "MONTHLY";
    Period["ThreeMonths"] = "THREE_MONTHS";
    Period["Weekly"] = "WEEKLY";
    Period["Yearly"] = "YEARLY";
})(Period = exports.Period || (exports.Period = {}));
