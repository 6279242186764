import { getAutocompleteFilter } from "@toolkit/ui";
import { useVendorsAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const getVendorsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "vendors",
        query: useVendorsAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, (item === null || item === void 0 ? void 0 : item.nameAr) || (item === null || item === void 0 ? void 0 : item.name)),
        backendAccessor: "id",
    });
};
