import { CitiesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { LocationPicker } from "@toolkit/maps";
import { createAutocompleteOption, FormCard, FormPhoneInput, Grid, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "../CustomTextField.components";
import { useGetBackendErrorsForInputsValues } from "../setBackendsErrors";
import { LocationProps } from "../types";
import { locationFields } from "./locationUtils";

const LocationCard: FC<LocationProps> = props => {
  const { isLoading, errorsInput, onLocationChange: handleLocation, location, defaultValues, doYouHaveVendor } = props;

  const { t } = useTranslation("admin");

  const form = useFormContext();
  const { register, setValue } = form;

  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errorsInput, [
    "addressInput.area",
    "addressInput.streetAddress1",
    "arabicStreetAddress",
    "addressInput.streetAddress2",
    "arabicStreetAddress2",
    "addressInput.phone",
  ]);

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    register("addressInput.city", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);
  const phoneInput = "addressInput.phone";
  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const cityDefaultValue = defaultValues?.address?.city
      ? createAutocompleteOption(defaultValues?.address?.city, "id", item => pickLocalizedValue(item?.name, item?.nameAr || item?.name))
      : undefined;

    setValue("addressInput.city", cityDefaultValue);
    setValue(phoneInput, defaultValues?.address?.phone ?? "");
  }, [defaultValues, setValue]);

  return (
    <FormCard title={t("Address And Location")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
      <Grid container spacing={formGirdSpacing}>
        {locationFields(t, defaultValues?.address, backendErrorStorage)?.map(item => (
          <Grid item {...formGirdBreakPoints} key={item.name}>
            <CustomTextField
              name={item.name}
              label={item.label}
              defaultValue={item?.defaultValue}
              errorsInput={errorsInput}
              registerProps={item?.registerProps}
            />
          </Grid>
        ))}
        <Grid item {...formGirdBreakPoints}>
          <FormPhoneInput
            {...register(phoneInput, {
              required: getRequiredValidation(t, false),
            })}
            label={t("Phone Number")}
            error={Boolean(errorsInput?.[phoneInput]?.message)}
            helperText={t(errorsInput?.[phoneInput]?.message)}
            fullWidth
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <CitiesAutocomplete name={"addressInput.city"} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='subtitle1' gutterBottom>
            {t("Please Click on Map to detect your Coordinates")}
          </Typography>

          <LocationPicker location={location} onLocationChange={location => handleLocation(location?.coordinates ?? location)} />
        </Grid>
      </Grid>
    </FormCard>
  );
};
export default LocationCard;
