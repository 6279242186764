import { City, CityOrderField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";

export type CityColumnTypes = CustomTableColumnProps<City>[];

export const formatDate = (updateDate?: null | string, i18n?: any) =>
  updateDate ? moment(updateDate).locale(i18n.language).format("DD MMM YYYY") : "";

export const useCitiesManagementColumns = (): CityColumnTypes => {
  const { t, i18n } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ nameAr, name }) => (i18n.language === "ar" ? nameAr || name : name),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: CityOrderField.Name,
      },
      {
        key: "area",
        header: t("Area"),
        accessor: "area",
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => formatDate(row?.created, i18n),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: CityOrderField.Created,
      },
      {
        key: "name_Icontains",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
    ];
  }, []);
};
