import { MarketplaceCategoryInput, MarketplaceHealthPackageCategory } from "@health/queries/types";
import { ICategoryUpsertFormValues } from "../forms/CategoryFormUpsert/CategoryUpsertFormSchema";
import { MarketplaceHealthPackageCategoryQueryData } from "../types";
import { createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const convertCategoryFormValuesToBackEndValues = (values: ICategoryUpsertFormValues): MarketplaceCategoryInput => {
  return {
    name: values.name,
    nameAr: values.nameAr,
    description: values.description,
    descriptionAr: values.descriptionAr,
    parentId: values?.parentCategory?.value?.id!,
    image: values.image,
  };
};

export const convertCategoryToFormValues = (category: MarketplaceHealthPackageCategoryQueryData): ICategoryUpsertFormValues => {
  return {
    name: category?.name!,
    nameAr: category?.nameAr,
    description: category?.description,
    descriptionAr: category?.descriptionAr,
    parentCategory: category?.parent ? createCategoryAutocompleteObject(category.parent!) : undefined,
    image: category?.image,
  };
};

export const createCategoryAutocompleteObject = (category: MarketplaceHealthPackageCategory) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr || item?.name)
  );
};
