import { makeStyles } from "tss-react/mui";

export const useTableBodyLayoutStyles = makeStyles()(theme => ({
  tableRow: {
    height: 60,
    position: "relative",
  },
  tableRowHover: {
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      boxShadow: theme.mixins.shadows.md,
      backgroundColor: theme.palette.stale[50] + " !important",
      zIndex: 1,
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  row: {
    backgroundColor: theme.palette.background.default,
  },
  cell: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.stale[400],
    textAlign: "start",
    padding: "2px 16px",
  },
  actionsCell: {
    position: "sticky",
    [theme.direction === "rtl" ? "left" : "right"]: 0,
    whiteSpace: "nowrap",
    backgroundColor: "inherit",
    width: 80,
    padding: 8,
    zIndex: 1,
  },
}));
