import React from "react";
import { CustomMuiDialog, CustomMuiDialogProps } from "./CustomMuiDialog";
import { DeleteConfirmationDialog, DeleteConfirmationDialogProps } from "./DeleteDialog";
import { CustomInfoDialog, CustomInfoDialogProps } from "./InfoDialog";
import { WarningConfirmationDialog, WarningConfirmationDialogProps } from "./WarningDialog";

export type CustomDialogProps =
  | ({
      type: "warning";
    } & WarningConfirmationDialogProps)
  | ({
      type: "delete";
    } & DeleteConfirmationDialogProps)
  | ({
      type: "info";
    } & CustomInfoDialogProps)
  | ({
      type: "base";
    } & CustomMuiDialogProps);

export const CustomDialog: React.FC<CustomDialogProps> = props => {
  const { type, ...rest } = props;

  if (type === "warning") {
    return <WarningConfirmationDialog {...(rest as WarningConfirmationDialogProps)} />;
  } else if (type === "delete") {
    return <DeleteConfirmationDialog {...(rest as DeleteConfirmationDialogProps)} />;
  } else if (type === "info") {
    return <CustomInfoDialog {...(rest as CustomInfoDialogProps)} />;
  } else if (type === "base") {
    return <CustomMuiDialog {...(rest as CustomMuiDialogProps)} />;
  }

  return null;
};
