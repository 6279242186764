import { CitiesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { LocationPicker } from "@toolkit/maps";
import { createAutocompleteOption, Grid, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { CustomTextField } from "pages/ProvidersManagement/components/Form/components/CustomTextField.components";
import { locationFields } from "pages/ProvidersManagement/components/Form/components/Location/locationUtils";
import { useFormContext } from "react-hook-form";
import { LocationProps } from "../BranchesForm.types";
import { useGetBackendErrorsForInputsValues } from "./setBackendsErrors";

const Address: FC<LocationProps> = props => {
  const { errorsInput, onLocationChange: handleLocation, location, defaultValues } = props;

  const { t } = useTranslation("admin");

  const form = useFormContext();
  const { register, setValue } = form;

  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errorsInput, [
    "addressInput.area",
    "addressInput.streetAddress1",
    "arabicStreetAddress",
    "addressInput.streetAddress2",
    "arabicStreetAddress2",
    "addressInput.phone",
  ]);

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("addressInput.city", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const cityDefaultValue = defaultValues?.address?.city
      ? createAutocompleteOption(defaultValues?.address?.city, "id", item => pickLocalizedValue(item?.name, item?.nameAr || item?.name))
      : undefined;

    setValue("addressInput.city", cityDefaultValue);
  }, [defaultValues?.address?.city, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      {locationFields(t, defaultValues?.address, backendErrorStorage)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item?.defaultValue}
            errorsInput={errorsInput}
            registerProps={item?.registerProps}
          />
        </Grid>
      ))}

      <Grid item {...formGirdBreakPoints}>
        <CitiesAutocomplete name='addressInput.city' />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1' gutterBottom>
          {t("Please Click on Map to detect your Coordinates")}
        </Typography>

        <LocationPicker
          hasSearch
          hasLocationLngLat
          location={location}
          onLocationChange={mapLocation => handleLocation(mapLocation?.coordinates)}
        />
      </Grid>
    </Grid>
  );
};

export default Address;
