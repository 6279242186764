import { useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { getTranslatedNameField } from "@toolkit/ui";
import { useOrdersLifeCycleQuery } from "../gql/queries";
export const useLifeCycleHooks = (prescriptionID, number) => {
    var _a, _b, _c;
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState([]);
    const { i18n } = useTranslation("domains");
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickClose = () => {
        setOpen(false);
    };
    const handleChange = (e, v) => {
        setValue(v);
    };
    const { data, loading, fetchMore, refetch } = useOrdersLifeCycleQuery({
        variables: {
            first: 5,
            filter: {
                numbers: [String(number)],
                prescription: prescriptionID,
            },
        },
        skip: !open,
    });
    const ordersData = (_b = (_a = data === null || data === void 0 ? void 0 : data.orders) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(order => {
        var _a, _b, _c, _d;
        return (Object.assign(Object.assign({}, order === null || order === void 0 ? void 0 : order.node), { events: [
                ...(((_b = (_a = order === null || order === void 0 ? void 0 : order.node) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b.map(event => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, event), { branchName: getTranslatedNameField((_a = order === null || order === void 0 ? void 0 : order.node) === null || _a === void 0 ? void 0 : _a.branch, i18n.language), number: (_b = order === null || order === void 0 ? void 0 : order.node) === null || _b === void 0 ? void 0 : _b.number, isParentEvent: true }));
                })) || []),
                ...(((_d = (_c = order === null || order === void 0 ? void 0 : order.node) === null || _c === void 0 ? void 0 : _c.children) === null || _d === void 0 ? void 0 : _d.reduce((acc, child) => {
                    var _a;
                    // flatten/merge an events array
                    (_a = child === null || child === void 0 ? void 0 : child.events) === null || _a === void 0 ? void 0 : _a.forEach(event => event &&
                        acc.push(Object.assign(Object.assign({}, event), { number: child === null || child === void 0 ? void 0 : child.number, branchName: getTranslatedNameField(child === null || child === void 0 ? void 0 : child.branch, i18n.language) || "", isParentEvent: false })));
                    return acc;
                }, [])) || []),
            ] }));
    });
    const pageInfo = (_c = data === null || data === void 0 ? void 0 : data.orders) === null || _c === void 0 ? void 0 : _c.pageInfo;
    const fetchMoreData = () => {
        if (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) {
            fetchMore({
                variables: {
                    first: 5,
                    after: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    var _a, _b;
                    if (!fetchMoreResult)
                        return prev;
                    return Object.assign({}, prev, Object.assign(Object.assign({}, fetchMoreResult), { orders: Object.assign(Object.assign({}, fetchMoreResult.orders), { edges: [...(((_a = prev === null || prev === void 0 ? void 0 : prev.orders) === null || _a === void 0 ? void 0 : _a.edges) || []), ...(((_b = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.orders) === null || _b === void 0 ? void 0 : _b.edges) || [])] }) }));
                },
            });
        }
    };
    const handleSearch = () => {
        refetch({
            filter: {
                prescription: prescriptionID,
                numbers: (value === null || value === void 0 ? void 0 : value.length) ? [String(value)] : [String(number)],
            },
        });
    };
    return {
        open,
        value,
        loading,
        ordersData,
        pageInfo,
        handleChange,
        handleSearch,
        fetchMoreData,
        handleClickOpen,
        handleClickClose,
    };
};
