import { makeStyles } from "@toolkit/ui";

export const useGoogleMapSearchWrapperStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  mapContainerStyle: {
    height: "60vh",
    width: "100%",
  },
  peekToCurrentLocationButton: {
    borderRadius: "5px !important",
    "&:hover": {
      borderRadius: "5px !important",
    },
  },
  textField: {
    marginLeft: "1px",
    marginRight: "1px",
    width: 200,
  },
  container: {
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: 10,
    paddingLeft: 10,
    width: 250,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  saveButton: {
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    width: "100%",
    marginRight: "2px",
  },
  cancelButton: {
    color: theme.palette.primary[1200],
    background: theme.palette.gray[300],
    width: "100%",
    marginRight: "2px",
  },
  deleteButton: {
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    width: "100%",
    marginRight: "2px",
  },
  text: {
    width: "100%",
  },
  search: {
    flex: 1,
    height: "100%",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
  },
}));

export const useGoogleMapSearchStyles = makeStyles()({
  loadingElement: { height: `0` },
  containerElement: { height: `60px` },
  mapElement: { height: `0` },
});
