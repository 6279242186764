import { changeLanguage, useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { Box, IconButton, Menu, MenuItem, Typography, styled, useTheme } from "../../base/mui";
import { LanguageGlobeIcon } from "../../icons";

const StyledMenuItem = styled(MenuItem)(() => ({
  width: 200,
  borderRadius: 0,
}));

export const LanguageButton: FC = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChangeLanguage = (lang: string) => {
    changeLanguage(lang, () => {
      if (typeof window !== "undefined") {
        location.reload();
      }
    });
  };
  const handleChangeLanguage = (): void => {
    if (i18n?.language.startsWith("en")) {
      onChangeLanguage("ar");
    } else {
      onChangeLanguage("en");
    }
    handleClose();
  };
  const theme = useTheme();
  return (
    <>
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            boxShadow: theme.mixins.shadows.md,
            borderRadius: "10px",
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box
          sx={{
            padding: "8px 16px",
          }}
        >
          <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("Change language")}</Typography>
        </Box>
        <StyledMenuItem onClick={handleChangeLanguage}>
          <Typography>{i18n.language === "en" || i18n.language === "en-US" ? t("Arabic") : t("English")}</Typography>
          <Typography sx={{ paddingInline: "4px" }}>{i18n.language === "en" || i18n.language === "en-US" ? "ع" : "E"}</Typography>
        </StyledMenuItem>
      </Menu>
      <IconButton color='primary' onClick={handleOpen}>
        <LanguageGlobeIcon />
      </IconButton>
    </>
  );
};
