import { conditionOperationOptionsMap, userGenderOptionsMap, operatorOptionsMap } from "@health/enum-options";
import { toSentenceCase } from "@toolkit/core";
import { createAutocompleteOption, mapStringsToAutocompleteOptions } from "@toolkit/ui";
import { EntityType } from "@health/queries/types";
import { DecisionConditionFieldCategoryCode, } from "../../Decisions/types/types";
import { pickLocalizedValue } from "@toolkit/i18n";
export const convertDecisionPlanConditionGroupToFormValues = (decisionPlanFields) => {
    return [
        {
            outerConditionOperation: undefined,
            innerConditionOperation: undefined,
            conditions: convertDecisionPlanConditionToFormValues(decisionPlanFields),
        },
    ];
};
export const convertDecisionPlanConditionToFormValues = (decisionPlanFields) => {
    var _a, _b;
    const firstField = decisionPlanFields === null || decisionPlanFields === void 0 ? void 0 : decisionPlanFields[0];
    return [
        {
            id: firstField === null || firstField === void 0 ? void 0 : firstField.id,
            category: createAutocompleteOption({
                code: (_a = firstField === null || firstField === void 0 ? void 0 : firstField.category) === null || _a === void 0 ? void 0 : _a.code,
                display: (_b = firstField === null || firstField === void 0 ? void 0 : firstField.category) === null || _b === void 0 ? void 0 : _b.display,
            }, "code", "display"),
            field: undefined,
            operator: undefined,
            allowedValues: firstField === null || firstField === void 0 ? void 0 : firstField.allowedValues,
        },
    ];
};
export const convertDecisionConditionGroupsToFormValues = (conditionsGroups, decisionPlanFields) => {
    var _a;
    return (((_a = conditionsGroups === null || conditionsGroups === void 0 ? void 0 : conditionsGroups.filter(item => { var _a; return !((_a = item === null || item === void 0 ? void 0 : item.conditions) === null || _a === void 0 ? void 0 : _a.some(condition => { var _a, _b; return ((_b = (_a = condition === null || condition === void 0 ? void 0 : condition.field) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.code) === DecisionConditionFieldCategoryCode.HealthParameter; })); })) === null || _a === void 0 ? void 0 : _a.map(item => {
        var _a, _b;
        return {
            outerConditionOperation: conditionOperationOptionsMap[item === null || item === void 0 ? void 0 : item.conditionOperation] || null,
            innerConditionOperation: conditionOperationOptionsMap[(_b = (_a = item === null || item === void 0 ? void 0 : item.conditions) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.conditionOperation] || null,
            conditions: convertDecisionConditionsToFormValues(item === null || item === void 0 ? void 0 : item.conditions, decisionPlanFields),
        };
    })) || []);
};
export const resetDecisionConditionValues = (actionValues, except) => {
    for (const key in actionValues) {
        if (key !== except) {
            actionValues[key] = undefined;
        }
    }
};
export const convertDecisionConditionsToFormValues = (conditions, decisionPlanFields) => {
    return (
    // eslint-disable-next-line max-statements
    (conditions === null || conditions === void 0 ? void 0 : conditions.map(item => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        const decisionField = decisionPlanFields[(_a = item === null || item === void 0 ? void 0 : item.field) === null || _a === void 0 ? void 0 : _a.code];
        const extra = ((_b = item === null || item === void 0 ? void 0 : item.value) === null || _b === void 0 ? void 0 : _b.extra) ? JSON.parse((_c = item === null || item === void 0 ? void 0 : item.value) === null || _c === void 0 ? void 0 : _c.extra) : undefined;
        const actionValues = {
            valueNumber: undefined,
            valueString: undefined,
            valueList: undefined,
            valueDate: undefined,
            valueID: undefined,
        };
        if ((item === null || item === void 0 ? void 0 : item.entityType) === EntityType.Number) {
            actionValues.valueNumber = (_d = item === null || item === void 0 ? void 0 : item.value) === null || _d === void 0 ? void 0 : _d.valueNumber;
            resetDecisionConditionValues(actionValues, "valueNumber");
        }
        else if ((item === null || item === void 0 ? void 0 : item.entityType) === EntityType.String) {
            if (((_e = item === null || item === void 0 ? void 0 : item.field) === null || _e === void 0 ? void 0 : _e.code) === "gender") {
                actionValues.valueString = userGenderOptionsMap[(_f = item === null || item === void 0 ? void 0 : item.value) === null || _f === void 0 ? void 0 : _f.valueString];
            }
            else if ((_g = decisionField === null || decisionField === void 0 ? void 0 : decisionField.allowedValues) === null || _g === void 0 ? void 0 : _g.length) {
                const allowedValuesOptions = mapStringsToAutocompleteOptions(decisionField === null || decisionField === void 0 ? void 0 : decisionField.allowedValues, toSentenceCase);
                actionValues.valueString = allowedValuesOptions.find(option => { var _a; return (option === null || option === void 0 ? void 0 : option.value) === ((_a = item === null || item === void 0 ? void 0 : item.value) === null || _a === void 0 ? void 0 : _a.valueString); });
            }
            else {
                actionValues.valueString = (_h = item === null || item === void 0 ? void 0 : item.value) === null || _h === void 0 ? void 0 : _h.valueString;
            }
            resetDecisionConditionValues(actionValues, "valueString");
        }
        else if ((item === null || item === void 0 ? void 0 : item.entityType) === EntityType.List && extra) {
            actionValues.valueList = createAutocompleteOption({
                id: extra === null || extra === void 0 ? void 0 : extra.id,
                display: extra === null || extra === void 0 ? void 0 : extra.display,
            }, "id", "display");
            resetDecisionConditionValues(actionValues, "valueList");
        }
        else if ((item === null || item === void 0 ? void 0 : item.entityType) === EntityType.Id && extra) {
            actionValues.valueID = createAutocompleteOption({
                id: extra === null || extra === void 0 ? void 0 : extra.id,
                name: extra === null || extra === void 0 ? void 0 : extra.name,
                nameAr: extra === null || extra === void 0 ? void 0 : extra.nameAr,
            }, "id", _item => pickLocalizedValue(_item === null || _item === void 0 ? void 0 : _item.name, (_item === null || _item === void 0 ? void 0 : _item.nameAr) || (_item === null || _item === void 0 ? void 0 : _item.name)));
            resetDecisionConditionValues(actionValues, "valueID");
        }
        else if ((item === null || item === void 0 ? void 0 : item.entityType) === EntityType.Date) {
            actionValues.valueDate = (_j = item === null || item === void 0 ? void 0 : item.value) === null || _j === void 0 ? void 0 : _j.valueDate;
            resetDecisionConditionValues(actionValues, "valueDate");
        }
        return Object.assign(Object.assign({}, actionValues), { id: item === null || item === void 0 ? void 0 : item.id, conditionOperation: conditionOperationOptionsMap[item === null || item === void 0 ? void 0 : item.conditionOperation] || null, category: createAutocompleteOption({ code: (_l = (_k = item === null || item === void 0 ? void 0 : item.field) === null || _k === void 0 ? void 0 : _k.category) === null || _l === void 0 ? void 0 : _l.code, display: (_o = (_m = item === null || item === void 0 ? void 0 : item.field) === null || _m === void 0 ? void 0 : _m.category) === null || _o === void 0 ? void 0 : _o.display }, "code", "display"), operator: operatorOptionsMap[item === null || item === void 0 ? void 0 : item.operator], field: createAutocompleteOption({
                id: (_p = item === null || item === void 0 ? void 0 : item.field) === null || _p === void 0 ? void 0 : _p.id,
                code: (_q = item === null || item === void 0 ? void 0 : item.field) === null || _q === void 0 ? void 0 : _q.code,
                display: (_r = item === null || item === void 0 ? void 0 : item.field) === null || _r === void 0 ? void 0 : _r.display,
                type: item === null || item === void 0 ? void 0 : item.entityType,
            }, "code", "display"), allowedValues: mapStringsToAutocompleteOptions(decisionField === null || decisionField === void 0 ? void 0 : decisionField.allowedValues, toSentenceCase) });
    })) || []);
};
