import { animated, interpolate, useTransition } from "@react-spring/web";
import { i18n } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import { PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { Text } from "@visx/text";
import { AnimatedPieProps, AnimatedStyles } from "./StatisticsCard.types";
import { enterUpdateTransition, fromLeaveTransition, getTextAnchor, getVerticalAnchor, PIE_CHART_WIDTH, truncateText } from "./utils";

export function AnimatedPie<Datum>({
  data,
  animate,
  arcs,
  svgRef,
  containerWidth,
  path,
  getKey,
  getColor,
  onClickDatum,
  onMouseEnterText,
  onMouseLeaveText,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  const currentDir = i18n.dir(i18n.language);
  const theme = useTheme();
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.3;
    // After arcs are rendered, measure and possibly truncate text

    const textContent = getKey(arc);
    const fontSize = theme.mixins.fonts.fontSize.sm;
    // split width into 2 spaces after removing pie chart width
    const maxTextWidth = (containerWidth - PIE_CHART_WIDTH) / 2;
    const truncatedText = truncateText(svgRef.current!, textContent, maxTextWidth);

    const handleMouseEnterText: React.MouseEventHandler<SVGTextElement> = event => {
      onMouseEnterText(event, arc);
    };

    const handleMouseLeaveText: React.MouseEventHandler<SVGTextElement> = event => {
      onMouseLeaveText(event, arc);
    };

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <Text
              fill={theme.palette.primary.main}
              x={centroidX}
              y={centroidY}
              fontWeight='bold'
              fontSize={fontSize}
              {...getTextAnchor(centroidX, centroidY, data, currentDir)}
              verticalAnchor={getVerticalAnchor(centroidX, centroidY, data)}
              onMouseEnter={handleMouseEnterText}
              onMouseLeave={handleMouseLeaveText}
            >
              {truncatedText}
            </Text>
          </animated.g>
        )}
      </g>
    );
  });
}
