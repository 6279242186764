import { jsx as _jsx } from "react/jsx-runtime";
import { CustomDialog, CustomTable } from "@toolkit/ui";
import { useProductsColumns } from "./useGetProductsTableColumns";
export const ProductsModal = ({ title, lines }) => {
    const orderItemsLines = lines.map(line => {
        const item = (line === null || line === void 0 ? void 0 : line.healthPackage) || (line === null || line === void 0 ? void 0 : line.product);
        return Object.assign(Object.assign({}, line), { type: (line === null || line === void 0 ? void 0 : line.healthPackage) ? "Health Package" : "Product", marketPlaceItem: {
                id: item === null || item === void 0 ? void 0 : item.id,
                name: item === null || item === void 0 ? void 0 : item.name,
                nameAr: item === null || item === void 0 ? void 0 : item.nameAr,
                mainImageUrl: item === null || item === void 0 ? void 0 : item.mainImageUrl,
            } });
    });
    return (_jsx(CustomDialog, Object.assign({ type: 'info', title: title }, { children: _jsx(CustomTable, { data: orderItemsLines, columns: useProductsColumns(), TableContainerProps: {
                sx: {
                    height: 350,
                },
            }, hasFooter: false }) })));
};
