import { i18n } from "./index";

export const isLanguageEnglish = () => {
  return i18n.language?.toLocaleLowerCase().startsWith("en");
};

export const pickLocalizedValue = <T>(englishValue: T, arabicValue: T): T => {
  if (isLanguageEnglish()) {
    return englishValue;
  }

  return arabicValue || englishValue;
};
