import { MarketplaceHealthPackage, MarketplaceHealthPackageSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { startCase } from "lodash";
import { useGetVendorsQuery } from "pages/HealthPackages/gql";
import { useMemo } from "react";
import { ApproveHealthPackage } from "./ApproveHealthPackage";
import { PreviewHealthPackages } from "./PreviewHealthPackages";
import RejectHealthPackage from "./RejectHealthPackage";
import { ellipseName } from "utils";
import { getMarketplaceApprovalStatusTypeFilter, getYesNoAutocompleteFilter, targetGenderOptions } from "@health/enum-options";
import { adminApprovalStatusTypeOptions } from "pages/Marketplace/constants";
import { getMarketplaceHealthPackageCategoriesAutocompleteFilter } from "@health/autocompletes";

export const useHealthPackageColumns = (): CustomTableColumnProps<MarketplaceHealthPackage>[] => {
  const { t, i18n } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image "),
        accessor: ({ mainImageUrl }) => (
          <GridImageModal
            image={mainImageUrl!}
            title={t("Health Package Image")}
            fallbackText={t("Health Package Image Not Provided")}
            altText={t("Health Package")}
          />
        ),
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ nameAr, name }) => ellipseName(i18n.language === "ar" ? nameAr || name : name, 20)!,
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: MarketplaceHealthPackageSortingField.Name,
        filter: {
          type: "string",
          name: "nameContains",
        },
      },
      {
        key: "vendor",
        header: t("Health Provider"),
        accessor: ({ vendor }) => ellipseName(i18n.language === "ar" ? vendor?.nameAr || vendor?.name : vendor?.name, 20),
        filter: {
          type: "autocomplete",
          query: useGetVendorsQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.id,
          name: "vendorId",
          accessor: "vendors",
        },
      },
      {
        key: "status",
        header: t("Approval Status"),
        accessor: row => t(startCase(row?.approvalStatus!.toLowerCase())),
        filter: {
          ...getMarketplaceApprovalStatusTypeFilter({ name: "approvalStatuses", multiple: true }),
          options: adminApprovalStatusTypeOptions,
        },
      },
      {
        key: "category",
        header: t("Category"),
        showOnlyForFilterField: true,
        filter: getMarketplaceHealthPackageCategoriesAutocompleteFilter({ name: "categoryId" }),
      },
      {
        key: "targetGender",
        header: t("Gender", { ns: "admin" }),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "targetGender",
          options: targetGenderOptions,
          getOptionLabel: option => option?.label!,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: row => <PreviewHealthPackages row={row} />,
      },

      {
        key: "isPublished",
        header: t("Is Published"),
        filter: {
          ...getYesNoAutocompleteFilter({ name: "isPublished" }),
          label: t("Is Published"),
        },
        showOnlyForFilterField: true,
      },
      {
        key: "targetAge",
        header: t("Target Age"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "targetAge",
        },
        isHidden: true,
        accessor: row => row.targetFromAge,
      },
      {
        key: "targetToAge",
        header: t("To Age", { ns: "provider" }),
        showOnlyForFilterField: true,
        isHidden: true,
        accessor: row => row.targetToAge,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: row => (
          <Box display='flex' flexDirection='row' gap={2}>
            <ApproveHealthPackage row={row} />
            <RejectHealthPackage row={row} />
          </Box>
        ),
      },
    ];
  }, []);
};
