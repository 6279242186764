import { useTranslation } from "@toolkit/i18n";
import React, { useEffect } from "react";
import { Button, Grid } from "../../base/mui";
import { MuiArrowBackIcon, MuiArrowForwardIcon } from "../../base/mui-icons";
import { CustomList } from "./CustomList.component";
import { useCustomTransferListStyle } from "./CustomTransferList.styles";
import { CustomTransferListProps } from "./CustomTransferList.types";
import { intersection, not } from "./CustomTransferList.utils";

type BaseTransferableItem = { name: string; code: string };

export const CustomTransferList = <T extends BaseTransferableItem>(props: CustomTransferListProps<T>) => {
  const { ChoicesLabel, ChosenLabel, row, onRightList, defaultPermissions, loading, error, helperText } = props;
  const { i18n } = useTranslation();
  const { classes } = useCustomTransferListStyle();
  const [checked, setChecked] = React.useState<Array<T>>([]);
  const [source, setSource] = React.useState<Array<T>>(row);
  const [destination, setDestination] = React.useState<Array<T>>(defaultPermissions || []);
  const [searchedSource, setSearchedSource] = React.useState<string>("");
  const [searchedDestination, setSearchedDestination] = React.useState<string>("");

  const onSearchSource = (value: string) => {
    setSearchedSource(value);
  };

  const onSearchDestination = (event: string) => {
    setSearchedDestination(event);
  };

  useEffect(() => {
    if (row?.length > 0) {
      setSource(row);
    }
  }, [!loading]);

  const leftChecked = intersection(checked, source) as T[];
  const rightChecked = intersection(checked, destination) as T[];

  const onToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const handleCheckDestination = () => {
    setDestination(destination.concat(leftChecked));
    setSource(not(source, leftChecked) as T[]);
    setChecked(not(checked, leftChecked) as T[]);
    onRightList?.(destination.concat(leftChecked));
  };

  const handleCheckSource = () => {
    setSource(source.concat(rightChecked));
    setDestination(not(destination, rightChecked) as T[]);
    setChecked(not(checked, rightChecked) as T[]);
    onRightList?.(not(destination, rightChecked) as T[]);
  };
  useEffect(() => {
    if (defaultPermissions) {
      setDestination(defaultPermissions);
      setSource(not(source, defaultPermissions) as T[]);
    }
  }, [JSON.stringify(defaultPermissions)]);
  const currentDir = i18n.dir(i18n.language);

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid
        item
        sx={{
          width: {
            xs: "calc(34% + 8px)",
            sm: "calc(40% + 8px)",
            md: "calc(40% + 14px)",
            lg: "calc(43% + 14px)",
            xl: "calc(45% + 14px)",
          },
        }}
      >
        <CustomList<T>
          title={ChoicesLabel!}
          items={source}
          handleToggle={onToggle}
          checked={checked}
          handleSearch={onSearchSource}
          searchedValue={searchedSource}
          loading={!!loading}
        />
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            className={classes.button}
            variant='outlined'
            size='small'
            onClick={handleCheckDestination}
            aria-label='move selected right'
          >
            {currentDir === "ltr" ? <MuiArrowForwardIcon /> : <MuiArrowBackIcon />}
          </Button>
          <Button className={classes.button} variant='outlined' size='small' onClick={handleCheckSource} aria-label='move selected left'>
            {currentDir === "ltr" ? <MuiArrowBackIcon /> : <MuiArrowForwardIcon />}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: {
            xs: "calc(34% + 8px)",
            sm: "calc(40% + 8px)",
            md: "calc(40% + 14px)",
            lg: "calc(43% + 14px)",
            xl: "calc(45% + 14px)",
          },
        }}
      >
        <CustomList
          title={ChosenLabel!}
          items={destination}
          handleToggle={onToggle}
          checked={checked}
          handleSearch={onSearchDestination}
          searchedValue={searchedDestination}
          loading={!!loading}
          isError={error}
          helperText={helperText}
        />
      </Grid>
    </Grid>
  );
};
