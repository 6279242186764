import { getAutocompleteFilter } from "@toolkit/ui";
import { useMarketplaceProductCategoriesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const getMarketplaceProductCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceProductCategories",
        query: useMarketplaceProductCategoriesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, (item === null || item === void 0 ? void 0 : item.nameAr) || (item === null || item === void 0 ? void 0 : item.name)),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
