import { useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { PayerForm } from "../PayerForm";
import { usePayerEdit } from "./usePayerEdit";
import { payerEditBreadcrumb, payersListBreadcrumb, payersTitleBreadcrumb } from "../../Constants/payersBreadcrumbs";
import { i18n } from "@toolkit/i18n";
export const PayerEditPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, loading, payer, handleSubmit, isSubmitting } = usePayerEdit();

  useEffect(() => {
    if (payer?.name) {
      setBreadCrumb({
        title: payersTitleBreadcrumb(),
        values: [payersListBreadcrumb(), payerEditBreadcrumb(i18n.language === "en" ? payer.name! : (payer.nameAr && payer.name!) || "")],
      });
    }
  }, [payer?.name]);

  return <PayerForm loading={loading} defaultValue={payer!} errors={errors} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};
