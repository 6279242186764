import { combineErrors, getRequiredValidation, minRequired, validateValueIsNotEmpty, maxRequired } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, CustomDatePicker, Grid, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { InputMember } from "../types";

export const GeneralInformation: FC<InputMember> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("admin");

  const {
    register,
    control,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const formErrors = combineErrors(reactFormErrors, errors);
  const genderOptions = [
    { value: "FEMALE", name: "Female" },
    { value: "MALE", name: "Male" },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          placeholder={t("First Name")}
          label={t("First Name")}
          defaultValue={defaultValue?.firstName}
          error={Boolean(formErrors.firstName?.message)}
          helperText={t(formErrors.firstName?.message)}
          {...register("firstName", {
            required: getRequiredValidation(t, true),
            validate: value => validateValueIsNotEmpty(value, t),
            minLength: minRequired(t, 3),
            maxLength: maxRequired(t, 30),
          })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          placeholder={t("Last Name")}
          label={t("Last Name")}
          defaultValue={defaultValue?.lastName}
          error={Boolean(formErrors.lastName?.message)}
          helperText={t(formErrors.lastName?.message)}
          {...register("lastName", {
            required: getRequiredValidation(t, true),
            validate: value => validateValueIsNotEmpty(value, t),
            minLength: minRequired(t, 3),
            maxLength: maxRequired(t, 30),
          })}
        />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteController
          ControllerProps={{
            name: "gender",
            control: control,
            rules: { required: getRequiredValidation(t, true) },
            defaultValue: genderOptions?.find(el => el.value === defaultValue?.gender) || null,
          }}
          TextFieldProps={{
            error: Boolean(formErrors?.gender?.message),
            helperText: formErrors?.gender?.message,
            label: t("Gender"),
          }}
          getOptionLabel={o => t(o?.name)}
          defaultValue={genderOptions?.find(el => el.value === defaultValue?.gender) || null}
          options={genderOptions || []}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomDatePicker
          control={control}
          name='dateOfBirth'
          defaultValue={defaultValue?.dateOfBirth}
          label={t("Date of Birth")}
          placeholder={t("dd/mm/yyyy")}
          error={Boolean(formErrors?.dateOfBirth?.message)}
          helperText={t(formErrors?.dateOfBirth?.message)}
          controllerProps={{
            rules: { required: getRequiredValidation(t, true) },
          }}
          datePickerProps={{
            disabled: !!defaultValue,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          type='number'
          placeholder={t("Emirates Id")}
          label={t("Emirates Id")}
          defaultValue={defaultValue?.nationalId}
          error={Boolean(formErrors.nationalId?.message)}
          helperText={t(formErrors.nationalId?.message)}
          {...register("nationalId", { required: getRequiredValidation(t, true) })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          placeholder={t("iO Health Id")}
          label={t("iO Health Id")}
          defaultValue={defaultValue?.sehacityId}
          disabled={!!defaultValue}
          error={Boolean(formErrors.sehacityId?.message)}
          helperText={t(formErrors.sehacityId?.message)}
          {...register("sehacityId", { required: getRequiredValidation(t, !defaultValue?.sehacityId) })}
        />
      </Grid>
    </Grid>
  );
};
