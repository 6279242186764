import { z } from "zod";
import { patternArabicTextWithSpecialCharAndNumbers } from "@toolkit/core";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { baseMedicalFormClassificationUpsertFormSchema } from "../MedicalFormClassificationUpsert/MedicalFormClassificationUpsertFormSchema";
import { MedicalFormQuestionUpsertFormSchema } from "../MedicalFormQuestionUpsert/MedicalFormQuestionUpsertFormSchema";
import { zodSchema } from "../../../../zod";
const validateArabicText = (value) => {
    return patternArabicTextWithSpecialCharAndNumbers.value.test(value);
};
export const MedicalFormUpsertFormSchema = z.object({
    active: z.boolean(),
    name: z.string().min(1).max(50),
    nameAr: z.string().min(1).max(50).refine(validateArabicText, { message: patternArabicTextWithSpecialCharAndNumbers.message }),
    code: z.string().min(1).max(50),
    type: zodSchema.medicalFormTypeAutocompleteEnum,
    category: zodSchema.medicalFormCategoryAutocompleteEnum,
    description: z.string().min(1).max(255),
    department: createZodAutocompleteObject(z.object({ id: z.string(), name: z.string() })).optional(),
    questions: z.array(MedicalFormQuestionUpsertFormSchema).optional(),
    medicalFormClassifications: z.array(baseMedicalFormClassificationUpsertFormSchema).optional(),
});
export const medicalFormUpsertFormDefaultValues = {
    active: false,
    name: "",
    nameAr: "",
    code: "",
    description: "",
    questions: [],
    medicalFormClassifications: [],
};
