import { toSentenceCase } from "@toolkit/core";
import { createAutocompleteOption, mapStringsToAutocompleteOptions } from "@toolkit/ui";
import { ActionFieldType } from "@health/queries/types";
import { sortBy } from "lodash";
import { isCodeInDecisionActionFieldListCode } from "../../Decisions/others/utils";
import { DecisionActionFieldListCode, } from "../../Decisions/types/types";
import { pickLocalizedValue } from "@toolkit/i18n";
export const sortActionFields = (actionDefinitionFields) => {
    return sortBy(actionDefinitionFields || [], item => {
        var _a;
        switch (item === null || item === void 0 ? void 0 : item.type) {
            case ActionFieldType.List:
                return 1;
            case ActionFieldType.String:
                if (isCodeInDecisionActionFieldListCode(item === null || item === void 0 ? void 0 : item.code)) {
                    return 2;
                }
                else if ((_a = item === null || item === void 0 ? void 0 : item.allowedValues) === null || _a === void 0 ? void 0 : _a.length) {
                    return 3;
                }
                else {
                    return 4;
                }
            case ActionFieldType.Number:
                return 5;
            case ActionFieldType.Date:
                return 6;
            default:
                return 7;
        }
    });
};
export const convertDecisionPlanActionFieldsToFormValues = (decisionPlanActionFields) => {
    const _decisionPlan = (decisionPlanActionFields === null || decisionPlanActionFields === void 0 ? void 0 : decisionPlanActionFields.map(item => ({
        code: item === null || item === void 0 ? void 0 : item.code,
        display: item === null || item === void 0 ? void 0 : item.display,
        type: item === null || item === void 0 ? void 0 : item.type,
        allowedValues: mapStringsToAutocompleteOptions(item === null || item === void 0 ? void 0 : item.allowedValues, toSentenceCase),
        isRequired: item === null || item === void 0 ? void 0 : item.isRequired,
        valueString: undefined,
        valueStringList: undefined,
        valueNumber: undefined,
        valueBoolean: false,
        valueList: undefined,
    }))) || [];
    return sortActionFields(_decisionPlan);
};
export const resetDecisionActionFieldsValues = (actionValues, except) => {
    for (const key in actionValues) {
        if (key !== except) {
            actionValues[key] = undefined;
        }
    }
};
export const convertDecisionActionFieldsToFormValues = (actionFields, decisionPlanActionsFields) => {
    const result = [];
    result.push(
    // eslint-disable-next-line max-statements
    ...((actionFields === null || actionFields === void 0 ? void 0 : actionFields.map(item => {
        var _a;
        const decisionActionsField = decisionPlanActionsFields[item === null || item === void 0 ? void 0 : item.code];
        const extra = (item === null || item === void 0 ? void 0 : item.extra) ? JSON.parse(item === null || item === void 0 ? void 0 : item.extra) : undefined;
        const actionValues = {
            valueBoolean: undefined,
            valueNumber: undefined,
            valueString: undefined,
            valueList: undefined,
            valueDate: undefined,
        };
        if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.Boolean) {
            actionValues.valueBoolean = item === null || item === void 0 ? void 0 : item.valueBoolean;
            resetDecisionActionFieldsValues(actionValues, "valueBoolean");
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.Number) {
            actionValues.valueNumber = item === null || item === void 0 ? void 0 : item.valueNumber;
            resetDecisionActionFieldsValues(actionValues, "valueNumber");
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.String) {
            if (Object.values(DecisionActionFieldListCode).includes(item === null || item === void 0 ? void 0 : item.code) && extra) {
                actionValues.valueString = createAutocompleteOption({
                    id: extra === null || extra === void 0 ? void 0 : extra.id,
                    name: extra === null || extra === void 0 ? void 0 : extra.name,
                    nameAr: extra === null || extra === void 0 ? void 0 : extra.nameAr,
                }, "id", _item => pickLocalizedValue(_item === null || _item === void 0 ? void 0 : _item.name, (_item === null || _item === void 0 ? void 0 : _item.nameAr) || (_item === null || _item === void 0 ? void 0 : _item.name)));
            }
            else if ((_a = decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.allowedValues) === null || _a === void 0 ? void 0 : _a.length) {
                const allowedValuesOptions = mapStringsToAutocompleteOptions(decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.allowedValues, toSentenceCase);
                actionValues.valueString = allowedValuesOptions.find(option => (option === null || option === void 0 ? void 0 : option.value) === (item === null || item === void 0 ? void 0 : item.valueString));
            }
            else {
                actionValues.valueString = item === null || item === void 0 ? void 0 : item.valueString;
            }
            resetDecisionActionFieldsValues(actionValues, "valueString");
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.List && extra) {
            actionValues.valueList = createAutocompleteOption({
                id: extra === null || extra === void 0 ? void 0 : extra.id,
                name: extra === null || extra === void 0 ? void 0 : extra.name,
                nameAr: extra === null || extra === void 0 ? void 0 : extra.nameAr,
            }, "id", _item => pickLocalizedValue(_item === null || _item === void 0 ? void 0 : _item.name, (_item === null || _item === void 0 ? void 0 : _item.nameAr) || (_item === null || _item === void 0 ? void 0 : _item.name)));
            resetDecisionActionFieldsValues(actionValues, "valueList");
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.Date) {
            actionValues.valueDate = item === null || item === void 0 ? void 0 : item.valueDate;
            resetDecisionActionFieldsValues(actionValues, "valueDate");
        }
        return Object.assign(Object.assign({}, actionValues), { code: item === null || item === void 0 ? void 0 : item.code, display: decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.display, type: item === null || item === void 0 ? void 0 : item.type, isRequired: !!(decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.isRequired), allowedValues: mapStringsToAutocompleteOptions(decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.allowedValues, toSentenceCase) });
    })) || []));
    Object.entries(decisionPlanActionsFields).forEach(([code, decisionActionsField]) => {
        if (!(actionFields === null || actionFields === void 0 ? void 0 : actionFields.some(item => (item === null || item === void 0 ? void 0 : item.code) === code))) {
            result.push({
                code,
                display: decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.display,
                type: decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.type,
                isRequired: !!(decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.isRequired),
                allowedValues: mapStringsToAutocompleteOptions(decisionActionsField === null || decisionActionsField === void 0 ? void 0 : decisionActionsField.allowedValues, toSentenceCase),
            });
        }
    });
    return sortActionFields(result);
};
