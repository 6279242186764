import { z } from "zod";
import { i18n } from "@toolkit/i18n";
import { isNil } from "lodash";
export const baseMedicalFormClassificationUpsertFormSchema = z.object({
    name: z.string().min(1).max(255),
    rangeFrom: z.coerce.number().min(0),
    rangeTo: z.coerce.number(),
});
export const medicalFormClassificationUpsertFormSchema = (questionsMinMaxScores) => baseMedicalFormClassificationUpsertFormSchema.superRefine((data, ctx) => {
    if (!isNil(questionsMinMaxScores === null || questionsMinMaxScores === void 0 ? void 0 : questionsMinMaxScores.minScore) && data.rangeFrom < (questionsMinMaxScores === null || questionsMinMaxScores === void 0 ? void 0 : questionsMinMaxScores.minScore)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Value must be at least {{minScore}}", { minScore: questionsMinMaxScores === null || questionsMinMaxScores === void 0 ? void 0 : questionsMinMaxScores.minScore, ns: "domains" }),
            path: ["rangeFrom"],
        });
    }
    if (!isNil(questionsMinMaxScores === null || questionsMinMaxScores === void 0 ? void 0 : questionsMinMaxScores.maxScore) && data.rangeTo > (questionsMinMaxScores === null || questionsMinMaxScores === void 0 ? void 0 : questionsMinMaxScores.maxScore)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Value must be at most {{maxScore}}", { maxScore: questionsMinMaxScores === null || questionsMinMaxScores === void 0 ? void 0 : questionsMinMaxScores.maxScore, ns: "domains" }),
            path: ["rangeTo"],
        });
    }
});
export const medicalFormClassificationUpsertFormDefaultValues = {
    name: "",
    rangeFrom: undefined,
    rangeTo: undefined,
};
