import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import useToggle from "shared/hooks/useToggle";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { FileDownload, FileImportDialog } from "@health/domains";
import { BulkTemplateCode } from "@health/queries/types";

export const HealthProgramMembersList: FC<{ canManageActions?: boolean }> = ({ canManageActions }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { open, handleToggle } = useToggle();
  const { customTableProps } = useGridContext();

  const handleAddNew = () => navigate("new");

  const handleSuccessUpload = () => {
    customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  };

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          <TableSettingComponent />
          {canManageActions && (
            <MainListActions hasAddNew onAddNewItem={handleAddNew}>
              <FileImportDialog
                title={t("Import Member")}
                subTitle={t("Upload Members list you want to get")}
                isOpen={open}
                onToggle={handleToggle}
                onSuccessUpload={handleSuccessUpload}
                templateCode={BulkTemplateCode.HealthProgramMember}
              />

              <FileDownload templateCode={BulkTemplateCode.HealthProgramMember} />
            </MainListActions>
          )}
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};
