import { makeStyles } from "@toolkit/ui";

export const useQuestionCardStyles = makeStyles<{ currentDir?: "ltr" | "rtl" }>()((theme, { currentDir }) => ({
  root: {
    width: "100%",
    paddingRight: currentDir === "rtl" ? 16 : 0,
    paddingLeft: currentDir === "rtl" ? 0 : 16,
  },
  header: {
    boxShadow: theme.mixins.shadows.md,
    padding: 8,
    paddingRight: currentDir === "rtl" ? 16 : 0,
    paddingLeft: currentDir === "rtl" ? 0 : 16,
  },
  requiredLabelContainer: {
    "& label": {
      direction: currentDir === "ltr" ? "rtl" : "ltr",
      margin: 0,
    },
  },
  icon: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: 400,
    margin: "0 4px",
  },
  questionTypeItem: {
    borderRadius: 0,
    paddingLeft: 12,
  },
}));
